import React, { createRef, useEffect, useRef } from 'react';
import gsap from 'gsap';
import { Svg } from './cooperationDesktop.styled';

const CooperationDesktop = () => {
  const hexagonRef = createRef();

  const lineRef = useRef([...Array(12)].map(() => createRef()));

  const circleRef = useRef([...Array(6)].map(() => createRef()));

  const iconRef = useRef([...Array(6)].map(() => createRef()));

  const screenLineRef = createRef();

  const tl = gsap.timeline();

  const questionMove = gsap.timeline({
    paused: true,
    repeat: -1,
    repeatDelay: 2,
    yoyo: true,
  });

  const screenDrawLine = gsap.timeline({
    paused: true,
    repeat: -1,
    repeatDelay: 2,
    yoyo: true,
  });

  const pointerClick = gsap.timeline({
    paused: true,
    repeat: -1,
    repeatDelay: 1,
  });

  const clientMove = gsap.timeline({
    paused: true,
    repeat: -1,
    repeatDelay: 2,
    yoyo: true,
  });

  const bulbShake = gsap.timeline({
    paused: true,
    repeat: -1,
    repeatDelay: 1,
  });

  const paletteMove = gsap.timeline({
    paused: true,
    repeat: -1,
    repeatDelay: 2,
  });

  function onQuestionComplete() {
    questionMove.play();
  }

  function onBulbComplete() {
    bulbShake.play();
  }

  function onPaletteComplete() {
    paletteMove.play();
  }

  function onScreenComplete() {
    screenDrawLine.play();
  }

  function onPointerComplete() {
    pointerClick.play();
  }

  function onClientComplete() {
    clientMove.play();
  }

  useEffect(() => {
    questionMove
      .to(iconRef.current[0].current.children[0], 0.3, {
        scale: 1.5,
        transformOrigin: 'center center',
      })
      .to(iconRef.current[0].current.children[1], 0.3, {
        x: 5,
        scale: 1.2,
        transformOrigin: 'center center',
      })
      .to(iconRef.current[0].current.children[2], 0.3, {
        x: -5,
        scale: 1.2,
        transformOrigin: 'center center',
      });
    bulbShake
      .fromTo(
        iconRef.current[1].current,
        0.2,
        {
          x: 1,
        },
        { x: -1 }
      )
      .fromTo(
        iconRef.current[1].current,
        0.2,
        {
          x: 1,
        },
        { x: -1 }
      )
      .fromTo(
        iconRef.current[1].current,
        0.2,
        {
          x: 1,
        },
        { x: -1 }
      )
      .fromTo(
        iconRef.current[1].current,
        0.2,
        {
          x: 1,
        },
        { x: -1 }
      )
      .to(iconRef.current[1].current, 0.2, {
        x: 0,
      });
    paletteMove
      .to(iconRef.current[2].current, 0.3, { y: -10 })
      .to(iconRef.current[2].current, 0.3, { y: 0 })
      .to(iconRef.current[2].current, 0.2, {
        scaleY: 0.9,
        transformOrigin: 'center 100%',
      })
      .to(iconRef.current[2].current, 0.2, {
        scaleY: 1,
        transformOrigin: 'center 100%',
      });
    screenDrawLine.fromTo(
      screenLineRef.current,
      0.3,
      {
        scaleX: 0,
      },
      { scale: 1 }
    );
    pointerClick
      .addLabel('mouseClick')
      .to(
        [
          iconRef.current[4].current.children[1],
          iconRef.current[4].current.children[2],
          iconRef.current[4].current.children[3],
          iconRef.current[4].current.children[4],
          iconRef.current[4].current.children[5],
        ],
        0.3,
        { opacity: 1 },
        'mouseClick'
      )
      .to(
        iconRef.current[4].current.children[0],
        0.3,
        {
          scale: 1.2,
          transformOrigin: 'center center',
        },
        'mouseClick'
      )
      .addLabel('mouseNotCLick')
      .to(
        iconRef.current[4].current.children[0],
        0.3,
        {
          scale: 1,
          transformOrigin: 'center center',
        },
        'mouseNotCLick'
      )
      .to(
        [
          iconRef.current[4].current.children[1],
          iconRef.current[4].current.children[2],
          iconRef.current[4].current.children[3],
          iconRef.current[4].current.children[4],
          iconRef.current[4].current.children[5],
        ],
        0.3,
        { opacity: 0 },
        'mouseNotCLick'
      );
    clientMove.to(iconRef.current[5].current, 1, { x: 0, opacity: 1 });
    tl.set(
      [
        hexagonRef.current,
        lineRef.current[0].current,
        lineRef.current[1].current,
        lineRef.current[2].current,
        lineRef.current[3].current,
        lineRef.current[4].current,
        lineRef.current[5].current,
        lineRef.current[6].current,
        lineRef.current[7].current,
        lineRef.current[8].current,
        lineRef.current[9].current,
        lineRef.current[10].current,
        lineRef.current[11].current,
        circleRef.current[0].current,
        circleRef.current[1].current,
        circleRef.current[2].current,
        circleRef.current[3].current,
        circleRef.current[4].current,
        circleRef.current[5].current,
        iconRef.current[0].current,
        iconRef.current[1].current,
        iconRef.current[2].current,
        iconRef.current[3].current,
        iconRef.current[4].current,
        iconRef.current[5].current,
      ],
      { opacity: 0 }
    )
      .fromTo(
        hexagonRef.current,
        0.45,
        { scale: 0, transformOrigin: 'center center', opacity: 1 },
        { scale: 1, transformOrigin: 'center center' }
      )
      .fromTo(
        lineRef.current[0].current,
        0.25,
        {
          scaleX: 0,
          transformOrigin: '100%',
          opacity: 1,
          ease: 'none',
        },
        { scaleX: 1, transformOrigin: '100%', ease: 'none' }
      )
      .fromTo(
        lineRef.current[1].current,
        0.25,
        {
          scaleY: 0,
          transformOrigin: 'top',
          opacity: 1,
          ease: 'none',
        },
        { scaleY: 1, transformOrigin: 'top', ease: 'none' }
      )
      .fromTo(
        lineRef.current[2].current,
        0.25,
        { scaleX: 0, transformOrigin: '0%', opacity: 1, ease: 'none' },
        { scaleX: 1, transformOrigin: '0%', ease: 'none' }
      )
      .fromTo(
        circleRef.current[0].current,
        0.25,
        { scale: 0, transformOrigin: '0% center', opacity: 1 },
        { scale: 1, transformOrigin: '0% center' }
      )
      .to(iconRef.current[0].current, 0.25, { opacity: 1, onQuestionComplete })
      .fromTo(
        lineRef.current[3].current,
        0.25,
        { scaleX: 0, transformOrigin: '0%', opacity: 1, ease: 'none' },
        { scaleX: 1, transformOrigin: '0%', ease: 'none' }
      )
      .fromTo(
        circleRef.current[1].current,
        0.25,
        { scale: 0, transformOrigin: '0% center', opacity: 1 },
        { scale: 1, transformOrigin: '0% center' }
      )
      .to(iconRef.current[1].current, 0.25, { opacity: 1, onBulbComplete })
      .fromTo(
        lineRef.current[4].current,
        0.25,
        { scaleX: 0, transformOrigin: '0%', opacity: 1, ease: 'none' },
        { scaleX: 1, transformOrigin: '0%', ease: 'none' }
      )
      .fromTo(
        lineRef.current[5].current,
        0.25,
        { scaleY: 0, transformOrigin: 'top', opacity: 1, ease: 'none' },
        { scaleY: 1, transformOrigin: 'top', ease: 'none' }
      )
      .fromTo(
        lineRef.current[6].current,
        0.25,
        {
          scaleX: 0,
          transformOrigin: '100%',
          opacity: 1,
          ease: 'none',
        },
        { scaleX: 1, transformOrigin: '100%', ease: 'none' }
      )
      .fromTo(
        circleRef.current[2].current,
        0.25,
        { scale: 0, transformOrigin: '100% center', opacity: 1 },
        { scale: 1, transformOrigin: '100% center' }
      )
      .to(iconRef.current[2].current, 0.25, { opacity: 1, onPaletteComplete })
      .fromTo(
        lineRef.current[7].current,
        0.25,
        {
          scaleX: 0,
          transformOrigin: '100%',
          opacity: 1,
          ease: 'none',
        },
        { scaleX: 1, transformOrigin: '100%', ease: 'none' }
      )
      .fromTo(
        circleRef.current[3].current,
        0.25,
        {
          scale: 0,
          transformOrigin: '100% center',
          opacity: 1,
          ease: 'none',
        },
        { scale: 1, transformOrigin: '100% center', ease: 'none' }
      )
      .to(iconRef.current[3].current, 0.25, { opacity: 1, onScreenComplete })
      .fromTo(
        lineRef.current[8].current,
        0.25,
        {
          scaleX: 0,
          transformOrigin: '100%',
          opacity: 1,
          ease: 'none',
        },
        { scaleX: 1, transformOrigin: '100%', ease: 'none' }
      )
      .fromTo(
        lineRef.current[9].current,
        0.25,
        {
          scaleY: 0,
          transformOrigin: 'top',
          opacity: 1,
          ease: 'none',
        },
        { scaleY: 1, transformOrigin: 'top', ease: 'none' }
      )
      .fromTo(
        lineRef.current[10].current,
        0.25,
        { scaleX: 0, transformOrigin: '0%', opacity: 1, ease: 'none' },
        { scaleX: 1, transformOrigin: '0%', ease: 'none' }
      )
      .fromTo(
        circleRef.current[4].current,
        0.25,
        { scale: 0, transformOrigin: '0% center', opacity: 1 },
        { scale: 1, transformOrigin: '0% center' }
      )
      .to(iconRef.current[4].current, 0.25, { opacity: 1, onPointerComplete })
      .fromTo(
        lineRef.current[11].current,
        0.25,
        { scaleX: 0, transformOrigin: '0%', opacity: 1, ease: 'none' },
        { scaleX: 1, transformOrigin: '0%', ease: 'none' }
      )
      .fromTo(
        circleRef.current[5].current,
        0.25,
        { scale: 0, transformOrigin: '0% center', opacity: 1 },
        { scale: 1, transformOrigin: '0% center' }
      )
      .to(iconRef.current[5].current, 0.0001, {
        x: 40,
        onClientComplete,
      });
  });

  return (
    <Svg
      version="1.1"
      id="Warstwa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 600.8 842.3"
      classNameName="st4"
    >
      <g id="kolo_1" ref={circleRef.current[0]}>
        <circle className="st0" cx="181.8" cy="289.8" r="46" />
        <path
          className="st1"
          d="M181.8,337.5c-26.3,0-47.7-21.4-47.7-47.7s21.4-47.8,47.7-47.8s47.7,21.4,47.7,47.7S208,337.5,181.8,337.5z
		 M181.8,245.3c-24.5,0-44.5,20-44.5,44.5s20,44.5,44.5,44.5s44.5-20,44.5-44.5S206.4,245.3,181.8,245.3z"
        />
      </g>
      <g id="kolo_2" ref={circleRef.current[1]}>
        <circle className="st0" cx="427.4" cy="289.8" r="46" />
        <path
          className="st1"
          d="M427.4,337.5c-26.3,0-47.7-21.4-47.7-47.7s21.4-47.8,47.7-47.8s47.7,21.4,47.7,47.7S453.7,337.5,427.4,337.5z
		 M427.4,245.3c-24.5,0-44.5,20-44.5,44.5s20,44.5,44.5,44.5c24.5,0,44.5-20,44.5-44.5S452,245.3,427.4,245.3z"
        />
      </g>
      <g id="kolo_3" ref={circleRef.current[2]}>
        <circle className="st0" cx="427.6" cy="518.5" r="46" />
        <path
          className="st1"
          d="M427.6,566.2c-26.3,0-47.7-21.4-47.7-47.7c0-26.3,21.4-47.8,47.7-47.8s47.7,21.4,47.7,47.7
		C475.3,544.8,453.9,566.2,427.6,566.2z M427.6,474c-24.5,0-44.5,20-44.5,44.5c0,24.5,20,44.5,44.5,44.5c24.5,0,44.5-20,44.5-44.5
		C472.1,493.9,452.3,474,427.6,474z"
        />
      </g>
      <g id="kolo_4" ref={circleRef.current[3]}>
        <circle className="st0" cx="181.6" cy="518.2" r="46" />
        <path
          className="st1"
          d="M181.6,566c-26.3,0-47.7-21.4-47.7-47.7c0-26.3,21.4-47.8,47.7-47.8s47.7,21.4,47.7,47.7
		C229.3,544.5,207.9,566,181.6,566z M181.6,473.7c-24.5,0-44.5,20-44.5,44.5c0,24.5,20,44.5,44.5,44.5s44.5-20,44.5-44.5
		C226.1,493.7,206.1,473.7,181.6,473.7z"
        />
      </g>
      <g id="kolo_5" ref={circleRef.current[4]}>
        <circle className="st0" cx="181.7" cy="745.1" r="46" />
        <path
          className="st1"
          d="M181.7,792.9c-26.3,0-47.7-21.4-47.7-47.7c0-26.3,21.4-47.8,47.7-47.8s47.7,21.4,47.7,47.7
		C229.3,771.4,208,792.9,181.7,792.9z M181.7,700.6c-24.5,0-44.5,20-44.5,44.5s20,44.5,44.5,44.5s44.5-20,44.5-44.5
		C226.2,720.6,206.3,700.6,181.7,700.6z"
        />
      </g>
      <g id="kolo_6" ref={circleRef.current[5]}>
        <circle className="st0" cx="427.7" cy="744.8" r="46" />
        <path
          className="st1"
          d="M427.7,792.6c-26.3,0-47.7-21.4-47.7-47.7s21.4-47.8,47.7-47.8s47.7,21.4,47.7,47.7
		C475.3,771.1,454,792.6,427.7,792.6z M427.7,700.3c-24.5,0-44.5,20-44.5,44.5c0,24.5,20,44.5,44.5,44.5c24.5,0,44.5-20,44.5-44.5
		C472.1,720.3,452.3,700.3,427.7,700.3z"
        />
      </g>
      <g id="ikona_1" ref={iconRef.current[0]}>
        <g>
          <path
            className="st1"
            d="M177.3,289.3h-4.2c0-2.1,0.7-3.8,2.1-5.2c1.4-1.3,3.3-2,5.6-2c1.5,0,2.8,0.2,3.9,0.8s1.9,1.4,2.6,2.5
			c0.6,1.1,0.9,2.1,0.9,3.3c0,0.6-0.1,1.3-0.4,1.9s-0.5,1.3-0.9,1.9c-0.2,0.4-0.8,1.1-1.9,1.9c-0.9,0.8-1.5,1.4-1.8,1.8
			s-0.5,0.8-0.6,1.4c-0.1,0.6-0.2,1.4-0.4,2.5h-4.3v-0.7c0-1.4,0.2-2.7,0.8-3.9c0.6-1.2,1.4-2.2,2.6-3.3c1.2-1.1,1.9-1.6,2.1-2.1
			s0.4-0.8,0.4-1.3c0-0.7-0.2-1.3-0.8-1.8c-0.6-0.5-1.3-0.7-2.3-0.7c-0.9,0-1.8,0.4-2.5,0.8C177.7,287.5,177.3,288.4,177.3,289.3z
			 M180.4,301.7c0.8,0,1.4,0.2,2,0.8c0.6,0.6,0.8,1.2,0.8,1.9c0,0.7-0.2,1.4-0.8,2c-0.6,0.6-1.2,0.8-2,0.8s-1.4-0.2-2-0.8
			c-0.6-0.6-0.8-1.2-0.8-2c0-0.8,0.2-1.4,0.8-1.9C179,302,179.7,301.7,180.4,301.7z"
          />
        </g>
        <g>
          <path
            className="st1"
            d="M195.9,289.2h-2.6c0-1.3,0.5-2.3,1.3-3.2c0.8-0.8,2.1-1.3,3.5-1.3c0.9,0,1.8,0.1,2.3,0.5
			c0.7,0.4,1.2,0.8,1.5,1.5c0.4,0.7,0.6,1.3,0.6,2c0,0.4-0.1,0.8-0.2,1.2c-0.1,0.4-0.4,0.7-0.6,1.2c-0.1,0.2-0.6,0.6-1.2,1.2
			c-0.6,0.5-0.9,0.9-1.1,1.1c-0.1,0.1-0.2,0.5-0.4,0.8s-0.1,0.8-0.2,1.5h-2.7v-0.5c0-0.9,0.1-1.6,0.5-2.5c0.4-0.8,0.8-1.4,1.5-2
			c0.7-0.6,1.2-1.1,1.3-1.3s0.2-0.5,0.2-0.8c0-0.5-0.2-0.8-0.5-1.1c-0.2-0.2-0.8-0.5-1.4-0.5s-1.1,0.1-1.5,0.6
			C196.1,288.1,195.9,288.6,195.9,289.2z M197.8,296.8c0.5,0,0.9,0.1,1.3,0.5s0.5,0.7,0.5,1.2c0,0.5-0.1,0.8-0.5,1.2
			c-0.4,0.4-0.7,0.5-1.2,0.5c-0.5,0-0.9-0.1-1.2-0.5c-0.4-0.4-0.5-0.7-0.5-1.2c0-0.5,0.1-0.8,0.5-1.2
			C197,297.1,197.3,296.8,197.8,296.8z"
          />
        </g>
        <g>
          <path
            className="st1"
            d="M162.6,280.5h-2.9c0-1.5,0.5-2.7,1.5-3.6s2.3-1.4,4-1.4c1.1,0,2,0.2,2.7,0.6s1.4,0.9,1.8,1.8
			c0.5,0.7,0.7,1.5,0.7,2.3c0,0.5-0.1,0.9-0.2,1.3c-0.1,0.5-0.4,0.9-0.7,1.3c-0.2,0.2-0.6,0.7-1.3,1.3c-0.7,0.6-1.1,1.1-1.3,1.3
			c-0.1,0.2-0.4,0.6-0.5,0.9c-0.1,0.5-0.2,0.9-0.2,1.8H163v-0.5c0-1.1,0.2-2,0.6-2.8c0.4-0.8,0.9-1.6,1.8-2.3
			c0.8-0.7,1.3-1.2,1.4-1.4c0.1-0.2,0.2-0.6,0.2-0.9c0-0.5-0.2-0.9-0.6-1.3c-0.4-0.4-0.9-0.5-1.6-0.5s-1.3,0.2-1.8,0.6
			C162.8,279.3,162.6,279.9,162.6,280.5z M164.8,289.4c0.6,0,1.1,0.2,1.4,0.6c0.4,0.4,0.6,0.8,0.6,1.4s-0.2,1.1-0.6,1.4
			c-0.4,0.4-0.8,0.6-1.4,0.6c-0.6,0-1.1-0.2-1.4-0.6s-0.6-0.8-0.6-1.4s0.2-1.1,0.6-1.4C163.7,289.5,164.2,289.4,164.8,289.4z"
          />
        </g>
      </g>
      <g ref={iconRef.current[1]}>
        <path
          id="ikona_2"
          className="st1"
          d="M432.7,305h-12.2c-0.5,0-0.8-0.4-0.8-0.8c0-2.6-0.5-4.8-1.3-6.9c-0.8-2.1-1.8-4-2.7-5.8
	c-1.6-3.1-3.1-5.8-3.1-8.6v-0.1c0-4.5,1.8-8.1,4.5-10.6c2.6-2.5,6.1-3.8,9.6-3.8h0.1l0,0c3.6,0,7.3,1.5,10,4.3
	c2.3,2.5,3.9,5.9,3.9,10.1c0,2.9-1.4,5.6-3.1,8.6c-1.9,3.5-4,7.5-4,12.7C433.5,304.7,433.1,305,432.7,305z M420.9,308.1
	c-0.5,0-0.8-0.4-0.8-0.8s0.4-0.8,0.8-0.8h11.2c0.5,0,0.8,0.4,0.8,0.8s-0.4,0.8-0.8,0.8H420.9z M424.9,291.2h3.4V290
	c0-0.5,0.4-0.8,0.8-0.8s0.8,0.4,0.8,0.8v13.4h1.9c0.2-5.2,2.3-9,4.2-12.7c1.5-2.8,2.8-5.3,2.8-7.7c0-3.8-1.4-6.8-3.4-8.9
	c-2.3-2.5-5.5-3.8-8.8-3.8h-0.1c-3.1,0-6.1,1.2-8.5,3.3c-2.3,2.1-3.9,5.3-3.9,9.3v0.1c0,2.5,1.4,5,2.8,7.7c0.9,1.9,2,3.9,2.8,6
	c0.7,2,1.3,4.2,1.4,6.6h1.9v-13.4c0-0.5,0.4-0.8,0.8-0.8c0.5,0,0.8,0.4,0.8,0.8L424.9,291.2L424.9,291.2z M428.2,303.4v-10.4h-3.4
	v10.4H428.2z M422,311.1c-0.5,0-0.8-0.4-0.8-0.8c0-0.5,0.4-0.8,0.8-0.8h9c0.5,0,0.8,0.4,0.8,0.8c0,0.5-0.4,0.8-0.8,0.8H422z"
        />
      </g>
      <g ref={iconRef.current[2]}>
        <path
          id="ikona_3"
          className="st1"
          d="M428.9,498.4c-11.6,0-21.1,9.5-21.1,21.1c0,11.6,9.5,21.1,21.1,21.1c2,0,3.5-1.5,3.5-3.5
	c0-0.9-0.4-1.8-0.9-2.3c-0.6-0.6-0.9-1.4-0.9-2.3c0-2,1.5-3.5,3.5-3.5h4.1c6.5,0,11.7-5.3,11.7-11.7
	C450,506.9,440.5,498.4,428.9,498.4z M416,519.5c-2,0-3.5-1.5-3.5-3.5s1.5-3.5,3.5-3.5c2,0,3.5,1.5,3.5,3.5S418,519.5,416,519.5z
	 M423.1,510.1c-2,0-3.5-1.5-3.5-3.5s1.5-3.5,3.5-3.5c2,0,3.5,1.5,3.5,3.5S424.9,510.1,423.1,510.1z M434.8,510.1
	c-2,0-3.5-1.5-3.5-3.5s1.5-3.5,3.5-3.5c2,0,3.5,1.5,3.5,3.5S436.7,510.1,434.8,510.1z M441.8,519.5c-2,0-3.5-1.5-3.5-3.5
	s1.5-3.5,3.5-3.5c2,0,3.5,1.5,3.5,3.5S443.7,519.5,441.8,519.5z"
        />
      </g>
      <g
        id="ikona_4"
        transform="translate(0,-11.249983)"
        ref={iconRef.current[3]}
      >
        <g id="g1608" transform="translate(1073.6514,-6760.5757)">
          <path
            className="st2"
            d="M-868.6,7270.5h-42.8c-0.8,0-1.4,0.6-1.4,1.4l0,0v28.6c0,0.8,0.6,1.4,1.4,1.4l0,0h42.8c0.8,0,1.4-0.6,1.4-1.4
			V7272C-867.2,7271.1-867.8,7270.5-868.6,7270.5z M-870,7299.1h-40v-25.7h40V7299.1z"
          />
          <path
            id="path1541"
            className="st2"
            d="M-891.5,7300.6v7.2h2.8v-7.2H-891.5z"
          />
          <path
            id="path1543"
            className="st2"
            d="M-898.7,7306.2v2.8h1.4h14.3h1.4v-2.8h-1.4h-14.3H-898.7z"
          />
          <path
            id="path1559"
            ref={screenLineRef}
            className="st1"
            d="M-878.8,7278.1c-0.8,0-1.5,0.7-1.5,1.5c0,0.4,0.1,0.6,0.4,0.9l-5.8,8.1c-0.1,0-0.4-0.1-0.5-0.1
			c-0.2,0-0.5,0-0.7,0.1l-5.4-5.4c0.1-0.2,0.1-0.5,0.1-0.7c0-0.8-0.7-1.5-1.5-1.5s-1.5,0.7-1.5,1.5c0,0.4,0.1,0.6,0.4,0.9l-5.8,8.1
			c-0.2-0.1-0.4-0.1-0.6-0.1c-0.8,0-1.5,0.7-1.5,1.5s0.7,1.5,1.5,1.5s1.5-0.7,1.5-1.5c0-0.4-0.1-0.6-0.2-0.8l5.8-8.2
			c0.1,0,0.4,0.1,0.5,0.1c0.2,0,0.5,0,0.7-0.1l5.4,5.4c-0.1,0.2-0.1,0.5-0.1,0.7c0,0.8,0.7,1.5,1.5,1.5s1.5-0.7,1.5-1.5
			c0-0.4-0.1-0.6-0.4-0.9l5.8-8.1c0.1,0,0.4,0.1,0.5,0.1c0.8,0,1.5-0.7,1.5-1.5S-878,7278.1-878.8,7278.1L-878.8,7278.1z"
          />
        </g>
      </g>
      <g id="ikona_5" ref={iconRef.current[4]}>
        <path
          colorRendering="auto"
          imageRendering="auto"
          shapeRendering="auto"
          className="st1"
          d="M173,734.3c-0.9,0-1.6,0.9-1.3,1.8
		l8.6,26.3c0.4,1.1,1.9,1.3,2.5,0.2l4-6.5l5.6,5.6c0.5,0.6,1.4,0.6,1.9,0l4.8-4.8c0.5-0.6,0.5-1.4,0-1.9l-5.6-5.6l6.5-3.9
		c0.9-0.6,0.8-2.1-0.2-2.5l-26.3-8.6C173.2,734.3,173.1,734.3,173,734.3L173,734.3z"
        />
        <path
          colorRendering="auto"
          imageRendering="auto"
          shapeRendering="auto"
          className="st2 st5"
          d="M174.5,725.4c-0.5,0-0.9,0.5-0.9,0.9
		v3.9c0,0.6,0.5,0.9,0.9,0.9c0.5,0,0.9-0.5,0.9-0.9v-3.9C175.6,725.9,175.1,725.4,174.5,725.4C174.7,725.4,174.5,725.4,174.5,725.4z
		"
        />
        <path
          colorRendering="auto"
          imageRendering="auto"
          shapeRendering="auto"
          className="st2 st5"
          d="M182.3,728.7c-0.2,0-0.5,0.1-0.7,0.4
		l-3.2,3.2c-0.4,0.4-0.4,0.9,0,1.4c0.4,0.4,0.9,0.4,1.4,0l0,0l3.2-3.2c0.4-0.4,0.4-0.9,0-1.4C182.8,728.8,182.5,728.7,182.3,728.7
		L182.3,728.7z"
        />
        <path
          colorRendering="auto"
          imageRendering="auto"
          shapeRendering="auto"
          className="st2 st5"
          d="M163.7,736.3c-0.6,0-0.9,0.4-0.9,0.9
		c0,0.6,0.4,0.9,0.9,0.9c0,0,0,0,0.1,0h4.9c0.6,0,0.9-0.4,0.9-0.9c0-0.6-0.4-0.9-0.9-0.9c0,0,0,0-0.1,0H163.7z"
        />
        <path
          colorRendering="auto"
          imageRendering="auto"
          shapeRendering="auto"
          className="st2 st5"
          d="M166.9,728.7c-0.6,0-0.9,0.5-0.9,0.9
		c0,0.2,0.1,0.5,0.4,0.7l2.7,2.7c0.4,0.4,0.9,0.4,1.4,0c0.4-0.4,0.4-0.9,0-1.4l0,0l-2.7-2.7C167.4,728.8,167.1,728.7,166.9,728.7
		L166.9,728.7z"
        />
        <path
          colorRendering="auto"
          imageRendering="auto"
          shapeRendering="auto"
          className="st2 st5"
          d="M170.2,740.8c-0.2,0-0.5,0.1-0.7,0.4
		l-3.2,3.2c-0.4,0.4-0.4,0.9,0,1.4c0.4,0.4,0.9,0.4,1.4,0l0,0l3.2-3.2c0.4-0.4,0.4-0.9,0-1.4C170.7,740.9,170.4,740.8,170.2,740.8
		L170.2,740.8z"
        />
      </g>
      <g id="ikona_6" ref={iconRef.current[5]}>
        <circle className="st1" cx="428.3" cy="733.6" r="9.5" />
        <path
          className="st1"
          d="M446.3,755.1L446.3,755.1c-0.5-2.8-2.1-5.8-4.5-7.4c-2.6-1.8-5.9-2.1-9.2-2.1l0,0l-3.5,5l3.5,8.5l-3.8,5.4
		l-3.8-5.4l3.5-8.5l-3.5-4.9c-3.2,0.1-6.2,0.6-8.8,2.2c-2.3,1.5-4.2,4.3-4.8,7.2c-0.5,2.2-2.2,5,1.3,6.7c3.5,1.8,10.7,2.8,16.1,2.8
		c5.4,0,12.4-1.1,15.8-2.8C448.2,760.1,446.7,757.4,446.3,755.1z"
        />
      </g>
      <polygon
        id="szescian"
        className="st1"
        points="236.8,83.1 236.8,161.8 305,201.2 373.2,161.8 373.2,83.1 305,43.8 "
        ref={hexagonRef}
      />
      <line
        id="linia_1"
        className="st3"
        x1="236.8"
        y1="124"
        x2="50"
        y2="124"
        ref={lineRef.current[0]}
      />
      <line
        id="linia_2"
        className="st3"
        x1="51"
        y1="124"
        x2="51"
        y2="292.9"
        ref={lineRef.current[1]}
      />
      <line
        id="linia_3"
        className="st3"
        x1="134"
        y1="291.9"
        x2="51"
        y2="291.9"
        ref={lineRef.current[2]}
      />
      <line
        id="linia_4_1_"
        className="st3"
        x1="379.8"
        y1="291.9"
        x2="229.4"
        y2="291.9"
        ref={lineRef.current[3]}
      />
      <line
        id="linia_5"
        className="st3"
        x1="558"
        y1="291.9"
        x2="475"
        y2="291.9"
        ref={lineRef.current[4]}
      />
      <line
        id="linia_6_1_"
        className="st3"
        x1="557.2"
        y1="290.9"
        x2="557.2"
        y2="518.7"
        ref={lineRef.current[5]}
      />
      <line
        id="linia_7"
        className="st3"
        x1="558.2"
        y1="518.3"
        x2="475.3"
        y2="518.3"
        ref={lineRef.current[6]}
      />
      <line
        id="linia_8"
        className="st3"
        x1="380"
        y1="518.3"
        x2="229.6"
        y2="518.3"
        ref={lineRef.current[7]}
      />
      <line
        id="linia_9"
        className="st3"
        x1="134.2"
        y1="518.3"
        x2="51.2"
        y2="518.3"
        ref={lineRef.current[8]}
      />
      <line
        id="linia_10"
        className="st3"
        x1="51"
        y1="517.2"
        x2="51"
        y2="744.1"
        ref={lineRef.current[9]}
      />
      <line
        id="linia_11"
        className="st3"
        x1="134.1"
        y1="743.1"
        x2="51.2"
        y2="743.1"
        ref={lineRef.current[10]}
      />
      <line
        id="linia_12"
        className="st3"
        x1="380"
        y1="743.1"
        x2="229.6"
        y2="743.1"
        ref={lineRef.current[11]}
      />
    </Svg>
  );
};

export default CooperationDesktop;
