import styled from 'styled-components';

export const SectionWrapper = styled.div`
  width: 100%;
  height: auto;
  margin-top: 40px;

  @media (min-width: 850px) {
    width: calc(100% - 50px);
    height: 50%;
    margin-left: 50px;
    margin-top: 0px;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
  }

  @media (min-width: 1100px) {
    @media (min-height: 600px) {
      height: 434px;
    }
  }
`;

export const AnimationWrapper = styled.div`
  width: 100%;
  height: auto;

  @media (min-width: 850px) {
    width: 50%;
    order: 2;
  }
`;

export const TextWrapper = styled.div`
  width: 100%;
  height: auto;

  @media (min-width: 850px) {
    width: 50%;
    order: 1;
    max-width: 600px;
  }
`;

export const Text = styled.p`
  width: 100%;
  height: auto;
  padding: 15px;
  opacity: 0;
  text-align: center;
  color: ${({ theme }) => theme.colors.grey};
  font-size: ${({ theme }) => theme.font.s};
  font-weight: ${({ theme }) => theme.fontWeight.regular};

  @media (min-width: 850px) {
    text-align: left;
    font-size: ${({ theme }) => theme.font.s};
  }
`;

export const TextLine = styled.span`
  display: inline-block;
`;

export const SquareWrapper = styled.div`
  padding: 0 15px;
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;

  @media (min-width: 1200px) {
    height: 67px;
    margin-top: 50px;
  }

  @media (min-width: 1400px) {
    height: 83px;
  }
`;
