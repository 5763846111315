import React, { useEffect, createRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import gsap from 'gsap';
import CooperationMobile from 'components/cooperationMobile/cooperationMobile';
import CooperationDesktop from 'components/cooperationDesktop/cooperationDesktop';
import CircleLeft from 'components/circle/circleLeft';
import CircleRight from 'components/circle/circleRight';
import {
  SectionWrapper,
  BackgroundWrapper,
  SvgWrapper,
  TextWrapper,
  HexagonText,
  FirstText,
  SecondText,
  ThirdText,
  FourthText,
  FifthText,
  SixthText,
  TextLine,
  CircleTopWrapper,
  CircleBottomWrapper,
  CircleRightWrapper,
  CircleWrapper,
} from './cooperation.styled';

const firstTextRef = createRef();
const secondTextRef = createRef();
const thirdTextRef = createRef();
const fourthTextRef = createRef();
const fifthTextRef = createRef();
const sixthTextRef = createRef();

const tl = gsap.timeline();

const Cooperation = () => {
  useEffect(() => {
    tl.to(firstTextRef.current, 1.25, { opacity: 0 })
      .set(firstTextRef.current, { opacity: 1 })
      .staggerFrom(
        firstTextRef.current.children,
        0.5,
        { opacity: 0, x: -20, y: -20 },
        0.15
      )
      .staggerFrom(
        secondTextRef.current.children,
        0.5,
        { opacity: 0, x: -20, y: -20, delay: 0.25 },
        0.15
      )
      .staggerFrom(
        fourthTextRef.current.children,
        0.5,
        { opacity: 0, x: -20, y: -20 },
        0.15
      )
      .staggerFrom(
        thirdTextRef.current.children,
        0.5,
        { opacity: 0, x: -20, y: -20, delay: 0.5 },
        0.15
      )
      .staggerFrom(
        fifthTextRef.current.children,
        0.5,
        { opacity: 0, x: -20, y: -20 },
        0.15
      )
      .staggerFrom(
        sixthTextRef.current.children,
        0.5,
        { opacity: 0, x: -20, y: -20 },
        0.15
      );
  });

  const { image } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "background.png" }) {
        sharp: childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);

  return (
    <BackgroundWrapper Tag="div" fluid={image.sharp.fluid}>
      <SectionWrapper>
        <SvgWrapper>
          <CooperationMobile />
          <CooperationDesktop />
        </SvgWrapper>
        <TextWrapper>
          <FirstText ref={firstTextRef}>
            <TextLine>Lista&nbsp;</TextLine>
            <TextLine>pytań&nbsp;</TextLine>
            <TextLine>dotycząca&nbsp;</TextLine>
            <TextLine>zamówienia&nbsp;</TextLine>
          </FirstText>
          <SecondText ref={secondTextRef}>
            <TextLine>Wizja&nbsp;</TextLine>
            <TextLine>zleceniodawcy&nbsp;</TextLine>
          </SecondText>
          <ThirdText ref={thirdTextRef}>
            <TextLine>Wspólne&nbsp;</TextLine>
            <TextLine>określenie&nbsp;</TextLine>
            <TextLine>koncepcji&nbsp;</TextLine>
            <TextLine>działania&nbsp;</TextLine>
          </ThirdText>
          <FourthText ref={fourthTextRef}>
            <TextLine>Nasza&nbsp;</TextLine>
            <TextLine>propozycja&nbsp;</TextLine>
          </FourthText>
          <FifthText ref={fifthTextRef}>
            <TextLine>Realizacja&nbsp;</TextLine>
          </FifthText>
          <SixthText ref={sixthTextRef}>
            <TextLine>Wynik:&nbsp;</TextLine>
            <TextLine>zadowolony&nbsp;</TextLine>
            <TextLine>klient&nbsp;</TextLine>
          </SixthText>
          <HexagonText>
            <TextLine>Jak&nbsp;</TextLine>
            <TextLine>wygląda&nbsp;</TextLine>
            <TextLine>Współpraca ?</TextLine>
          </HexagonText>
        </TextWrapper>
      </SectionWrapper>
      <CircleTopWrapper>
        <CircleLeft />
      </CircleTopWrapper>
      <CircleBottomWrapper>
        <CircleLeft />
      </CircleBottomWrapper>
      <CircleRightWrapper>
        <CircleRight />
      </CircleRightWrapper>
      <CircleWrapper>
        <CircleRight />
      </CircleWrapper>
    </BackgroundWrapper>
  );
};

export default Cooperation;
