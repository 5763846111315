import styled from 'styled-components';

export const Svg = styled.svg`
  width: 100%;
  height: 100%;
  display: none;

  @media (min-width: 850px) {
    display: initial;
  }

  .st0 {
    fill: transparent;
  }
  .st1 {
    fill: #ffffff;
  }
  .st2 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #ffffff;
  }
  .st3 {
    fill: none;
    stroke: #ffffff;
    stroke-width: 2;
    stroke-miterlimit: 10;
  }
  .st4 {
    enable-background: new 0 0 600.8 842.3;
  }
  .st5 {
    opacity: 0;
  }
`;
