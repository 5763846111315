import React, { createRef, useEffect } from 'react';
import gsap from 'gsap';
import SvgAboutUs from 'components/svgAboutUs/svgAboutUs';
import SquareAnimationNoMargin from 'components/squareAnimationNoMargin/squareAnimationNoMargin';
import {
  SectionWrapper,
  AnimationWrapper,
  TextWrapper,
  Text,
  TextLine,
  SquareWrapper,
} from './animation.styled';

const Animation = () => {
  const firstParagraphRef = createRef();
  const secondParagraphRef = createRef();

  const tl = gsap.timeline();

  useEffect(() => {
    tl.set([firstParagraphRef.current, secondParagraphRef.current], {
      opacity: 1,
    })
      .staggerFrom(
        firstParagraphRef.current.children,
        0.45,
        {
          x: -20,
          y: -20,
          opacity: 0,
        },
        0.025
      )
      .staggerFrom(
        secondParagraphRef.current.children,
        0.45,
        {
          x: -20,
          y: -20,
          opacity: 0,
        },
        0.025
      );
  });

  return (
    <SectionWrapper>
      <AnimationWrapper>
        <SvgAboutUs />
      </AnimationWrapper>
      <TextWrapper>
        <Text ref={firstParagraphRef}>
          <TextLine>Współpracę&nbsp;</TextLine>
          <TextLine>opieramy&nbsp;</TextLine>
          <TextLine>na&nbsp;</TextLine>
          <TextLine>szczegółowym&nbsp;</TextLine>
          <TextLine>dialogu&nbsp;</TextLine>
          <TextLine>-&nbsp;</TextLine>
          <TextLine>łączymy&nbsp;</TextLine>
          <TextLine>wizję&nbsp;</TextLine>
          <TextLine>klientów&nbsp;</TextLine>
          <TextLine>z&nbsp;</TextLine>
          <TextLine>naszą&nbsp;</TextLine>
          <TextLine>specjalistyczną&nbsp;</TextLine>
          <TextLine>wiedzą&nbsp;</TextLine>
          <TextLine>by&nbsp;</TextLine>
          <TextLine>stworzyć&nbsp;</TextLine>
          <TextLine>innowacyjny&nbsp;</TextLine>
          <TextLine>i&nbsp;</TextLine>
          <TextLine>funkcjonalny&nbsp;</TextLine>
          <TextLine>projekt&nbsp;</TextLine>
          <TextLine>dopasowany&nbsp;</TextLine>
          <TextLine>do&nbsp;</TextLine>
          <TextLine>potrzeb&nbsp;</TextLine>
          <TextLine>klienta.&nbsp;</TextLine>
        </Text>
        <Text ref={secondParagraphRef}>
          <TextLine>Staramy&nbsp;</TextLine>
          <TextLine>się&nbsp;</TextLine>
          <TextLine>zapewnić&nbsp;</TextLine>
          <TextLine>jak&nbsp;</TextLine>
          <TextLine>największy&nbsp;</TextLine>
          <TextLine>komfort,&nbsp;</TextLine>
          <TextLine>dlatego&nbsp;</TextLine>
          <TextLine>wprowadzamy&nbsp;</TextLine>
          <TextLine>w&nbsp;</TextLine>
          <TextLine>życie&nbsp;</TextLine>
          <TextLine>wizję&nbsp;</TextLine>
          <TextLine>zleceniodawcy&nbsp;</TextLine>
          <TextLine>ale&nbsp;</TextLine>
          <TextLine>również&nbsp;</TextLine>
          <TextLine>dbamy&nbsp;</TextLine>
          <TextLine>o&nbsp;</TextLine>
          <TextLine>dalszy&nbsp;</TextLine>
          <TextLine>proces&nbsp;</TextLine>
          <TextLine>realizacji&nbsp;</TextLine>
          <TextLine>zamówienia&nbsp;</TextLine>
          <TextLine>-&nbsp;</TextLine>
          <TextLine>druk&nbsp;</TextLine>
          <TextLine>oraz&nbsp;</TextLine>
          <TextLine>dostawę&nbsp;</TextLine>
          <TextLine>gotowego&nbsp;</TextLine>
          <TextLine>produktu.&nbsp;</TextLine>
        </Text>
        <SquareWrapper>
          <SquareAnimationNoMargin />
        </SquareWrapper>
      </TextWrapper>
    </SectionWrapper>
  );
};

export default Animation;
