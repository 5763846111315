import styled from 'styled-components';

export const Svg = styled.svg`
  width: 100%;
  height: 100%;
  .st0 {
    fill: #a8cfde;
  }
  .st1 {
    opacity: 0.3;
  }
  .st2 {
    fill: #ffffff;
  }
  .st3 {
    enable-background: new;
  }
  .st4 {
    fill: #243551;
  }
  .st5 {
    fill: #0d6189;
  }
  .st6 {
    fill: #c1deea;
  }
  .st7 {
    fill: #eaf3f5;
  }
  .st8 {
    fill: #daebf2;
  }
  .st9 {
    fill: #ff8484;
  }
  .st10 {
    fill: #0081a7;
  }
  .st11 {
    fill: #f69750;
  }
  .st12 {
    fill: #84c3d7;
  }
  .st13 {
    fill: #ddebf1;
  }
  .st14 {
    opacity: 0.5;
    fill: url(#SVGID_1_);
    enable-background: new;
  }
  .st15 {
    fill: #83c2d6;
  }
  .st16 {
    opacity: 0.15;
    fill: #00586c;
    enable-background: new;
  }
  .st17 {
    opacity: 0.1;
    fill: #00586c;
    enable-background: new;
  }
  .st18 {
    opacity: 0.5;
    fill: #00586c;
    enable-background: new;
  }
  .st19 {
    fill: #f7b7a9;
  }
  .st20 {
    fill: #1b283d;
  }
  .st21 {
    fill: #fbb597;
  }
  .st22 {
    fill: #45393f;
  }
  .st23 {
    fill: #b77b65;
  }
  .st24 {
    opacity: 0.06;
    fill: #00586c;
    enable-background: new;
  }
  .st25 {
    opacity: 0.09;
    fill: #00586c;
    enable-background: new;
  }
  .st26 {
    opacity: 0.2;
    fill: #1d1d1b;
    enable-background: new;
  }
  .st27 {
    opacity: 0.1;
    fill: #1d1d1b;
    enable-background: new;
  }
  .st28 {
    fill: #91664f;
  }
  .st29 {
    opacity: 0.3;
    fill: #00586c;
    enable-background: new;
  }
  .st30 {
    opacity: 0.3;
    fill: #243551;
    enable-background: new;
  }
  .st31 {
    opacity: 0.5;
  }
  .st32 {
    opacity: 0.5;
    fill: #243551;
    enable-background: new;
  }
  .st33 {
    fill: #69b5cf;
  }
  .st34 {
    opacity: 0.4;
    fill: #ffffff;
    enable-background: new;
  }
  .st35 {
    opacity: 0.702;
    fill: #ffffff;
    enable-background: new;
  }
  .st36 {
    opacity: 0.08;
    fill: #00586c;
    enable-background: new;
  }
  .st37 {
    opacity: 0.05;
    enable-background: new;
  }
  .st38 {
    fill: #81524f;
  }
  .st39 {
    opacity: 0.07;
    enable-background: new;
  }
  .st40 {
    fill: #e9ad9f;
  }
  .st41 {
    opacity: 0.2;
    fill: url(#SVGID_2_);
    enable-background: new;
  }
  .st42 {
    opacity: 0.4;
    fill: #1b283d;
    enable-background: new;
  }
  .st43 {
    opacity: 0.1;
  }
  .st44 {
    opacity: 0.7;
  }
  .st45 {
    fill: #f58a3b;
  }
  .st46 {
    fill: #e5eaed;
  }
  .st47 {
    fill: #8ec1d4;
  }
  .st48 {
    font-family: 'Century Gothic', sans-serif;
  }
  .st49 {
    font-size: 16px;
  }
  .st50 {
    enable-background: new 0 0 483.32 536.42;
  }
  .st51 {
    stop-color: #ffffff;
  }
  .st52 {
    stop-color: #aac4d8;
  }
  .st53 {
    stop-color: #003f6c;
  }
  .st54 {
    fill: #942985;
  }
`;
