import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';

export const BackgroundWrapper = styled(BackgroundImage)`
  background-size: cover;
  width: 100%;
  height: initial;
`;

export const SectionWrapper = styled.div`
  width: 100%;
  height: auto;

  @media (min-width: 850px) {
    width: calc(100% - 50px);
    margin-left: 50px;
  }
`;

export const SvgWrapper = styled.div`
  width: 100%;
  height: auto;

  @media (min-width: 850px) {
    padding-top: 20px;
    width: 650px;
    height: auto;
  }
`;

export const TextWrapper = styled.div`
  display: none;

  @media (min-width: 850px) {
    width: 100%;
    height: 100px;
    display: flex;
  }
`;

export const HexagonText = styled.p`
  text-align: center;
  width: 170px;
  position: absolute;
  top: 135px;
  left: 300px;
  font-size: ${({ theme }) => theme.font.s};
  color: ${({ theme }) => theme.colors.grey};
`;

export const FirstText = styled.p`
  text-align: center;
  width: 200px;
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.font.s};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  position: absolute;
  top: 400px;
  left: 150px;
`;

export const SecondText = styled.p`
  text-align: center;
  width: 200px;
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.font.s};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  position: absolute;
  top: 400px;
  left: 420px;
`;

export const ThirdText = styled.p`
  text-align: center;
  width: 200px;
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.font.s};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  position: absolute;
  top: 650px;
  left: 150px;
`;

export const FourthText = styled.p`
  text-align: center;
  width: 200px;
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.font.s};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  position: absolute;
  top: 650px;
  left: 420px;
`;

export const FifthText = styled.p`
  text-align: center;
  width: 200px;
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.font.s};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  position: absolute;
  top: 900px;
  left: 150px;
`;

export const SixthText = styled.p`
  text-align: center;
  width: 200px;
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.font.s};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  position: absolute;
  top: 900px;
  left: 420px;
`;

export const TextLine = styled.span`
  display: inline-block;
`;

export const CircleTopWrapper = styled.div`
  position: absolute;
  width: 100px;
  height: auto;
  top: 180px;
  left: -60px;

  @media (min-width: 850px) {
    display: none;
  }

  @media (min-width: 1200px) {
    display: block;
    top: 250px;
    left: 800px;
  }
`;

export const CircleBottomWrapper = styled.div`
  position: absolute;
  width: 150px;
  height: auto;
  top: 350px;
  right: -100px;

  @media (min-width: 850px) {
    display: none;
  }

  @media (min-width: 1200px) {
    display: block;
    top: 200px;
    left: 980px;
  }
`;

export const CircleRightWrapper = styled.div`
  position: absolute;
  width: 100px;
  height: auto;
  top: 600px;
  right: -30px;

  @media (min-width: 850px) {
    display: none;
  }

  @media (min-width: 1200px) {
    display: block;
    top: 800px;
    left: 750px;
  }
`;

export const CircleWrapper = styled.div`
  position: absolute;
  width: 200px;
  height: auto;
  top: 650px;
  left: -120px;

  @media (min-width: 850px) {
    display: none;
  }

  @media (min-width: 1200px) {
    display: block;
    width: 400px;
    top: 400px;
    left: 800px;
  }
`;
