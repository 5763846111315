import React, { useEffect, createRef } from 'react';
import gsap from 'gsap';
import { ScrollScene } from 'scrollscene';

import { Svg } from './cooperationMobile.styled';

const animationStartRef = createRef();

const hexagonRef = createRef();
const hexagonTextRef = createRef();

const questionRef = createRef();
const bulbRef = createRef();
const paletteRef = createRef();
const screenRef = createRef();
const screenLineRef = createRef();
const pointerRef = createRef();
const clientRef = createRef();

const firstCircleRef = createRef();
const secondCircleRef = createRef();
const thirdCircleRef = createRef();
const fourthCircleRef = createRef();
const fifthCircleRef = createRef();
const sixthCircleRef = createRef();

const firstLineRef = createRef();
const secondLineRef = createRef();
const thirdLineRef = createRef();
const fourthLineRef = createRef();
const fifthLineRef = createRef();
const sixthLineRef = createRef();

const firstTextRef = createRef();
const secondTextRef = createRef();
const thirdTextRef = createRef();
const fourthTextRef = createRef();
const fifthTextRef = createRef();
const sixthTextRef = createRef();

const cooperationMobile = () => {
  const tlMobile = gsap.timeline({ paused: true });

  const questionMove = gsap.timeline({
    paused: true,
    repeat: -1,
    repeatDelay: 2,
    yoyo: true,
  });

  const screenDrawLine = gsap.timeline({
    paused: true,
    repeat: -1,
    repeatDelay: 2,
    yoyo: true,
  });

  const pointerClick = gsap.timeline({
    paused: true,
    repeat: -1,
    repeatDelay: 1,
  });

  const clientMove = gsap.timeline({
    paused: true,
    repeat: -1,
    repeatDelay: 2,
    yoyo: true,
  });

  const bulbShake = gsap.timeline({
    paused: true,
    repeat: -1,
    repeatDelay: 1,
  });

  const paletteMove = gsap.timeline({
    paused: true,
    repeat: -1,
    repeatDelay: 2,
  });

  function onQuestionComplete() {
    questionMove.play();
  }

  function onBulbComplete() {
    bulbShake.play();
  }

  function onPaletteComplete() {
    paletteMove.play();
  }

  function onScreenComplete() {
    screenDrawLine.play();
  }

  function onPointerComplete() {
    pointerClick.play();
  }

  function onClientComplete() {
    clientMove.play();
  }

  useEffect(() => {
    questionMove
      .to(questionRef.current.children[0], 0.3, {
        scale: 1.5,
        transformOrigin: 'center center',
      })
      .to(questionRef.current.children[1], 0.3, {
        x: 5,
        scale: 1.2,
        transformOrigin: 'center center',
      })
      .to(questionRef.current.children[2], 0.3, {
        x: -5,
        scale: 1.2,
        transformOrigin: 'center center',
      });
    bulbShake
      .fromTo(
        bulbRef.current,
        0.2,
        {
          x: 1,
        },
        { x: -1 }
      )
      .fromTo(
        bulbRef.current,
        0.2,
        {
          x: 1,
        },
        { x: -1 }
      )
      .fromTo(
        bulbRef.current,
        0.2,
        {
          x: 1,
        },
        { x: -1 }
      )
      .fromTo(
        bulbRef.current,
        0.2,
        {
          x: 1,
        },
        { x: -1 }
      )
      .to(bulbRef.current, 0.2, {
        x: 0,
      });
    paletteMove
      .to(paletteRef.current, 0.3, { y: -10 })
      .to(paletteRef.current, 0.3, { y: 0 })
      .to(paletteRef.current, 0.2, {
        scaleY: 0.9,
        transformOrigin: 'center 100%',
      })
      .to(paletteRef.current, 0.2, {
        scaleY: 1,
        transformOrigin: 'center 100%',
      });
    screenDrawLine.fromTo(
      screenLineRef.current,
      0.3,
      {
        scaleX: 0,
      },
      { scale: 1 }
    );
    pointerClick
      .addLabel('mouseClick')
      .to(
        [
          pointerRef.current.children[1],
          pointerRef.current.children[2],
          pointerRef.current.children[3],
          pointerRef.current.children[4],
          pointerRef.current.children[5],
        ],
        0.3,
        { opacity: 1 },
        'mouseClick'
      )
      .to(
        pointerRef.current.children[0],
        0.3,
        {
          scale: 1.2,
          transformOrigin: 'center center',
        },
        'mouseClick'
      )
      .addLabel('mouseNotCLick')
      .to(
        pointerRef.current.children[0],
        0.3,
        {
          scale: 1,
          transformOrigin: 'center center',
        },
        'mouseNotCLick'
      )
      .to(
        [
          pointerRef.current.children[1],
          pointerRef.current.children[2],
          pointerRef.current.children[3],
          pointerRef.current.children[4],
          pointerRef.current.children[5],
        ],
        0.3,
        { opacity: 0 },
        'mouseNotCLick'
      );
    clientMove.to(clientRef.current, 1, { x: 0, opacity: 1 });
    tlMobile
      .fromTo(
        hexagonRef.current,
        0.3,
        { opacity: 1, scale: 0, transformOrigin: 'center center' },
        { scale: 1, transformOrigin: 'center center' }
      )
      .to(hexagonTextRef.current, 0.3, { opacity: 1 })
      .fromTo(
        firstLineRef.current,
        0.3,
        { opacity: 1, scaleY: 0 },
        { scaleY: 1 }
      )
      .fromTo(
        firstCircleRef.current,
        0.3,
        { opacity: 1, scale: 0, transformOrigin: 'center 0' },
        { scale: 1, transformOrigin: 'center 0' }
      )
      .to(questionRef.current, 0.3, { opacity: 1, onQuestionComplete })
      .to(firstTextRef.current, 0.001, { opacity: 1 })
      .staggerFrom(
        firstTextRef.current.children[0].children,
        0.45,
        {
          x: -50,
          opacity: 0,
        },
        0.025
      )
      .fromTo(
        secondLineRef.current,
        0.3,
        { opacity: 1, scaleY: 0 },
        { scaleY: 1 }
      )
      .fromTo(
        secondCircleRef.current,
        0.3,
        { opacity: 1, scale: 0, transformOrigin: 'center 0' },
        { scale: 1, transformOrigin: 'center 0' }
      )
      .to(bulbRef.current, 0.3, { opacity: 1, onBulbComplete })
      .to(secondTextRef.current, 0.001, { opacity: 1 })
      .staggerFrom(
        secondTextRef.current.children,
        0.45,
        {
          x: 50,
          opacity: 0,
        },
        0.025
      )
      .fromTo(
        thirdLineRef.current,
        0.3,
        { opacity: 1, scaleY: 0 },
        { scaleY: 1 }
      )
      .fromTo(
        thirdCircleRef.current,
        0.3,
        { opacity: 1, scale: 0, transformOrigin: 'center 0' },
        { scale: 1, transformOrigin: 'center 0' }
      )
      .to(paletteRef.current, 0.3, { opacity: 1, onPaletteComplete })
      .to(thirdTextRef.current, 0.001, { opacity: 1 })
      .staggerFrom(
        thirdTextRef.current.children,
        0.45,
        {
          x: -50,
          opacity: 0,
        },
        0.025
      )
      .fromTo(
        fourthLineRef.current,
        0.3,
        { opacity: 1, scaleY: 0 },
        { scaleY: 1 }
      )
      .fromTo(
        fourthCircleRef.current,
        0.3,
        { opacity: 1, scale: 0, transformOrigin: 'center 0' },
        { scale: 1, transformOrigin: 'center 0' }
      )
      .to(screenRef.current, 0.3, { opacity: 1, onScreenComplete })
      .to(fourthTextRef.current, 0.001, { opacity: 1 })
      .staggerFrom(
        fourthTextRef.current.children,
        0.45,
        {
          x: 50,
          opacity: 0,
        },
        0.025
      )
      .fromTo(
        fifthLineRef.current,
        0.3,
        { opacity: 1, scaleY: 0 },
        { scaleY: 1 }
      )
      .fromTo(
        fifthCircleRef.current,
        0.3,
        { opacity: 1, scale: 0, transformOrigin: 'center 0' },
        { scale: 1, transformOrigin: 'center 0' }
      )
      .to(pointerRef.current, 0.3, { opacity: 1, onPointerComplete })
      .to(fifthTextRef.current, 0.001, { opacity: 1 })
      .staggerFrom(
        fifthTextRef.current.children,
        0.45,
        {
          x: -50,
          opacity: 0,
        },
        0.025
      )
      .fromTo(
        sixthLineRef.current,
        0.3,
        { opacity: 1, scaleY: 0 },
        { scaleY: 1 }
      )
      .fromTo(
        sixthCircleRef.current,
        0.3,
        { opacity: 1, scale: 0, transformOrigin: 'center 0' },
        { scale: 1, transformOrigin: 'center 0' }
      )
      .to(clientRef.current, 0.0001, { x: 40, onClientComplete })
      .to(sixthTextRef.current, 0.001, { opacity: 1 })
      .staggerFrom(
        sixthTextRef.current.children[0].children,
        0.45,
        {
          x: 50,
          opacity: 0,
        },
        0.025
      )
      .staggerFrom(
        sixthTextRef.current.children[1].children,
        0.45,
        {
          x: 50,
          opacity: 0,
        },
        0.025
      );

    // eslint-disable-next-line no-unused-vars
    const scrollscene = new ScrollScene({
      triggerElement: animationStartRef.current,
      reverse: false,
      gsap: {
        timeline: tlMobile,
      },
    });
  });

  return (
    <Svg
      version="1.1"
      id="Warstwa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 375 956.8"
      classNameName="st5"
      ref={animationStartRef}
    >
      <rect
        x="188"
        y="150.8"
        className="st0 st6"
        width="2"
        height="58.9"
        ref={firstLineRef}
      />
      <g ref={firstCircleRef} className="st6">
        <circle className="st1" cx="189" cy="238.3" r="29.6" />
        <path
          className="st0"
          d="M189,269c-16.9,0-30.6-13.7-30.6-30.6s13.7-30.7,30.6-30.7s30.6,13.7,30.6,30.6S205.9,269,189,269z M189,209.7
		c-15.8,0-28.6,12.8-28.6,28.6s12.8,28.6,28.6,28.6s28.6-12.8,28.6-28.6S204.8,209.7,189,209.7z"
        />
      </g>
      <g className="st6" ref={questionRef}>
        <g>
          <path
            className="st0"
            d="M186.2,236.6h-3.6c0-1.8,0.6-3.2,1.8-4.4c1.2-1.1,2.8-1.7,4.8-1.7c1.3,0,2.4,0.2,3.3,0.7
			c0.9,0.5,1.6,1.2,2.2,2.1c0.5,0.9,0.8,1.8,0.8,2.8c0,0.5-0.1,1.1-0.3,1.6s-0.4,1.1-0.8,1.6c-0.2,0.3-0.7,0.9-1.6,1.6
			c-0.8,0.7-1.3,1.2-1.5,1.5s-0.4,0.7-0.5,1.2s-0.2,1.2-0.3,2.1h-3.7v-0.6c0-1.2,0.2-2.3,0.7-3.3s1.2-1.9,2.2-2.8
			c1-0.9,1.6-1.4,1.8-1.8c0.2-0.4,0.3-0.7,0.3-1.1c0-0.6-0.2-1.1-0.7-1.5s-1.1-0.6-2-0.6c-0.8,0-1.5,0.3-2.1,0.7
			C186.5,235.1,186.2,235.8,186.2,236.6z M188.8,247.2c0.7,0,1.2,0.2,1.7,0.7s0.7,1,0.7,1.6s-0.2,1.2-0.7,1.7s-1,0.7-1.7,0.7
			s-1.2-0.2-1.7-0.7s-0.7-1-0.7-1.7s0.2-1.2,0.7-1.6C187.6,247.4,188.2,247.2,188.8,247.2z"
          />
        </g>
        <g>
          <path
            className="st0"
            d="M202,236.5h-2.2c0-1.1,0.4-2,1.1-2.7s1.8-1.1,3-1.1c0.8,0,1.5,0.1,2,0.4c0.6,0.3,1,0.7,1.3,1.3
			s0.5,1.1,0.5,1.7c0,0.3-0.1,0.7-0.2,1c-0.1,0.3-0.3,0.6-0.5,1c-0.1,0.2-0.5,0.5-1,1c-0.5,0.4-0.8,0.8-0.9,0.9
			c-0.1,0.1-0.2,0.4-0.3,0.7s-0.1,0.7-0.2,1.3h-2.3v-0.4c0-0.8,0.1-1.4,0.4-2.1s0.7-1.2,1.3-1.7s1-0.9,1.1-1.1
			c0.1-0.2,0.2-0.4,0.2-0.7c0-0.4-0.2-0.7-0.4-0.9s-0.7-0.4-1.2-0.4s-0.9,0.1-1.3,0.5C202.2,235.6,202,236,202,236.5z M203.6,243
			c0.4,0,0.8,0.1,1.1,0.4c0.3,0.3,0.4,0.6,0.4,1s-0.1,0.7-0.4,1s-0.6,0.4-1,0.4s-0.8-0.1-1-0.4c-0.3-0.3-0.4-0.6-0.4-1
			s0.1-0.7,0.4-1C202.9,243.2,203.2,243,203.6,243z"
          />
        </g>
        <g>
          <path
            className="st0"
            d="M173.6,229.1h-2.5c0-1.3,0.4-2.3,1.3-3.1s2-1.2,3.4-1.2c0.9,0,1.7,0.2,2.3,0.5s1.2,0.8,1.5,1.5
			c0.4,0.6,0.6,1.3,0.6,2c0,0.4-0.1,0.8-0.2,1.1c-0.1,0.4-0.3,0.8-0.6,1.1c-0.2,0.2-0.5,0.6-1.1,1.1s-0.9,0.9-1.1,1.1
			c-0.1,0.2-0.3,0.5-0.4,0.8c-0.1,0.4-0.2,0.8-0.2,1.5H174v-0.4c0-0.9,0.2-1.7,0.5-2.4s0.8-1.4,1.5-2s1.1-1,1.2-1.2
			c0.1-0.2,0.2-0.5,0.2-0.8c0-0.4-0.2-0.8-0.5-1.1s-0.8-0.4-1.4-0.4c-0.6,0-1.1,0.2-1.5,0.5C173.8,228.1,173.6,228.6,173.6,229.1z
			 M175.5,236.7c0.5,0,0.9,0.2,1.2,0.5s0.5,0.7,0.5,1.2s-0.2,0.9-0.5,1.2s-0.7,0.5-1.2,0.5s-0.9-0.2-1.2-0.5s-0.5-0.7-0.5-1.2
			s0.2-0.9,0.5-1.2C174.6,236.8,175,236.7,175.5,236.7z"
          />
        </g>
      </g>
      <rect
        x="188"
        y="296.3"
        className="st0 st6"
        width="2"
        height="31.2"
        ref={secondLineRef}
      />
      <g ref={secondCircleRef} className="st6">
        <circle className="st1" cx="189" cy="357" r="29.6" />
        <path
          className="st0"
          d="M189,387.6c-16.9,0-30.6-13.7-30.6-30.6s13.7-30.6,30.6-30.6s30.6,13.7,30.6,30.6S205.9,387.6,189,387.6z
		 M189,328.4c-15.8,0-28.6,12.8-28.6,28.6s12.8,28.6,28.6,28.6s28.6-12.8,28.6-28.6S204.8,328.4,189,328.4z"
        />
      </g>
      <rect
        x="188"
        y="410.3"
        className="st0 st6"
        width="2"
        height="31.4"
        ref={thirdLineRef}
      />
      <g ref={thirdCircleRef} className="st6">
        <circle className="st1" cx="189" cy="471" r="29.6" />
        <path
          className="st0"
          d="M189,501.6c-16.9,0-30.6-13.7-30.6-30.6s13.7-30.6,30.6-30.6s30.6,13.7,30.6,30.6S205.9,501.6,189,501.6z
		 M189,442.3c-15.8,0-28.6,12.8-28.6,28.6s12.8,28.6,28.6,28.6s28.6-12.8,28.6-28.6S204.8,442.3,189,442.3z"
        />
      </g>
      <rect
        x="187.7"
        y="640"
        className="st0 st6"
        width="2"
        height="31.1"
        ref={fifthLineRef}
      />
      <g ref={fifthCircleRef} className="st6">
        <circle className="st1" cx="188.7" cy="700.7" r="29.6" />
        <path
          className="st0"
          d="M188.7,731.3c-16.9,0-30.6-13.7-30.6-30.6s13.7-30.6,30.6-30.6s30.6,13.7,30.6,30.6S205.6,731.3,188.7,731.3z
		 M188.7,672.1c-15.8,0-28.6,12.8-28.6,28.6s12.8,28.6,28.6,28.6s28.6-12.8,28.6-28.6S204.5,672.1,188.7,672.1z"
        />
      </g>
      <rect
        x="187.7"
        y="750.4"
        className="st0 st6"
        width="2"
        height="45.3"
        ref={sixthLineRef}
      />
      <g ref={sixthCircleRef} className="st6">
        <circle className="st1" cx="188.7" cy="825.2" r="29.6" />
        <path
          className="st0"
          d="M188.7,855.8c-16.9,0-30.6-13.7-30.6-30.6s13.7-30.6,30.6-30.6s30.6,13.7,30.6,30.6S205.6,855.8,188.7,855.8z
		 M188.7,796.6c-15.8,0-28.6,12.8-28.6,28.6s12.8,28.6,28.6,28.6s28.6-12.8,28.6-28.6S204.5,796.6,188.7,796.6z"
        />
      </g>
      <rect
        x="187.7"
        y="530"
        className="st0 st6"
        width="2"
        height="31.1"
        ref={fourthLineRef}
      />
      <g ref={fourthCircleRef} className="st6">
        <circle className="st1" cx="188.7" cy="590.7" r="29.6" />
        <path
          className="st0"
          d="M188.7,621.3c-16.9,0-30.6-13.7-30.6-30.6s13.7-30.6,30.6-30.6s30.6,13.7,30.6,30.6S205.6,621.3,188.7,621.3z
		 M188.7,562.1c-15.8,0-28.6,12.8-28.6,28.6s12.8,28.6,28.6,28.6s28.6-12.9,28.6-28.6S204.5,562.1,188.7,562.1z"
        />
      </g>
      <path
        className="st0 st6"
        ref={bulbRef}
        d="M194.2,370.6h-10.4c-0.4,0-0.7-0.3-0.7-0.7c0-2.2-0.4-4.1-1.1-5.9s-1.5-3.4-2.3-4.9c-1.4-2.6-2.6-4.9-2.6-7.3
	v-0.1c0-3.8,1.5-6.9,3.8-9c2.2-2.1,5.2-3.2,8.2-3.2h0.1l0,0c3.1,0,6.2,1.3,8.5,3.7c2,2.1,3.3,5,3.3,8.6c0,2.5-1.2,4.8-2.6,7.3
	c-1.6,3-3.4,6.4-3.4,10.8C194.9,370.3,194.6,370.6,194.2,370.6z M184.2,373.2c-0.4,0-0.7-0.3-0.7-0.7c0-0.4,0.3-0.7,0.7-0.7h9.5
	c0.4,0,0.7,0.3,0.7,0.7c0,0.4-0.3,0.7-0.7,0.7H184.2z M187.6,358.8h2.9v-1c0-0.4,0.3-0.7,0.7-0.7s0.7,0.3,0.7,0.7v11.4h1.6
	c0.2-4.4,2-7.7,3.6-10.8c1.3-2.4,2.4-4.5,2.4-6.6c0-3.2-1.2-5.8-2.9-7.6c-2-2.1-4.7-3.2-7.5-3.2H189c-2.6,0-5.2,1-7.2,2.8
	s-3.3,4.5-3.3,7.9v0.1c0,2.1,1.2,4.3,2.4,6.6c0.8,1.6,1.7,3.3,2.4,5.1c0.6,1.7,1.1,3.6,1.2,5.6h1.6v-11.4c0-0.4,0.3-0.7,0.7-0.7
	s0.7,0.3,0.7,0.7L187.6,358.8L187.6,358.8z M190.4,369.2v-8.9h-2.9v8.9H190.4z M185.1,375.8c-0.4,0-0.7-0.3-0.7-0.7s0.3-0.7,0.7-0.7
	h7.7c0.4,0,0.7,0.3,0.7,0.7s-0.3,0.7-0.7,0.7H185.1z"
      />
      <path
        className="st0 st6"
        ref={paletteRef}
        d="M190,454.5c-9.9,0-18,8.1-18,18s8.1,18,18,18c1.7,0,3-1.3,3-3c0-0.8-0.3-1.5-0.8-2s-0.8-1.2-0.8-2
	c0-1.7,1.3-3,3-3h3.5c5.5,0,10-4.5,10-10C208,461.7,199.9,454.5,190,454.5z M179,472.5c-1.7,0-3-1.3-3-3s1.3-3,3-3s3,1.3,3,3
	S180.7,472.5,179,472.5z M185,464.5c-1.7,0-3-1.3-3-3s1.3-3,3-3s3,1.3,3,3S186.6,464.5,185,464.5z M195,464.5c-1.7,0-3-1.3-3-3
	s1.3-3,3-3s3,1.3,3,3S196.6,464.5,195,464.5z M201,472.5c-1.7,0-3-1.3-3-3s1.3-3,3-3s3,1.3,3,3S202.6,472.5,201,472.5z"
      />
      <g
        id="layer1"
        className="st6"
        ref={screenRef}
        transform="translate(0,-11.249983)"
      >
        <g id="g1608" transform="translate(1073.6514,-6760.5757)">
          <path
            className="st2"
            d="M-866.4,7348.2h-36.5c-0.7,0-1.2,0.5-1.2,1.2l0,0v24.4c0,0.7,0.5,1.2,1.2,1.2l0,0h36.5c0.7,0,1.2-0.5,1.2-1.2
			v-24.4C-865.2,7348.7-865.7,7348.2-866.4,7348.2z M-867.6,7372.5h-34.1v-21.9h34.1V7372.5z"
          />
          <path
            id="path1541"
            className="st2"
            d="M-885.9,7373.8v6.1h2.4v-6.1H-885.9z"
          />
          <path
            id="path1543"
            className="st2"
            d="M-892,7378.6v2.4h1.2h12.2h1.2v-2.4h-1.2h-12.2H-892z"
          />
          <path
            id="path1559"
            className="st0"
            ref={screenLineRef}
            d="M-875.1,7354.6c-0.7,0-1.3,0.6-1.3,1.3c0,0.3,0.1,0.5,0.3,0.8l-4.9,6.9c-0.1,0-0.3-0.1-0.4-0.1
			c-0.2,0-0.4,0-0.6,0.1l-4.6-4.6c0.1-0.2,0.1-0.4,0.1-0.6c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3c0,0.3,0.1,0.5,0.3,0.8l-4.9,6.9
			c-0.2-0.1-0.3-0.1-0.5-0.1c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3c0-0.3-0.1-0.5-0.2-0.7l4.9-7
			c0.1,0,0.3,0.1,0.4,0.1c0.2,0,0.4,0,0.6-0.1l4.6,4.6c-0.1,0.2-0.1,0.4-0.1,0.6c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3
			c0-0.3-0.1-0.5-0.3-0.8l4.9-6.9c0.1,0,0.3,0.1,0.4,0.1c0.7,0,1.3-0.6,1.3-1.3S-874.4,7354.6-875.1,7354.6L-875.1,7354.6z"
          />
        </g>
      </g>
      <g ref={pointerRef} className="st6">
        <path
          colorRendering="auto"
          imageRendering="auto"
          shapeRendering="auto"
          className="st0"
          d="M182.6,693.5c-0.8,0-1.4,0.8-1.1,1.5
		l7.3,22.4c0.3,0.9,1.6,1.1,2.1,0.2l3.4-5.5l4.8,4.8c0.4,0.5,1.2,0.5,1.6,0l4.1-4.1c0.4-0.5,0.4-1.2,0-1.6l-4.8-4.8l5.5-3.3
		c0.8-0.5,0.7-1.8-0.2-2.1l-22.4-7.3C182.8,693.5,182.7,693.5,182.6,693.5L182.6,693.5z"
        />
        <path
          colorRendering="auto"
          imageRendering="auto"
          shapeRendering="auto"
          className="st2 st6"
          d="M183.9,685.9c-0.4,0-0.8,0.4-0.8,0.8
		v3.3c0,0.5,0.4,0.8,0.8,0.8c0.4,0,0.8-0.4,0.8-0.8v-3.3C184.8,686.3,184.4,685.9,183.9,685.9C184,685.9,183.9,685.9,183.9,685.9z"
        />
        <path
          colorRendering="auto"
          imageRendering="auto"
          shapeRendering="auto"
          className="st2 st6"
          d="M190.5,688.7c-0.2,0-0.4,0.1-0.6,0.3
		l-2.7,2.7c-0.3,0.3-0.3,0.8,0,1.2c0.3,0.3,0.8,0.3,1.2,0l0,0l2.7-2.7c0.3-0.3,0.3-0.8,0-1.2C190.9,688.8,190.7,688.7,190.5,688.7
		L190.5,688.7z"
        />
        <path
          colorRendering="auto"
          imageRendering="auto"
          shapeRendering="auto"
          className="st2 st6"
          d="M174.7,695.2c-0.5,0-0.8,0.3-0.8,0.8
		s0.3,0.8,0.8,0.8c0,0,0,0,0.1,0h4.2c0.5,0,0.8-0.3,0.8-0.8s-0.3-0.8-0.8-0.8c0,0,0,0-0.1,0H174.7z"
        />
        <path
          colorRendering="auto"
          imageRendering="auto"
          shapeRendering="auto"
          className="st2 st6"
          d="M177.4,688.7c-0.5,0-0.8,0.4-0.8,0.8
		c0,0.2,0.1,0.4,0.3,0.6l2.3,2.3c0.3,0.3,0.8,0.3,1.2,0c0.3-0.3,0.3-0.8,0-1.2l0,0l-2.3-2.3C177.8,688.8,177.6,688.7,177.4,688.7
		L177.4,688.7z"
        />
        <path
          colorRendering="auto"
          imageRendering="auto"
          shapeRendering="auto"
          className="st2 st6"
          d="M180.2,699c-0.2,0-0.4,0.1-0.6,0.3
		l-2.7,2.7c-0.3,0.3-0.3,0.8,0,1.2c0.3,0.3,0.8,0.3,1.2,0l0,0l2.7-2.7c0.3-0.3,0.3-0.8,0-1.2C180.6,699.1,180.4,699,180.2,699
		L180.2,699z"
        />
      </g>
      <g transform="matrix(.75 0 0 -.75 0 1052.4)" />
      <g ref={clientRef} className="st6">
        <circle className="st0" cx="189.2" cy="816" r="8.1" />
        <path
          className="st0"
          d="M204.6,834.4L204.6,834.4c-0.4-2.4-1.8-4.9-3.8-6.3c-2.2-1.5-5-1.8-7.8-1.8l0,0l-3,4.3l3,7.2l-3.2,4.6
		l-3.2-4.6l3-7.2l-3-4.2c-2.7,0.1-5.3,0.5-7.5,1.9c-2,1.3-3.6,3.7-4.1,6.1c-0.4,1.9-1.9,4.3,1.1,5.7c3,1.5,9.1,2.4,13.7,2.4
		c4.6,0,10.6-0.9,13.5-2.4C206.2,838.6,204.9,836.3,204.6,834.4z"
        />
      </g>
      <polygon
        className="st0 st6"
        points="124.3,47.1 124.3,121.8 189,159.1 253.7,121.8 253.7,47.1 189,9.8 "
        ref={hexagonRef}
      />
      <g ref={hexagonTextRef} className="st6">
        <g className="st3">
          <path
            className="st4"
            d="M145.7,71.4h1.1v7.8c0,1.2-0.1,2-0.3,2.5s-0.5,0.8-0.9,1.1c-0.4,0.3-1,0.4-1.6,0.4c-0.5,0-0.9-0.1-1.4-0.3
		c-0.5-0.2-0.9-0.5-1.5-0.9l0.6-0.9c0.6,0.4,1.1,0.7,1.5,0.9c0.3,0.1,0.5,0.2,0.8,0.2s0.6-0.1,0.9-0.3s0.5-0.5,0.6-0.8
		s0.2-1,0.2-1.9L145.7,71.4L145.7,71.4z"
          />
          <path
            className="st4"
            d="M158,74.4v8.5h-1.1v-1.5c-0.5,0.6-1,1-1.5,1.3s-1.2,0.4-1.9,0.4c-1.2,0-2.2-0.4-3.1-1.3s-1.3-1.9-1.3-3.2
		c0-1.2,0.4-2.3,1.3-3.1s1.9-1.3,3.1-1.3c0.7,0,1.3,0.1,1.9,0.4c0.6,0.3,1.1,0.7,1.5,1.3v-1.6L158,74.4L158,74.4z M153.6,75.2
		c-0.6,0-1.2,0.1-1.7,0.4s-0.9,0.7-1.2,1.3s-0.5,1.1-0.5,1.7s0.2,1.2,0.5,1.7s0.7,1,1.2,1.3s1.1,0.5,1.7,0.5c0.6,0,1.2-0.2,1.7-0.5
		s0.9-0.7,1.2-1.2s0.4-1.1,0.4-1.7c0-1-0.3-1.8-1-2.5C155.3,75.6,154.5,75.2,153.6,75.2z"
          />
          <path
            className="st4"
            d="M160.4,71.1h1.1v6.7l3.9-3.4h1.6l-4.7,4.1l4.9,4.4h-1.5l-4.3-3.8v3.8h-1.1V71.1H160.4z"
          />
          <path
            className="st4"
            d="M171.7,74.4h1.1l2.6,6.2l2.7-6.2h0.2l2.7,6.2l2.7-6.2h1.1l-3.7,8.5H181l-2.7-6.1l-2.7,6.1h-0.2L171.7,74.4z"
          />
          <path
            className="st4"
            d="M185.3,74.4h1.2l2.9,6.5l2.8-6.5h1.2L188.3,86h-1.2l1.6-3.8L185.3,74.4z"
          />
          <path
            className="st4"
            d="M202.1,74.4h1.1v6.8c0,1.2-0.1,2.1-0.3,2.6c-0.3,0.8-0.8,1.4-1.5,1.8s-1.6,0.6-2.6,0.6c-0.7,0-1.4-0.1-2-0.3
		s-1.1-0.5-1.4-0.8c-0.4-0.3-0.7-0.9-1-1.5h1.2c0.3,0.6,0.7,1,1.3,1.3c0.5,0.3,1.1,0.4,1.9,0.4c0.7,0,1.4-0.1,1.9-0.4s0.9-0.6,1.1-1
		s0.3-1.1,0.3-2v-0.4c-0.4,0.5-0.9,0.9-1.5,1.2c-0.6,0.3-1.2,0.4-1.9,0.4c-0.8,0-1.5-0.2-2.2-0.6s-1.2-0.9-1.6-1.6
		c-0.4-0.7-0.6-1.4-0.6-2.2s0.2-1.5,0.6-2.2c0.4-0.7,0.9-1.2,1.6-1.6c0.7-0.4,1.4-0.6,2.2-0.6c0.6,0,1.2,0.1,1.8,0.4
		c0.5,0.3,1.1,0.7,1.6,1.3L202.1,74.4L202.1,74.4z M198.9,75.2c-0.6,0-1.2,0.1-1.7,0.4s-0.9,0.7-1.3,1.2s-0.5,1.1-0.5,1.7
		c0,0.9,0.3,1.7,0.9,2.3s1.4,0.9,2.4,0.9s1.8-0.3,2.4-0.9s0.9-1.4,0.9-2.4c0-0.6-0.1-1.2-0.4-1.7s-0.7-0.9-1.2-1.2
		S199.4,75.2,198.9,75.2z"
          />
          <path className="st4" d="M205.4,71.1h1.1v11.8h-1.1V71.1z" />
          <path
            className="st4"
            d="M217.2,74.4v8.5h-1.1v-1.5c-0.5,0.6-1,1-1.5,1.3s-1.2,0.4-1.9,0.4c-1.2,0-2.2-0.4-3.1-1.3s-1.3-1.9-1.3-3.2
		c0-1.2,0.4-2.3,1.3-3.1s1.9-1.3,3.1-1.3c0.7,0,1.3,0.1,1.9,0.4s1.1,0.7,1.5,1.3v-1.6L217.2,74.4L217.2,74.4z M212.8,75.2
		c-0.6,0-1.2,0.1-1.7,0.4s-0.9,0.7-1.2,1.3c-0.3,0.5-0.5,1.1-0.5,1.7s0.2,1.2,0.5,1.7s0.7,1,1.2,1.3s1.1,0.5,1.7,0.5
		c0.6,0,1.2-0.2,1.7-0.5s0.9-0.7,1.2-1.2s0.4-1.1,0.4-1.7c0-1-0.3-1.8-1-2.5C214.6,75.6,213.8,75.2,212.8,75.2z M216.2,82.9h0.9
		c-0.6,0.5-0.9,1-0.9,1.5c0,0.2,0.1,0.4,0.3,0.6s0.5,0.3,0.8,0.3c0.2,0,0.3,0,0.5,0l-0.1,0.7c-0.2,0.1-0.4,0.1-0.7,0.1
		c-0.6,0-1-0.1-1.3-0.4s-0.5-0.6-0.5-1C215.3,83.9,215.6,83.3,216.2,82.9z"
          />
          <path
            className="st4"
            d="M228.1,71.1v11.8H227v-1.5c-0.5,0.6-1,1-1.5,1.3s-1.2,0.4-1.9,0.4c-1.2,0-2.2-0.4-3.1-1.3s-1.3-1.9-1.3-3.2
		c0-1.2,0.4-2.3,1.3-3.1s1.9-1.3,3.1-1.3c0.7,0,1.3,0.1,1.9,0.4s1.1,0.7,1.5,1.3v-4.8C227,71.1,228.1,71.1,228.1,71.1z M223.8,75.2
		c-0.6,0-1.2,0.1-1.7,0.4s-0.9,0.7-1.2,1.3c-0.3,0.5-0.5,1.1-0.5,1.7s0.2,1.2,0.5,1.7s0.7,1,1.2,1.3s1.1,0.5,1.7,0.5
		c0.6,0,1.2-0.2,1.7-0.5s0.9-0.7,1.2-1.2s0.4-1.1,0.4-1.7c0-1-0.3-1.8-1-2.5C225.5,75.6,224.7,75.2,223.8,75.2z"
          />
          <path
            className="st4"
            d="M239.1,74.4v8.5H238v-1.5c-0.5,0.6-1,1-1.5,1.3s-1.2,0.4-1.9,0.4c-1.2,0-2.2-0.4-3.1-1.3s-1.3-1.9-1.3-3.2
		c0-1.2,0.4-2.3,1.3-3.1s1.9-1.3,3.1-1.3c0.7,0,1.3,0.1,1.9,0.4s1.1,0.7,1.5,1.3v-1.6L239.1,74.4L239.1,74.4z M234.7,75.2
		c-0.6,0-1.2,0.1-1.7,0.4s-0.9,0.7-1.2,1.3c-0.3,0.5-0.5,1.1-0.5,1.7s0.2,1.2,0.5,1.7s0.7,1,1.2,1.3s1.1,0.5,1.7,0.5
		c0.6,0,1.2-0.2,1.7-0.5s0.9-0.7,1.2-1.2s0.4-1.1,0.4-1.7c0-1-0.3-1.8-1-2.5C236.5,75.6,235.7,75.2,234.7,75.2z"
          />
        </g>
        <g className="st3">
          <path
            className="st4"
            d="M140.3,93.6h1.1l2.6,6.2l2.7-6.2h0.2l2.7,6.2l2.7-6.2h1.1l-3.7,8.5h-0.2l-2.7-6.1l-2.7,6.1h-0.2L140.3,93.6z"
          />
          <path
            className="st4"
            d="M159,94.5l-0.7,0.7c-0.6-0.6-1.2-0.9-1.7-0.9c-0.4,0-0.7,0.1-0.9,0.4c-0.3,0.2-0.4,0.5-0.4,0.8
		s0.1,0.5,0.3,0.8s0.6,0.6,1.3,0.9c0.8,0.4,1.4,0.8,1.7,1.2c0.3,0.4,0.4,0.8,0.4,1.3c0,0.7-0.2,1.3-0.7,1.8s-1.1,0.7-1.8,0.7
		c-0.5,0-1-0.1-1.4-0.3c-0.4-0.2-0.8-0.5-1.1-0.9l0.7-0.8c0.6,0.6,1.2,0.9,1.8,0.9c0.4,0,0.8-0.1,1.1-0.4s0.5-0.6,0.5-1
		c0-0.3-0.1-0.6-0.3-0.8c-0.2-0.2-0.7-0.5-1.4-0.9c-0.8-0.4-1.3-0.8-1.6-1.2c-0.3-0.4-0.4-0.8-0.4-1.3c0-0.6,0.2-1.2,0.7-1.6
		s1-0.6,1.7-0.6C157.4,93.4,158.2,93.8,159,94.5z"
          />
          <path
            className="st4"
            d="M161,93.6h1.1v1.6c0.4-0.6,0.9-1,1.5-1.3s1.2-0.4,1.9-0.4c1.2,0,2.2,0.4,3.1,1.3c0.9,0.9,1.3,1.9,1.3,3.1
		c0,1.3-0.4,2.3-1.3,3.2c-0.9,0.9-1.9,1.3-3.1,1.3c-0.7,0-1.3-0.1-1.9-0.4c-0.6-0.3-1.1-0.7-1.5-1.3v4.6H161V93.6z M165.3,94.4
		c-0.9,0-1.7,0.3-2.4,1s-1,1.5-1,2.5c0,0.6,0.1,1.2,0.4,1.7c0.3,0.5,0.7,0.9,1.2,1.2c0.5,0.3,1.1,0.5,1.7,0.5c0.6,0,1.1-0.2,1.7-0.5
		c0.5-0.3,0.9-0.7,1.2-1.3s0.5-1.1,0.5-1.7s-0.2-1.2-0.5-1.7s-0.7-1-1.2-1.3C166.5,94.6,165.9,94.4,165.3,94.4z"
          />
          <path
            className="st4"
            d="M175.9,93.4c1.3,0,2.4,0.5,3.3,1.4c0.8,0.9,1.2,1.9,1.2,3.1s-0.4,2.2-1.2,3.1s-1.9,1.3-3.2,1.3
		s-2.4-0.4-3.2-1.3s-1.2-1.9-1.2-3.1s0.4-2.2,1.2-3.1C173.5,93.8,174.6,93.4,175.9,93.4z M175.9,94.4c-0.9,0-1.7,0.3-2.3,1
		s-1,1.5-1,2.4c0,0.6,0.1,1.2,0.4,1.7s0.7,0.9,1.2,1.2s1.1,0.4,1.7,0.4s1.2-0.1,1.7-0.4s0.9-0.7,1.2-1.2s0.4-1.1,0.4-1.7
		c0-1-0.3-1.8-1-2.4C177.6,94.8,176.8,94.4,175.9,94.4z M176.4,89.6h1.7l-2.3,2.4h-1.1L176.4,89.6z"
          />
          <path
            className="st4"
            d="M184.1,94.5l1.6-1v1.1l-1.6,1v6.5H183v-5.8l-1.4,0.9v-1.1l1.4-0.9v-4.9h1.1V94.5z"
          />
          <path
            className="st4"
            d="M187.1,93.6h1.1v1.6c0.4-0.6,0.9-1,1.5-1.3s1.2-0.4,1.9-0.4c1.2,0,2.2,0.4,3.1,1.3c0.9,0.9,1.3,1.9,1.3,3.1
		c0,1.3-0.4,2.3-1.3,3.2c-0.9,0.9-1.9,1.3-3.1,1.3c-0.7,0-1.3-0.1-1.9-0.4c-0.6-0.3-1.1-0.7-1.5-1.3v4.6h-1.1V93.6z M191.5,94.4
		c-0.9,0-1.7,0.3-2.4,1c-0.6,0.7-1,1.5-1,2.5c0,0.6,0.1,1.2,0.4,1.7s0.7,0.9,1.2,1.2c0.5,0.3,1.1,0.5,1.7,0.5c0.6,0,1.1-0.2,1.7-0.5
		c0.5-0.3,0.9-0.7,1.2-1.3s0.5-1.1,0.5-1.7s-0.2-1.2-0.5-1.7s-0.7-1-1.2-1.3C192.7,94.6,192.1,94.4,191.5,94.4z"
          />
          <path
            className="st4"
            d="M197.6,93.6h1.1v1.2c0.3-0.5,0.7-0.9,1.1-1.1s0.8-0.4,1.2-0.4c0.3,0,0.6,0.1,1,0.3l-0.6,0.9
		c-0.2-0.1-0.4-0.1-0.6-0.1c-0.4,0-0.7,0.1-1.1,0.4s-0.6,0.8-0.8,1.4c-0.1,0.5-0.2,1.5-0.2,2.9v2.9h-1.1L197.6,93.6L197.6,93.6z"
          />
          <path
            className="st4"
            d="M211.3,93.6v8.5h-1.1v-1.5c-0.5,0.6-1,1-1.5,1.3s-1.2,0.4-1.9,0.4c-1.2,0-2.2-0.4-3.1-1.3s-1.3-1.9-1.3-3.2
		c0-1.2,0.4-2.3,1.3-3.1s1.9-1.3,3.1-1.3c0.7,0,1.3,0.1,1.9,0.4s1.1,0.7,1.5,1.3v-1.6L211.3,93.6L211.3,93.6z M207,94.4
		c-0.6,0-1.2,0.1-1.7,0.4s-0.9,0.7-1.2,1.3c-0.3,0.5-0.5,1.1-0.5,1.7s0.2,1.2,0.5,1.7s0.7,1,1.2,1.3s1.1,0.5,1.7,0.5
		c0.6,0,1.2-0.2,1.7-0.5s0.9-0.7,1.2-1.2s0.4-1.1,0.4-1.7c0-1-0.3-1.8-1-2.5C208.7,94.8,207.9,94.4,207,94.4z"
          />
          <path
            className="st4"
            d="M222,95.4l-0.9,0.5c-0.7-1-1.8-1.5-3.1-1.5c-1,0-1.9,0.3-2.6,1c-0.7,0.7-1,1.5-1,2.4c0,0.6,0.2,1.2,0.5,1.7
		s0.7,1,1.3,1.3c0.5,0.3,1.2,0.5,1.8,0.5c1.2,0,2.3-0.5,3.1-1.5l0.9,0.6c-0.4,0.6-1,1.1-1.6,1.4c-0.7,0.3-1.5,0.5-2.3,0.5
		c-1.3,0-2.5-0.4-3.3-1.3s-1.3-1.9-1.3-3.1c0-0.8,0.2-1.6,0.6-2.3s1-1.3,1.7-1.7s1.5-0.6,2.4-0.6c0.6,0,1.1,0.1,1.6,0.3
		s1,0.4,1.3,0.7S221.8,94.9,222,95.4z"
          />
          <path
            className="st4"
            d="M232.6,93.6v8.5h-1.1v-1.5c-0.5,0.6-1,1-1.5,1.3s-1.2,0.4-1.9,0.4c-1.2,0-2.2-0.4-3.1-1.3s-1.3-1.9-1.3-3.2
		c0-1.2,0.4-2.3,1.3-3.1s1.9-1.3,3.1-1.3c0.7,0,1.3,0.1,1.9,0.4s1.1,0.7,1.5,1.3v-1.6L232.6,93.6L232.6,93.6z M228.2,94.4
		c-0.6,0-1.2,0.1-1.7,0.4s-0.9,0.7-1.2,1.3c-0.3,0.5-0.5,1.1-0.5,1.7s0.2,1.2,0.5,1.7s0.7,1,1.2,1.3s1.1,0.5,1.7,0.5
		c0.6,0,1.2-0.2,1.7-0.5s0.9-0.7,1.2-1.2s0.4-1.1,0.4-1.7c0-1-0.3-1.8-1-2.5C230,94.8,229.2,94.4,228.2,94.4z"
          />
          <path
            className="st4"
            d="M236.3,93.7h-1.1c0-1.1,0.3-1.9,0.9-2.5s1.4-0.9,2.5-0.9c1,0,1.8,0.3,2.4,0.8s0.9,1.2,0.9,1.9
		c0,0.4-0.1,0.7-0.2,1s-0.4,0.6-0.7,0.8s-0.9,0.5-1.7,0.8c-1,0.4-1.6,0.7-1.8,0.9c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.3,0.1,0.6,0.4,0.8
		s0.7,0.4,1.1,0.4c0.5,0,0.9-0.1,1.2-0.4s0.4-0.7,0.5-1.3h1.1c0,0.9-0.3,1.5-0.8,2s-1.2,0.7-2,0.7s-1.5-0.2-2-0.7s-0.8-1-0.8-1.6
		c0-0.3,0.1-0.6,0.2-0.9s0.3-0.5,0.6-0.7s0.7-0.4,1.4-0.7c1.1-0.5,1.8-0.8,2.2-1c0.3-0.2,0.5-0.6,0.5-1c0-0.5-0.2-0.8-0.6-1.1
		s-0.9-0.5-1.5-0.5c-0.8,0-1.4,0.3-1.8,0.8C236.5,92.5,236.3,93,236.3,93.7z M238.6,100.4c0.3,0,0.5,0.1,0.7,0.3s0.3,0.4,0.3,0.7
		c0,0.3-0.1,0.5-0.3,0.7s-0.4,0.3-0.7,0.3s-0.5-0.1-0.7-0.3s-0.3-0.4-0.3-0.7c0-0.3,0.1-0.5,0.3-0.7S238.3,100.4,238.6,100.4z"
          />
        </g>
      </g>
      <g ref={firstTextRef} className="st6">
        <g className="st3">
          <path
            className="st0"
            d="M55.1,276.4h1.1v10.4h4.4v1.1H55L55.1,276.4L55.1,276.4z"
          />
          <path
            className="st0"
            d="M62.6,275.9c0.2,0,0.5,0.1,0.6,0.3s0.3,0.4,0.3,0.6s-0.1,0.5-0.3,0.6s-0.4,0.3-0.6,0.3c-0.2,0-0.5-0.1-0.6-0.3
		c-0.2-0.2-0.3-0.4-0.3-0.6c0-0.3,0.1-0.5,0.3-0.6C62.2,276,62.4,275.9,62.6,275.9z M62.1,279.4h1.1v8.5h-1.1V279.4z"
          />
          <path
            className="st0"
            d="M69.7,280.3L69,281c-0.6-0.6-1.2-0.9-1.7-0.9c-0.4,0-0.7,0.1-0.9,0.4c-0.3,0.2-0.4,0.5-0.4,0.8
		s0.1,0.5,0.3,0.8c0.2,0.3,0.6,0.6,1.3,0.9c0.8,0.4,1.4,0.8,1.7,1.2c0.3,0.4,0.4,0.8,0.4,1.3c0,0.7-0.2,1.3-0.7,1.8
		s-1.1,0.7-1.8,0.7c-0.5,0-1-0.1-1.4-0.3c-0.4-0.2-0.8-0.5-1.1-0.9l0.7-0.8c0.6,0.6,1.2,0.9,1.8,0.9c0.4,0,0.8-0.1,1.1-0.4
		c0.3-0.3,0.5-0.6,0.5-1c0-0.3-0.1-0.6-0.3-0.8c-0.2-0.2-0.7-0.5-1.4-0.9c-0.8-0.4-1.3-0.8-1.6-1.2c-0.3-0.4-0.4-0.8-0.4-1.3
		c0-0.6,0.2-1.2,0.7-1.6s1-0.6,1.7-0.6C68.2,279.2,68.9,279.5,69.7,280.3z"
          />
          <path
            className="st0"
            d="M72.6,276.2h1.1v3.2h1.7v0.9h-1.7v7.6h-1.1v-7.6h-1.5v-0.9h1.5V276.2z"
          />
          <path
            className="st0"
            d="M85.6,279.4v8.5h-1.1v-1.5c-0.5,0.6-1,1-1.5,1.3s-1.2,0.4-1.9,0.4c-1.2,0-2.2-0.4-3.1-1.3s-1.3-1.9-1.3-3.2
		c0-1.2,0.4-2.3,1.3-3.1s1.9-1.3,3.1-1.3c0.7,0,1.3,0.1,1.9,0.4s1.1,0.7,1.5,1.3v-1.6h1.1V279.4z M81.2,280.2
		c-0.6,0-1.2,0.1-1.7,0.4s-0.9,0.7-1.2,1.3s-0.5,1.1-0.5,1.7s0.2,1.2,0.5,1.7s0.7,1,1.2,1.3s1.1,0.5,1.7,0.5s1.2-0.2,1.7-0.5
		s0.9-0.7,1.2-1.2s0.4-1.1,0.4-1.7c0-1-0.3-1.8-1-2.5C82.9,280.5,82.1,280.2,81.2,280.2z"
          />
          <path
            className="st0"
            d="M92.5,279.4h1.1v1.6c0.4-0.6,0.9-1,1.5-1.3s1.2-0.4,1.9-0.4c1.2,0,2.2,0.4,3.1,1.3s1.3,1.9,1.3,3.1
		c0,1.3-0.4,2.3-1.3,3.2c-0.9,0.9-1.9,1.3-3.1,1.3c-0.7,0-1.3-0.1-1.9-0.4s-1.1-0.7-1.5-1.3v4.6h-1.1V279.4z M96.9,280.2
		c-0.9,0-1.7,0.3-2.4,1s-1,1.5-1,2.5c0,0.6,0.1,1.2,0.4,1.7s0.7,0.9,1.2,1.2c0.5,0.3,1.1,0.5,1.7,0.5s1.1-0.2,1.7-0.5
		c0.5-0.3,0.9-0.7,1.2-1.3s0.5-1.1,0.5-1.7s-0.2-1.2-0.5-1.7s-0.7-1-1.2-1.3C98,280.4,97.5,280.2,96.9,280.2z"
          />
          <path
            className="st0"
            d="M102.5,279.4h1.2l2.9,6.5l2.8-6.5h1.2l-5.1,11.6h-1.2l1.6-3.8L102.5,279.4z"
          />
          <path
            className="st0"
            d="M112.9,276.2h1.1v3.2h1.7v0.9H114v7.6h-1.1v-7.6h-1.5v-0.9h1.5V276.2z"
          />
          <path
            className="st0"
            d="M125.8,279.4v8.5h-1.1v-1.5c-0.5,0.6-1,1-1.5,1.3s-1.2,0.4-1.9,0.4c-1.2,0-2.2-0.4-3.1-1.3s-1.3-1.9-1.3-3.2
		c0-1.2,0.4-2.3,1.3-3.1s1.9-1.3,3.1-1.3c0.7,0,1.3,0.1,1.9,0.4s1.1,0.7,1.5,1.3v-1.6h1.1V279.4z M121.5,280.2
		c-0.6,0-1.2,0.1-1.7,0.4s-0.9,0.7-1.2,1.3s-0.5,1.1-0.5,1.7s0.2,1.2,0.5,1.7s0.7,1,1.2,1.3s1.1,0.5,1.7,0.5s1.2-0.2,1.7-0.5
		s0.9-0.7,1.2-1.2s0.4-1.1,0.4-1.7c0-1-0.3-1.8-1-2.5C123.2,280.5,122.4,280.2,121.5,280.2z"
          />
          <path
            className="st0"
            d="M128.3,279.4h1.1v1.5c0.4-0.6,0.9-1,1.5-1.3s1.1-0.4,1.7-0.4c0.6,0,1.2,0.2,1.7,0.5s0.9,0.8,1.1,1.3
		s0.4,1.4,0.4,2.6v4.4h-1.1v-4.1c0-1,0-1.6-0.1-2c-0.1-0.6-0.4-1-0.7-1.3s-0.8-0.4-1.4-0.4c-0.7,0-1.3,0.2-1.8,0.7s-0.9,1-1,1.6
		c-0.1,0.4-0.2,1.2-0.2,2.3v3.1h-1.1L128.3,279.4L128.3,279.4z M132.3,275.4h1.7l-2.3,2.4h-1.1L132.3,275.4z"
          />
          <path
            className="st0"
            d="M151,276.1v11.8h-1.1v-1.5c-0.5,0.6-1,1-1.5,1.3s-1.2,0.4-1.9,0.4c-1.2,0-2.2-0.4-3.1-1.3s-1.3-1.9-1.3-3.2
		c0-1.2,0.4-2.3,1.3-3.1s1.9-1.3,3.1-1.3c0.7,0,1.3,0.1,1.9,0.4c0.6,0.3,1.1,0.7,1.5,1.3v-4.8L151,276.1L151,276.1z M146.6,280.2
		c-0.6,0-1.2,0.1-1.7,0.4s-0.9,0.7-1.2,1.3s-0.5,1.1-0.5,1.7s0.2,1.2,0.5,1.7s0.7,1,1.2,1.3s1.1,0.5,1.7,0.5c0.6,0,1.2-0.2,1.7-0.5
		s0.9-0.7,1.2-1.2s0.4-1.1,0.4-1.7c0-1-0.3-1.8-1-2.5C148.4,280.5,147.6,280.2,146.6,280.2z"
          />
          <path
            className="st0"
            d="M157.5,279.2c1.3,0,2.4,0.5,3.3,1.4c0.8,0.9,1.2,1.9,1.2,3.1s-0.4,2.2-1.2,3.1s-1.9,1.3-3.2,1.3
		s-2.4-0.4-3.2-1.3s-1.2-1.9-1.2-3.1s0.4-2.2,1.2-3.1C155.1,279.6,156.2,279.2,157.5,279.2z M157.5,280.2c-0.9,0-1.7,0.3-2.3,1
		s-1,1.5-1,2.4c0,0.6,0.1,1.2,0.4,1.7s0.7,0.9,1.2,1.2s1.1,0.4,1.7,0.4s1.2-0.1,1.7-0.4s0.9-0.7,1.2-1.2s0.4-1.1,0.4-1.7
		c0-1-0.3-1.8-1-2.4C159.2,280.6,158.4,280.2,157.5,280.2z"
          />
          <path
            className="st0"
            d="M164.9,276.2h1.1v3.2h1.7v0.9H166v7.6h-1.1v-7.6h-1.5v-0.9h1.5V276.2z"
          />
          <path
            className="st0"
            d="M168.4,279.4h1.2l2.9,6.5l2.8-6.5h1.2l-5.1,11.6h-1.2l1.6-3.8L168.4,279.4z"
          />
          <path
            className="st0"
            d="M186.2,281.1l-0.9,0.5c-0.7-1-1.8-1.5-3.1-1.5c-1,0-1.9,0.3-2.6,1c-0.7,0.7-1,1.5-1,2.4c0,0.6,0.2,1.2,0.5,1.7
		s0.7,1,1.3,1.3c0.5,0.3,1.2,0.5,1.8,0.5c1.2,0,2.3-0.5,3.1-1.5l0.9,0.6c-0.4,0.6-1,1.1-1.6,1.4c-0.7,0.3-1.5,0.5-2.3,0.5
		c-1.3,0-2.5-0.4-3.3-1.3c-0.9-0.9-1.3-1.9-1.3-3.1c0-0.8,0.2-1.6,0.6-2.3s1-1.3,1.7-1.7c0.7-0.4,1.5-0.6,2.4-0.6
		c0.6,0,1.1,0.1,1.6,0.3s1,0.4,1.3,0.7C185.6,280.4,185.9,280.7,186.2,281.1z"
          />
          <path
            className="st0"
            d="M187.6,279.4h6.2l-4.8,7.5h4.7v1h-6.5l4.8-7.5h-4.4V279.4z"
          />
          <path
            className="st0"
            d="M203.5,279.4v8.5h-1.1v-1.5c-0.5,0.6-1,1-1.5,1.3s-1.2,0.4-1.9,0.4c-1.2,0-2.2-0.4-3.1-1.3s-1.3-1.9-1.3-3.2
		c0-1.2,0.4-2.3,1.3-3.1s1.9-1.3,3.1-1.3c0.7,0,1.3,0.1,1.9,0.4c0.6,0.3,1.1,0.7,1.5,1.3v-1.6h1.1V279.4z M199.2,280.2
		c-0.6,0-1.2,0.1-1.7,0.4s-0.9,0.7-1.2,1.3c-0.3,0.5-0.5,1.1-0.5,1.7s0.2,1.2,0.5,1.7s0.7,1,1.2,1.3s1.1,0.5,1.7,0.5
		c0.6,0,1.2-0.2,1.7-0.5s0.9-0.7,1.2-1.2s0.4-1.1,0.4-1.7c0-1-0.3-1.8-1-2.5S200.1,280.2,199.2,280.2z M202.6,287.9h0.9
		c-0.6,0.5-0.9,1-0.9,1.5c0,0.2,0.1,0.4,0.3,0.6s0.5,0.3,0.8,0.3c0.2,0,0.3,0,0.5,0l-0.1,0.7c-0.2,0.1-0.4,0.1-0.7,0.1
		c-0.6,0-1-0.1-1.3-0.4s-0.5-0.6-0.5-1C201.6,288.9,202,288.3,202.6,287.9z"
          />
          <path
            className="st0"
            d="M214.3,281.1l-0.9,0.5c-0.7-1-1.8-1.5-3.1-1.5c-1,0-1.9,0.3-2.6,1c-0.7,0.7-1,1.5-1,2.4c0,0.6,0.2,1.2,0.5,1.7
		s0.7,1,1.3,1.3c0.5,0.3,1.2,0.5,1.8,0.5c1.2,0,2.3-0.5,3.1-1.5l0.9,0.6c-0.4,0.6-1,1.1-1.6,1.4c-0.7,0.3-1.5,0.5-2.3,0.5
		c-1.3,0-2.5-0.4-3.3-1.3c-0.9-0.9-1.3-1.9-1.3-3.1c0-0.8,0.2-1.6,0.6-2.3s1-1.3,1.7-1.7c0.7-0.4,1.5-0.6,2.4-0.6
		c0.6,0,1.1,0.1,1.6,0.3s1,0.4,1.3,0.7C213.7,280.4,214,280.7,214.3,281.1z"
          />
          <path
            className="st0"
            d="M224.8,279.4v8.5h-1.1v-1.5c-0.5,0.6-1,1-1.5,1.3s-1.2,0.4-1.9,0.4c-1.2,0-2.2-0.4-3.1-1.3s-1.3-1.9-1.3-3.2
		c0-1.2,0.4-2.3,1.3-3.1s1.9-1.3,3.1-1.3c0.7,0,1.3,0.1,1.9,0.4c0.6,0.3,1.1,0.7,1.5,1.3v-1.6h1.1V279.4z M220.5,280.2
		c-0.6,0-1.2,0.1-1.7,0.4s-0.9,0.7-1.2,1.3c-0.3,0.5-0.5,1.1-0.5,1.7s0.2,1.2,0.5,1.7s0.7,1,1.2,1.3s1.1,0.5,1.7,0.5
		c0.6,0,1.2-0.2,1.7-0.5s0.9-0.7,1.2-1.2s0.4-1.1,0.4-1.7c0-1-0.3-1.8-1-2.5S221.4,280.2,220.5,280.2z"
          />
          <path
            className="st0"
            d="M231,279.4h6.2l-4.8,7.5h4.7v1h-6.5l4.8-7.5H231V279.4z"
          />
          <path
            className="st0"
            d="M247,279.4v8.5h-1.1v-1.5c-0.5,0.6-1,1-1.5,1.3s-1.2,0.4-1.9,0.4c-1.2,0-2.2-0.4-3.1-1.3s-1.3-1.9-1.3-3.2
		c0-1.2,0.4-2.3,1.3-3.1s1.9-1.3,3.1-1.3c0.7,0,1.3,0.1,1.9,0.4s1.1,0.7,1.5,1.3v-1.6h1.1V279.4z M242.6,280.2
		c-0.6,0-1.2,0.1-1.7,0.4s-0.9,0.7-1.2,1.3c-0.3,0.5-0.5,1.1-0.5,1.7s0.2,1.2,0.5,1.7s0.7,1,1.2,1.3s1.1,0.5,1.7,0.5
		c0.6,0,1.2-0.2,1.7-0.5s0.9-0.7,1.2-1.2s0.4-1.1,0.4-1.7c0-1-0.3-1.8-1-2.5S243.6,280.2,242.6,280.2z"
          />
          <path
            className="st0"
            d="M249.4,279.4h1.1v1.5c0.4-0.5,0.8-0.9,1.2-1.2c0.6-0.3,1.1-0.5,1.7-0.5c0.4,0,0.8,0.1,1.2,0.2s0.7,0.4,0.9,0.6
		s0.4,0.7,0.6,1.2c0.4-0.7,0.8-1.2,1.4-1.5s1.1-0.5,1.8-0.5c0.6,0,1.1,0.1,1.6,0.4s0.8,0.7,1,1.2s0.3,1.3,0.3,2.4v4.6H261v-4.6
		c0-0.9-0.1-1.5-0.2-1.9s-0.4-0.6-0.7-0.8c-0.3-0.2-0.7-0.3-1.1-0.3c-0.5,0-1,0.2-1.5,0.5c-0.4,0.3-0.8,0.7-1,1.3s-0.3,1.4-0.3,2.6
		v3.3h-1.1v-4.4c0-1-0.1-1.7-0.2-2.1s-0.4-0.7-0.7-0.9s-0.7-0.3-1.1-0.3c-0.5,0-1,0.2-1.4,0.5s-0.8,0.7-1,1.2s-0.3,1.3-0.3,2.4v3.6
		h-1.1v-8.5H249.4z"
          />
          <path
            className="st0"
            d="M268.5,279.2c1.3,0,2.4,0.5,3.3,1.4c0.8,0.9,1.2,1.9,1.2,3.1s-0.4,2.2-1.2,3.1c-0.8,0.9-1.9,1.3-3.2,1.3
		c-1.3,0-2.4-0.4-3.2-1.3s-1.2-1.9-1.2-3.1s0.4-2.2,1.2-3.1C266.1,279.6,267.2,279.2,268.5,279.2z M268.5,280.2
		c-0.9,0-1.7,0.3-2.3,1c-0.7,0.7-1,1.5-1,2.4c0,0.6,0.1,1.2,0.4,1.7s0.7,0.9,1.2,1.2s1.1,0.4,1.7,0.4s1.2-0.1,1.7-0.4
		s0.9-0.7,1.2-1.2s0.4-1.1,0.4-1.7c0-1-0.3-1.8-1-2.4C270.2,280.6,269.4,280.2,268.5,280.2z M269,275.4h1.7l-2.3,2.4h-1.1L269,275.4
		z"
          />
          <path
            className="st0"
            d="M273.8,279.4h1.1l2.6,6.2l2.7-6.2h0.2l2.7,6.2l2.7-6.2h1.1l-3.7,8.5H283l-2.7-6.1l-2.7,6.1h-0.2L273.8,279.4z"
          />
          <path
            className="st0"
            d="M288.6,275.9c0.2,0,0.5,0.1,0.6,0.3c0.2,0.2,0.3,0.4,0.3,0.6s-0.1,0.5-0.3,0.6c-0.2,0.2-0.4,0.3-0.6,0.3
		s-0.5-0.1-0.6-0.3c-0.2-0.2-0.3-0.4-0.3-0.6c0-0.3,0.1-0.5,0.3-0.6C288.2,276,288.4,275.9,288.6,275.9z M288.1,279.4h1.1v8.5h-1.1
		V279.4z"
          />
          <path
            className="st0"
            d="M298.6,285.1l0.9,0.5c-0.3,0.6-0.7,1.1-1,1.4s-0.8,0.6-1.3,0.8c-0.5,0.2-1.1,0.3-1.7,0.3
		c-1.4,0-2.5-0.5-3.3-1.4s-1.2-1.9-1.2-3.1c0-1.1,0.3-2,1-2.9c0.8-1.1,2-1.6,3.4-1.6s2.6,0.5,3.5,1.6c0.6,0.8,0.9,1.7,0.9,2.9h-7.6
		c0,1,0.3,1.8,0.9,2.4s1.4,0.9,2.3,0.9c0.4,0,0.9-0.1,1.3-0.2c0.4-0.2,0.8-0.4,1-0.6C297.9,286,298.3,285.6,298.6,285.1z
		 M298.6,282.8c-0.1-0.6-0.4-1-0.6-1.4s-0.6-0.6-1.1-0.8s-0.9-0.3-1.4-0.3c-0.8,0-1.6,0.3-2.2,0.8c-0.4,0.4-0.8,1-1,1.8h6.3V282.8z"
          />
          <path
            className="st0"
            d="M301.8,279.4h1.1v1.5c0.4-0.6,0.9-1,1.5-1.3s1.1-0.4,1.7-0.4s1.2,0.2,1.7,0.5s0.9,0.8,1.1,1.3s0.4,1.4,0.4,2.6
		v4.4h-1.1v-4.1c0-1,0-1.6-0.1-2c-0.1-0.6-0.4-1-0.7-1.3s-0.8-0.4-1.4-0.4c-0.7,0-1.3,0.2-1.8,0.7s-0.9,1-1,1.6
		c-0.1,0.4-0.2,1.2-0.2,2.3v3.1h-1.1v-8.5H301.8z"
          />
          <path
            className="st0"
            d="M312,275.9c0.2,0,0.5,0.1,0.6,0.3c0.2,0.2,0.3,0.4,0.3,0.6s-0.1,0.5-0.3,0.6c-0.2,0.2-0.4,0.3-0.6,0.3
		s-0.5-0.1-0.6-0.3c-0.2-0.2-0.3-0.4-0.3-0.6c0-0.3,0.1-0.5,0.3-0.6C311.5,276,311.7,275.9,312,275.9z M311.4,279.4h1.1v8.5h-1.1
		V279.4z"
          />
          <path
            className="st0"
            d="M323.3,279.4v8.5h-1.1v-1.5c-0.5,0.6-1,1-1.5,1.3s-1.2,0.4-1.9,0.4c-1.2,0-2.2-0.4-3.1-1.3s-1.3-1.9-1.3-3.2
		c0-1.2,0.4-2.3,1.3-3.1s1.9-1.3,3.1-1.3c0.7,0,1.3,0.1,1.9,0.4s1.1,0.7,1.5,1.3v-1.6h1.1V279.4z M318.9,280.2
		c-0.6,0-1.2,0.1-1.7,0.4s-0.9,0.7-1.2,1.3c-0.3,0.5-0.5,1.1-0.5,1.7s0.2,1.2,0.5,1.7s0.7,1,1.2,1.3s1.1,0.5,1.7,0.5
		s1.2-0.2,1.7-0.5s0.9-0.7,1.2-1.2c0.3-0.5,0.4-1.1,0.4-1.7c0-1-0.3-1.8-1-2.5S319.8,280.2,318.9,280.2z"
          />
        </g>
      </g>
      <g className="st3 st6" ref={secondTextRef}>
        <path
          className="st0"
          d="M108.6,391.7h1.2l2.3,8.3l3.3-8.3h0.2l3.3,8.3l2.4-8.3h1.2l-3.3,11.5H119l-3.4-8.7l-3.5,8.7h-0.2L108.6,391.7z
		"
        />
        <path
          className="st0"
          d="M124.9,391.2c0.2,0,0.5,0.1,0.6,0.3s0.3,0.4,0.3,0.6s-0.1,0.5-0.3,0.6s-0.4,0.3-0.6,0.3s-0.5-0.1-0.6-0.3
		c-0.2-0.2-0.3-0.4-0.3-0.6c0-0.3,0.1-0.5,0.3-0.6C124.4,391.3,124.6,391.2,124.9,391.2z M124.3,394.7h1.1v8.5h-1.1V394.7z"
        />
        <path
          className="st0"
          d="M127,394.7h6.2l-4.8,7.5h4.7v1h-6.5l4.8-7.5H127V394.7z"
        />
        <path
          className="st0"
          d="M134.3,394.7h1.1v9.8c0,0.7-0.1,1.2-0.4,1.6s-0.6,0.5-1.1,0.5c-0.4,0-0.8-0.1-1.3-0.3v-1
		c0.3,0.2,0.6,0.2,0.8,0.2c0.3,0,0.5-0.1,0.7-0.4c0.1-0.2,0.1-0.5,0.1-1L134.3,394.7L134.3,394.7z M134.9,391.2
		c0.3,0,0.5,0.1,0.6,0.3c0.2,0.2,0.3,0.4,0.3,0.6s-0.1,0.5-0.3,0.6c-0.2,0.2-0.4,0.3-0.6,0.3s-0.5-0.1-0.6-0.3
		c-0.2-0.2-0.3-0.4-0.3-0.6c0-0.3,0.1-0.5,0.3-0.6C134.4,391.3,134.6,391.2,134.9,391.2z"
        />
        <path
          className="st0"
          d="M146.2,394.7v8.5h-1.1v-1.5c-0.5,0.6-1,1-1.5,1.3s-1.2,0.4-1.9,0.4c-1.2,0-2.2-0.4-3.1-1.3s-1.3-1.9-1.3-3.2
		c0-1.2,0.4-2.3,1.3-3.1s1.9-1.3,3.1-1.3c0.7,0,1.3,0.1,1.9,0.4c0.6,0.3,1.1,0.7,1.5,1.3v-1.6h1.1V394.7z M141.8,395.6
		c-0.6,0-1.2,0.1-1.7,0.4s-0.9,0.7-1.2,1.3s-0.5,1.1-0.5,1.7s0.2,1.2,0.5,1.7s0.7,1,1.2,1.3s1.1,0.5,1.7,0.5c0.6,0,1.2-0.2,1.7-0.5
		s0.9-0.7,1.2-1.2s0.4-1.1,0.4-1.7c0-1-0.3-1.8-1-2.5C143.6,395.9,142.8,395.6,141.8,395.6z"
        />
        <path
          className="st0"
          d="M152.4,394.7h6.2l-4.8,7.5h4.7v1H152l4.8-7.5h-4.4V394.7z"
        />
        <path className="st0" d="M159.7,391.4h1.1v11.8h-1.1V391.4z" />
        <path
          className="st0"
          d="M170.2,400.4l0.9,0.5c-0.3,0.6-0.7,1.1-1,1.4s-0.8,0.6-1.3,0.8c-0.5,0.2-1.1,0.3-1.7,0.3
		c-1.4,0-2.5-0.5-3.3-1.4s-1.2-1.9-1.2-3.1c0-1.1,0.3-2,1-2.9c0.8-1.1,2-1.6,3.4-1.6s2.6,0.5,3.5,1.6c0.6,0.8,0.9,1.7,0.9,2.9h-7.6
		c0,1,0.3,1.8,0.9,2.4c0.6,0.6,1.4,0.9,2.3,0.9c0.4,0,0.9-0.1,1.3-0.2c0.4-0.2,0.8-0.4,1-0.6C169.6,401.4,169.9,401,170.2,400.4z
		 M170.2,398.1c-0.1-0.6-0.4-1-0.6-1.4c-0.3-0.3-0.6-0.6-1.1-0.8c-0.5-0.2-0.9-0.3-1.4-0.3c-0.8,0-1.6,0.3-2.2,0.8
		c-0.4,0.4-0.8,1-1,1.8h6.3V398.1z"
        />
        <path
          className="st0"
          d="M181.7,396.5l-0.9,0.5c-0.7-1-1.8-1.5-3.1-1.5c-1,0-1.9,0.3-2.6,1c-0.7,0.7-1,1.5-1,2.4c0,0.6,0.2,1.2,0.5,1.7
		s0.7,1,1.3,1.3c0.5,0.3,1.2,0.5,1.8,0.5c1.2,0,2.3-0.5,3.1-1.5l0.9,0.6c-0.4,0.6-1,1.1-1.6,1.4c-0.7,0.3-1.5,0.5-2.3,0.5
		c-1.3,0-2.5-0.4-3.3-1.3c-0.9-0.9-1.3-1.9-1.3-3.1c0-0.8,0.2-1.6,0.6-2.3s1-1.3,1.7-1.7c0.7-0.4,1.5-0.6,2.4-0.6
		c0.6,0,1.1,0.1,1.6,0.3s1,0.4,1.3,0.7C181.2,395.7,181.5,396.1,181.7,396.5z"
        />
        <path
          className="st0"
          d="M191,400.4l0.9,0.5c-0.3,0.6-0.7,1.1-1,1.4s-0.8,0.6-1.3,0.8c-0.5,0.2-1.1,0.3-1.7,0.3c-1.4,0-2.5-0.5-3.3-1.4
		s-1.2-1.9-1.2-3.1c0-1.1,0.3-2,1-2.9c0.8-1.1,2-1.6,3.4-1.6s2.6,0.5,3.5,1.6c0.6,0.8,0.9,1.7,0.9,2.9h-7.6c0,1,0.3,1.8,0.9,2.4
		c0.6,0.6,1.4,0.9,2.3,0.9c0.4,0,0.9-0.1,1.3-0.2s0.8-0.4,1-0.6C190.3,401.4,190.7,401,191,400.4z M191,398.1
		c-0.1-0.6-0.4-1-0.6-1.4s-0.6-0.6-1.1-0.8c-0.5-0.2-0.9-0.3-1.4-0.3c-0.8,0-1.6,0.3-2.2,0.8c-0.4,0.4-0.8,1-1,1.8h6.3V398.1z"
        />
        <path
          className="st0"
          d="M194.2,394.7h1.1v1.5c0.4-0.6,0.9-1,1.5-1.3s1.1-0.4,1.7-0.4c0.6,0,1.2,0.2,1.7,0.5s0.9,0.8,1.1,1.3
		s0.4,1.4,0.4,2.6v4.4h-1.1v-4.1c0-1,0-1.6-0.1-2c-0.1-0.6-0.4-1-0.7-1.3s-0.8-0.4-1.4-0.4c-0.7,0-1.3,0.2-1.8,0.7s-0.9,1-1,1.6
		c-0.1,0.4-0.2,1.2-0.2,2.3v3.1h-1.1L194.2,394.7L194.2,394.7z"
        />
        <path
          className="st0"
          d="M204.4,391.2c0.2,0,0.5,0.1,0.6,0.3c0.2,0.2,0.3,0.4,0.3,0.6s-0.1,0.5-0.3,0.6c-0.2,0.2-0.4,0.3-0.6,0.3
		c-0.2,0-0.5-0.1-0.6-0.3c-0.2-0.2-0.3-0.4-0.3-0.6c0-0.3,0.1-0.5,0.3-0.6C203.9,391.3,204.1,391.2,204.4,391.2z M203.8,394.7h1.1
		v8.5h-1.1V394.7z"
        />
        <path
          className="st0"
          d="M211.2,394.5c1.3,0,2.4,0.5,3.3,1.4c0.8,0.9,1.2,1.9,1.2,3.1s-0.4,2.2-1.2,3.1s-1.9,1.3-3.2,1.3
		s-2.4-0.4-3.2-1.3s-1.2-1.9-1.2-3.1s0.4-2.2,1.2-3.1C208.8,395,209.9,394.5,211.2,394.5z M211.2,395.6c-0.9,0-1.7,0.3-2.3,1
		s-1,1.5-1,2.4c0,0.6,0.1,1.2,0.4,1.7s0.7,0.9,1.2,1.2s1.1,0.4,1.7,0.4s1.2-0.1,1.7-0.4s0.9-0.7,1.2-1.2s0.4-1.1,0.4-1.7
		c0-1-0.3-1.8-1-2.4S212.1,395.6,211.2,395.6z"
        />
        <path
          className="st0"
          d="M226.2,391.4v11.8h-1.1v-1.5c-0.5,0.6-1,1-1.5,1.3s-1.2,0.4-1.9,0.4c-1.2,0-2.2-0.4-3.1-1.3s-1.3-1.9-1.3-3.2
		c0-1.2,0.4-2.3,1.3-3.1s1.9-1.3,3.1-1.3c0.7,0,1.3,0.1,1.9,0.4c0.6,0.3,1.1,0.7,1.5,1.3v-4.8L226.2,391.4L226.2,391.4z
		 M221.8,395.6c-0.6,0-1.2,0.1-1.7,0.4s-0.9,0.7-1.2,1.3c-0.3,0.5-0.5,1.1-0.5,1.7s0.2,1.2,0.5,1.7s0.7,1,1.2,1.3s1.1,0.5,1.7,0.5
		c0.6,0,1.2-0.2,1.7-0.5s0.9-0.7,1.2-1.2s0.4-1.1,0.4-1.7c0-1-0.3-1.8-1-2.5S222.7,395.6,221.8,395.6z"
        />
        <path
          className="st0"
          d="M237.1,394.7v8.5H236v-1.5c-0.5,0.6-1,1-1.5,1.3s-1.2,0.4-1.9,0.4c-1.2,0-2.2-0.4-3.1-1.3s-1.3-1.9-1.3-3.2
		c0-1.2,0.4-2.3,1.3-3.1s1.9-1.3,3.1-1.3c0.7,0,1.3,0.1,1.9,0.4c0.6,0.3,1.1,0.7,1.5,1.3v-1.6h1.1V394.7z M232.7,395.6
		c-0.6,0-1.2,0.1-1.7,0.4s-0.9,0.7-1.2,1.3c-0.3,0.5-0.5,1.1-0.5,1.7s0.2,1.2,0.5,1.7s0.7,1,1.2,1.3s1.1,0.5,1.7,0.5
		c0.6,0,1.2-0.2,1.7-0.5s0.9-0.7,1.2-1.2s0.4-1.1,0.4-1.7c0-1-0.3-1.8-1-2.5S233.7,395.6,232.7,395.6z"
        />
        <path
          className="st0"
          d="M238.4,394.7h1.1l2.6,6.2l2.7-6.2h0.2l2.7,6.2l2.7-6.2h1.1l-3.7,8.5h-0.2l-2.7-6.1l-2.7,6.1H242L238.4,394.7z"
        />
        <path
          className="st0"
          d="M261.1,396.5l-0.9,0.5c-0.7-1-1.8-1.5-3.1-1.5c-1,0-1.9,0.3-2.6,1c-0.7,0.7-1,1.5-1,2.4c0,0.6,0.2,1.2,0.5,1.7
		s0.7,1,1.3,1.3c0.5,0.3,1.2,0.5,1.8,0.5c1.2,0,2.3-0.5,3.1-1.5l0.9,0.6c-0.4,0.6-1,1.1-1.6,1.4c-0.7,0.3-1.5,0.5-2.3,0.5
		c-1.3,0-2.5-0.4-3.3-1.3c-0.9-0.9-1.3-1.9-1.3-3.1c0-0.8,0.2-1.6,0.6-2.3s1-1.3,1.7-1.7c0.7-0.4,1.5-0.6,2.4-0.6
		c0.6,0,1.1,0.1,1.6,0.3s1,0.4,1.3,0.7S260.8,396.1,261.1,396.5z"
        />
        <path
          className="st0"
          d="M262.3,394.7h1.2l2.9,6.5l2.8-6.5h1.2l-5.1,11.6h-1.2l1.6-3.8L262.3,394.7z"
        />
      </g>
      <g className="st3 st6" ref={thirdTextRef}>
        <path
          className="st0"
          d="M35.4,506.8h1.2l2.3,8.3l3.3-8.3h0.2l3.3,8.3l2.4-8.3h1.2L46,518.3h-0.2l-3.4-8.7l-3.5,8.7h-0.2L35.4,506.8z"
        />
        <path
          className="st0"
          d="M55.5,510.7l-0.7,0.7c-0.6-0.6-1.2-0.9-1.7-0.9c-0.4,0-0.7,0.1-0.9,0.4c-0.3,0.2-0.4,0.5-0.4,0.8
		s0.1,0.5,0.3,0.8s0.6,0.6,1.3,0.9c0.8,0.4,1.4,0.8,1.7,1.2s0.4,0.8,0.4,1.3c0,0.7-0.2,1.3-0.7,1.8s-1.1,0.7-1.8,0.7
		c-0.5,0-1-0.1-1.4-0.3s-0.8-0.5-1.1-0.9l0.7-0.8c0.6,0.6,1.2,0.9,1.8,0.9c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.5-0.6,0.5-1
		c0-0.3-0.1-0.6-0.3-0.8s-0.7-0.5-1.4-0.9c-0.8-0.4-1.3-0.8-1.6-1.2s-0.4-0.8-0.4-1.3c0-0.6,0.2-1.2,0.7-1.6s1-0.6,1.7-0.6
		C53.9,509.5,54.7,509.9,55.5,510.7z"
        />
        <path
          className="st0"
          d="M57.4,509.8h1.1v1.6c0.4-0.6,0.9-1,1.5-1.3s1.2-0.4,1.9-0.4c1.2,0,2.2,0.4,3.1,1.3s1.3,1.9,1.3,3.1
		c0,1.3-0.4,2.3-1.3,3.2s-1.9,1.3-3.1,1.3c-0.7,0-1.3-0.1-1.9-0.4c-0.6-0.3-1.1-0.7-1.5-1.3v4.6h-1.1V509.8z M61.8,510.6
		c-0.9,0-1.7,0.3-2.4,1c-0.7,0.7-1,1.5-1,2.5c0,0.6,0.1,1.2,0.4,1.7c0.3,0.5,0.7,0.9,1.2,1.2s1.1,0.5,1.7,0.5c0.6,0,1.1-0.2,1.7-0.5
		c0.5-0.3,0.9-0.7,1.2-1.3s0.5-1.1,0.5-1.7c0-0.6-0.2-1.2-0.5-1.7s-0.7-1-1.2-1.3C63,510.7,62.4,510.6,61.8,510.6z"
        />
        <path
          className="st0"
          d="M72.4,509.5c1.3,0,2.4,0.5,3.3,1.4c0.8,0.9,1.2,1.9,1.2,3.1s-0.4,2.2-1.2,3.1c-0.8,0.9-1.9,1.3-3.2,1.3
		c-1.3,0-2.4-0.4-3.2-1.3c-0.8-0.9-1.2-1.9-1.2-3.1c0-1.2,0.4-2.2,1.2-3.1C70,510,71.1,509.5,72.4,509.5z M72.4,510.6
		c-0.9,0-1.7,0.3-2.3,1c-0.6,0.7-1,1.5-1,2.4c0,0.6,0.1,1.2,0.4,1.7s0.7,0.9,1.2,1.2s1.1,0.4,1.7,0.4s1.2-0.1,1.7-0.4
		s0.9-0.7,1.2-1.2c0.3-0.5,0.4-1.1,0.4-1.7c0-1-0.3-1.8-1-2.4C74.1,510.9,73.3,510.6,72.4,510.6z M72.9,505.8h1.7l-2.3,2.4h-1.1
		L72.9,505.8z"
        />
        <path className="st0" d="M78.7,506.5h1.1v11.8h-1.1V506.5z" />
        <path
          className="st0"
          d="M82,509.8h1.1v1.5c0.4-0.6,0.9-1,1.5-1.3s1.1-0.4,1.7-0.4s1.2,0.2,1.7,0.5s0.9,0.8,1.1,1.3s0.4,1.4,0.4,2.6
		v4.4h-1.1v-4.1c0-1,0-1.6-0.1-2c-0.1-0.6-0.4-1-0.7-1.3s-0.8-0.4-1.4-0.4c-0.7,0-1.3,0.2-1.8,0.7s-0.9,1-1,1.6
		c-0.1,0.4-0.2,1.2-0.2,2.3v3.1H82V509.8z"
        />
        <path
          className="st0"
          d="M98.9,515.4l0.9,0.5c-0.3,0.6-0.7,1.1-1,1.4s-0.8,0.6-1.3,0.8s-1.1,0.3-1.7,0.3c-1.4,0-2.5-0.5-3.3-1.4
		s-1.2-1.9-1.2-3.1c0-1.1,0.3-2,1-2.9c0.8-1.1,2-1.6,3.4-1.6s2.6,0.5,3.5,1.6c0.6,0.8,0.9,1.7,0.9,2.9h-7.6c0,1,0.3,1.8,0.9,2.4
		c0.6,0.6,1.4,0.9,2.3,0.9c0.4,0,0.9-0.1,1.3-0.2c0.4-0.2,0.8-0.4,1-0.6C98.3,516.4,98.6,516,98.9,515.4z M98.9,513.1
		c-0.1-0.6-0.4-1-0.6-1.4c-0.3-0.3-0.6-0.6-1.1-0.8c-0.5-0.2-0.9-0.3-1.4-0.3c-0.8,0-1.6,0.3-2.2,0.8c-0.4,0.4-0.8,1-1,1.8h6.3
		V513.1z"
        />
        <path
          className="st0"
          d="M110.6,509.5c1.3,0,2.4,0.5,3.3,1.4c0.8,0.9,1.2,1.9,1.2,3.1s-0.4,2.2-1.2,3.1c-0.8,0.9-1.9,1.3-3.2,1.3
		c-1.3,0-2.4-0.4-3.2-1.3c-0.8-0.9-1.2-1.9-1.2-3.1c0-1.2,0.4-2.2,1.2-3.1C108.2,510,109.3,509.5,110.6,509.5z M110.6,510.6
		c-0.9,0-1.7,0.3-2.3,1c-0.6,0.7-1,1.5-1,2.4c0,0.6,0.1,1.2,0.4,1.7s0.7,0.9,1.2,1.2s1.1,0.4,1.7,0.4s1.2-0.1,1.7-0.4
		s0.9-0.7,1.2-1.2c0.3-0.5,0.4-1.1,0.4-1.7c0-1-0.3-1.8-1-2.4C112.3,510.9,111.5,510.6,110.6,510.6z"
        />
        <path
          className="st0"
          d="M117.1,506.5h1.1v6.7l3.9-3.4h1.6l-4.7,4.1l4.9,4.4h-1.5l-4.3-3.8v3.8H117L117.1,506.5L117.1,506.5z"
        />
        <path
          className="st0"
          d="M124.7,509.8h1.1v1.2c0.3-0.5,0.7-0.9,1.1-1.1s0.8-0.4,1.2-0.4c0.3,0,0.6,0.1,1,0.3l-0.6,0.9
		c-0.2-0.1-0.4-0.1-0.6-0.1c-0.4,0-0.7,0.1-1.1,0.4c-0.3,0.3-0.6,0.8-0.8,1.4c-0.1,0.5-0.2,1.5-0.2,2.9v2.9h-1.1
		C124.7,518.2,124.7,509.8,124.7,509.8z"
        />
        <path
          className="st0"
          d="M137.1,515.4l0.9,0.5c-0.3,0.6-0.7,1.1-1,1.4s-0.8,0.6-1.3,0.8s-1.1,0.3-1.7,0.3c-1.4,0-2.5-0.5-3.3-1.4
		s-1.2-1.9-1.2-3.1c0-1.1,0.3-2,1-2.9c0.8-1.1,2-1.6,3.4-1.6s2.6,0.5,3.5,1.6c0.6,0.8,0.9,1.7,0.9,2.9h-7.6c0,1,0.3,1.8,0.9,2.4
		c0.6,0.6,1.4,0.9,2.3,0.9c0.4,0,0.9-0.1,1.3-0.2c0.4-0.2,0.8-0.4,1-0.6C136.4,516.4,136.8,516,137.1,515.4z M137.1,513.1
		c-0.1-0.6-0.4-1-0.6-1.4c-0.3-0.3-0.6-0.6-1.1-0.8c-0.5-0.2-0.9-0.3-1.4-0.3c-0.8,0-1.6,0.3-2.2,0.8c-0.4,0.4-0.8,1-1,1.8h6.3
		V513.1z"
        />
        <path
          className="st0"
          d="M144.6,510.7l-0.7,0.7c-0.6-0.6-1.2-0.9-1.7-0.9c-0.4,0-0.7,0.1-0.9,0.4c-0.3,0.2-0.4,0.5-0.4,0.8
		s0.1,0.5,0.3,0.8s0.6,0.6,1.3,0.9c0.8,0.4,1.4,0.8,1.7,1.2s0.4,0.8,0.4,1.3c0,0.7-0.2,1.3-0.7,1.8s-1.1,0.7-1.8,0.7
		c-0.5,0-1-0.1-1.4-0.3s-0.8-0.5-1.1-0.9l0.7-0.8c0.6,0.6,1.2,0.9,1.8,0.9c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.5-0.6,0.5-1
		c0-0.3-0.1-0.6-0.3-0.8s-0.7-0.5-1.4-0.9c-0.8-0.4-1.3-0.8-1.6-1.2s-0.4-0.8-0.4-1.3c0-0.6,0.2-1.2,0.7-1.6s1-0.6,1.7-0.6
		C143,509.5,143.8,509.9,144.6,510.7z M142.2,505.8h1.7l-2.3,2.4h-1.1L142.2,505.8z"
        />
        <path className="st0" d="M146.4,506.5h1.1v11.8h-1.1V506.5z" />
        <path
          className="st0"
          d="M156.9,515.4l0.9,0.5c-0.3,0.6-0.7,1.1-1,1.4s-0.8,0.6-1.3,0.8s-1.1,0.3-1.7,0.3c-1.4,0-2.5-0.5-3.3-1.4
		s-1.2-1.9-1.2-3.1c0-1.1,0.3-2,1-2.9c0.8-1.1,2-1.6,3.4-1.6s2.6,0.5,3.5,1.6c0.6,0.8,0.9,1.7,0.9,2.9h-7.6c0,1,0.3,1.8,0.9,2.4
		c0.6,0.6,1.4,0.9,2.3,0.9c0.4,0,0.9-0.1,1.3-0.2c0.4-0.2,0.8-0.4,1-0.6C156.3,516.4,156.6,516,156.9,515.4z M156.9,513.1
		c-0.1-0.6-0.4-1-0.6-1.4c-0.3-0.3-0.6-0.6-1.1-0.8c-0.5-0.2-0.9-0.3-1.4-0.3c-0.8,0-1.6,0.3-2.2,0.8c-0.4,0.4-0.8,1-1,1.8h6.3
		V513.1z"
        />
        <path
          className="st0"
          d="M160.1,509.8h1.1v1.5c0.4-0.6,0.9-1,1.5-1.3s1.1-0.4,1.7-0.4c0.6,0,1.2,0.2,1.7,0.5s0.9,0.8,1.1,1.3
		s0.4,1.4,0.4,2.6v4.4h-1.1v-4.1c0-1,0-1.6-0.1-2c-0.1-0.6-0.4-1-0.7-1.3s-0.8-0.4-1.4-0.4c-0.7,0-1.3,0.2-1.8,0.7s-0.9,1-1,1.6
		c-0.1,0.4-0.2,1.2-0.2,2.3v3.1h-1.1L160.1,509.8L160.1,509.8z"
        />
        <path
          className="st0"
          d="M170.3,506.3c0.2,0,0.5,0.1,0.6,0.3s0.3,0.4,0.3,0.6s-0.1,0.5-0.3,0.6s-0.4,0.3-0.6,0.3
		c-0.2,0-0.5-0.1-0.6-0.3c-0.2-0.2-0.3-0.4-0.3-0.6c0-0.3,0.1-0.5,0.3-0.6C169.8,506.3,170,506.3,170.3,506.3z M169.7,509.8h1.1v8.5
		h-1.1V509.8z"
        />
        <path
          className="st0"
          d="M180.3,515.4l0.9,0.5c-0.3,0.6-0.7,1.1-1,1.4s-0.8,0.6-1.3,0.8s-1.1,0.3-1.7,0.3c-1.4,0-2.5-0.5-3.3-1.4
		s-1.2-1.9-1.2-3.1c0-1.1,0.3-2,1-2.9c0.8-1.1,2-1.6,3.4-1.6s2.6,0.5,3.5,1.6c0.6,0.8,0.9,1.7,0.9,2.9h-7.6c0,1,0.3,1.8,0.9,2.4
		c0.6,0.6,1.4,0.9,2.3,0.9c0.4,0,0.9-0.1,1.3-0.2c0.4-0.2,0.8-0.4,1-0.6C179.6,516.4,179.9,516,180.3,515.4z M180.3,513.1
		c-0.1-0.6-0.4-1-0.6-1.4c-0.3-0.3-0.6-0.6-1.1-0.8c-0.5-0.2-0.9-0.3-1.4-0.3c-0.8,0-1.6,0.3-2.2,0.8c-0.4,0.4-0.8,1-1,1.8h6.3
		V513.1z"
        />
        <path
          className="st0"
          d="M187.9,506.5h1.1v6.7l3.9-3.4h1.6l-4.7,4.1l4.9,4.4h-1.5l-4.3-3.8v3.8h-1.1v-11.8H187.9z"
        />
        <path
          className="st0"
          d="M200,509.5c1.3,0,2.4,0.5,3.3,1.4c0.8,0.9,1.2,1.9,1.2,3.1s-0.4,2.2-1.2,3.1s-1.9,1.3-3.2,1.3
		s-2.4-0.4-3.2-1.3c-0.8-0.9-1.2-1.9-1.2-3.1c0-1.2,0.4-2.2,1.2-3.1C197.6,510,198.7,509.5,200,509.5z M200,510.6
		c-0.9,0-1.7,0.3-2.3,1c-0.6,0.7-1,1.5-1,2.4c0,0.6,0.1,1.2,0.4,1.7s0.7,0.9,1.2,1.2s1.1,0.4,1.7,0.4s1.2-0.1,1.7-0.4
		s0.9-0.7,1.2-1.2s0.4-1.1,0.4-1.7c0-1-0.3-1.8-1-2.4C201.7,510.9,200.9,510.6,200,510.6z"
        />
        <path
          className="st0"
          d="M206.4,509.8h1.1v1.5c0.4-0.6,0.9-1,1.5-1.3s1.1-0.4,1.7-0.4c0.6,0,1.2,0.2,1.7,0.5s0.9,0.8,1.1,1.3
		s0.4,1.4,0.4,2.6v4.4h-1.1v-4.1c0-1,0-1.6-0.1-2c-0.1-0.6-0.4-1-0.7-1.3s-0.8-0.4-1.4-0.4c-0.7,0-1.3,0.2-1.8,0.7s-0.9,1-1,1.6
		c-0.1,0.4-0.2,1.2-0.2,2.3v3.1h-1.1L206.4,509.8L206.4,509.8z"
        />
        <path
          className="st0"
          d="M224.4,511.5l-0.9,0.5c-0.7-1-1.8-1.5-3.1-1.5c-1,0-1.9,0.3-2.6,1c-0.7,0.7-1,1.5-1,2.4c0,0.6,0.2,1.2,0.5,1.7
		s0.7,1,1.3,1.3c0.5,0.3,1.2,0.5,1.8,0.5c1.2,0,2.3-0.5,3.1-1.5l0.9,0.6c-0.4,0.6-1,1.1-1.6,1.4c-0.7,0.3-1.5,0.5-2.3,0.5
		c-1.3,0-2.5-0.4-3.3-1.3s-1.3-1.9-1.3-3.1c0-0.8,0.2-1.6,0.6-2.3s1-1.3,1.7-1.7s1.5-0.6,2.4-0.6c0.6,0,1.1,0.1,1.6,0.3
		s1,0.4,1.3,0.7S224.2,511.1,224.4,511.5z"
        />
        <path
          className="st0"
          d="M233.7,515.4l0.9,0.5c-0.3,0.6-0.7,1.1-1,1.4s-0.8,0.6-1.3,0.8s-1.1,0.3-1.7,0.3c-1.4,0-2.5-0.5-3.3-1.4
		s-1.2-1.9-1.2-3.1c0-1.1,0.3-2,1-2.9c0.8-1.1,2-1.6,3.4-1.6s2.6,0.5,3.5,1.6c0.6,0.8,0.9,1.7,0.9,2.9h-7.6c0,1,0.3,1.8,0.9,2.4
		c0.6,0.6,1.4,0.9,2.3,0.9c0.4,0,0.9-0.1,1.3-0.2c0.4-0.2,0.8-0.4,1-0.6C233.1,516.4,233.4,516,233.7,515.4z M233.7,513.1
		c-0.1-0.6-0.4-1-0.6-1.4s-0.6-0.6-1.1-0.8s-0.9-0.3-1.4-0.3c-0.8,0-1.6,0.3-2.2,0.8c-0.4,0.4-0.8,1-1,1.8h6.3V513.1z"
        />
        <path
          className="st0"
          d="M237,509.8h1.1v1.6c0.4-0.6,0.9-1,1.5-1.3s1.2-0.4,1.9-0.4c1.2,0,2.2,0.4,3.1,1.3s1.3,1.9,1.3,3.1
		c0,1.3-0.4,2.3-1.3,3.2s-1.9,1.3-3.1,1.3c-0.7,0-1.3-0.1-1.9-0.4c-0.6-0.3-1.1-0.7-1.5-1.3v4.6H237V509.8z M241.3,510.6
		c-0.9,0-1.7,0.3-2.4,1c-0.6,0.7-1,1.5-1,2.5c0,0.6,0.1,1.2,0.4,1.7s0.7,0.9,1.2,1.2s1.1,0.5,1.7,0.5c0.6,0,1.1-0.2,1.7-0.5
		c0.5-0.3,0.9-0.7,1.2-1.3s0.5-1.1,0.5-1.7c0-0.6-0.2-1.2-0.5-1.7s-0.7-1-1.2-1.3C242.5,510.7,241.9,510.6,241.3,510.6z"
        />
        <path
          className="st0"
          d="M256.1,511.5l-0.9,0.5c-0.7-1-1.8-1.5-3.1-1.5c-1,0-1.9,0.3-2.6,1c-0.7,0.7-1,1.5-1,2.4c0,0.6,0.2,1.2,0.5,1.7
		s0.7,1,1.3,1.3c0.5,0.3,1.2,0.5,1.8,0.5c1.2,0,2.3-0.5,3.1-1.5l0.9,0.6c-0.4,0.6-1,1.1-1.6,1.4c-0.7,0.3-1.5,0.5-2.3,0.5
		c-1.3,0-2.5-0.4-3.3-1.3s-1.3-1.9-1.3-3.1c0-0.8,0.2-1.6,0.6-2.3s1-1.3,1.7-1.7s1.5-0.6,2.4-0.6c0.6,0,1.1,0.1,1.6,0.3
		s1,0.4,1.3,0.7S255.9,511.1,256.1,511.5z"
        />
        <path
          className="st0"
          d="M258.1,509.8h1.1v9.8c0,0.7-0.1,1.2-0.4,1.6s-0.6,0.5-1.1,0.5c-0.4,0-0.8-0.1-1.3-0.3v-1
		c0.3,0.2,0.6,0.2,0.8,0.2c0.3,0,0.5-0.1,0.7-0.4c0.1-0.2,0.1-0.5,0.1-1L258.1,509.8L258.1,509.8z M258.6,506.3
		c0.3,0,0.5,0.1,0.6,0.3s0.3,0.4,0.3,0.6s-0.1,0.5-0.3,0.6s-0.4,0.3-0.6,0.3s-0.5-0.1-0.6-0.3s-0.3-0.4-0.3-0.6
		c0-0.3,0.1-0.5,0.3-0.6S258.4,506.3,258.6,506.3z"
        />
        <path
          className="st0"
          d="M261.8,506.3c0.2,0,0.5,0.1,0.6,0.3c0.2,0.2,0.3,0.4,0.3,0.6s-0.1,0.5-0.3,0.6c-0.2,0.2-0.4,0.3-0.6,0.3
		s-0.5-0.1-0.6-0.3c-0.2-0.2-0.3-0.4-0.3-0.6c0-0.3,0.1-0.5,0.3-0.6C261.4,506.3,261.6,506.3,261.8,506.3z M261.3,509.8h1.1v8.5
		h-1.1V509.8z"
        />
        <path
          className="st0"
          d="M277.6,506.5v11.8h-1.1v-1.5c-0.5,0.6-1,1-1.5,1.3s-1.2,0.4-1.9,0.4c-1.2,0-2.2-0.4-3.1-1.3s-1.3-1.9-1.3-3.2
		c0-1.2,0.4-2.3,1.3-3.1s1.9-1.3,3.1-1.3c0.7,0,1.3,0.1,1.9,0.4s1.1,0.7,1.5,1.3v-4.8H277.6z M273.2,510.6c-0.6,0-1.2,0.1-1.7,0.4
		s-0.9,0.7-1.2,1.3c-0.3,0.5-0.5,1.1-0.5,1.7c0,0.6,0.2,1.2,0.5,1.7s0.7,1,1.2,1.3s1.1,0.5,1.7,0.5s1.2-0.2,1.7-0.5s0.9-0.7,1.2-1.2
		c0.3-0.5,0.4-1.1,0.4-1.7c0-1-0.3-1.8-1-2.5S274.2,510.6,273.2,510.6z"
        />
        <path
          className="st0"
          d="M279.4,509.8h6.2l-4.8,7.5h4.7v1H279l4.8-7.5h-4.4L279.4,509.8L279.4,509.8z"
        />
        <path
          className="st0"
          d="M287.2,506.3c0.2,0,0.5,0.1,0.6,0.3c0.2,0.2,0.3,0.4,0.3,0.6s-0.1,0.5-0.3,0.6c-0.2,0.2-0.4,0.3-0.6,0.3
		s-0.5-0.1-0.6-0.3c-0.2-0.2-0.3-0.4-0.3-0.6c0-0.3,0.1-0.5,0.3-0.6C286.8,506.3,287,506.3,287.2,506.3z M286.7,509.8h1.1v8.5h-1.1
		V509.8z"
        />
        <path
          className="st0"
          d="M298.5,509.8v8.5h-1.1v-1.5c-0.5,0.6-1,1-1.5,1.3s-1.2,0.4-1.9,0.4c-1.2,0-2.2-0.4-3.1-1.3s-1.3-1.9-1.3-3.2
		c0-1.2,0.4-2.3,1.3-3.1s1.9-1.3,3.1-1.3c0.7,0,1.3,0.1,1.9,0.4s1.1,0.7,1.5,1.3v-1.6h1.1V509.8z M294.2,510.6
		c-0.6,0-1.2,0.1-1.7,0.4s-0.9,0.7-1.2,1.3c-0.3,0.5-0.5,1.1-0.5,1.7c0,0.6,0.2,1.2,0.5,1.7s0.7,1,1.2,1.3s1.1,0.5,1.7,0.5
		s1.2-0.2,1.7-0.5s0.9-0.7,1.2-1.2c0.3-0.5,0.4-1.1,0.4-1.7c0-1-0.3-1.8-1-2.5S295.1,510.6,294.2,510.6z"
        />
        <path
          className="st0"
          d="M302.7,510.7l1.6-1v1.1l-1.6,1v6.5h-1.1v-5.8l-1.4,0.9v-1.1l1.4-0.9v-4.9h1.1V510.7z"
        />
        <path
          className="st0"
          d="M314.3,509.8v8.5h-1.1v-1.5c-0.5,0.6-1,1-1.5,1.3s-1.2,0.4-1.9,0.4c-1.2,0-2.2-0.4-3.1-1.3s-1.3-1.9-1.3-3.2
		c0-1.2,0.4-2.3,1.3-3.1s1.9-1.3,3.1-1.3c0.7,0,1.3,0.1,1.9,0.4s1.1,0.7,1.5,1.3v-1.6h1.1V509.8z M309.9,510.6
		c-0.6,0-1.2,0.1-1.7,0.4s-0.9,0.7-1.2,1.3c-0.3,0.5-0.5,1.1-0.5,1.7c0,0.6,0.2,1.2,0.5,1.7s0.7,1,1.2,1.3s1.1,0.5,1.7,0.5
		s1.2-0.2,1.7-0.5s0.9-0.7,1.2-1.2c0.3-0.5,0.4-1.1,0.4-1.7c0-1-0.3-1.8-1-2.5S310.8,510.6,309.9,510.6z"
        />
        <path
          className="st0"
          d="M316.7,509.8h1.1v1.5c0.4-0.6,0.9-1,1.5-1.3s1.1-0.4,1.7-0.4s1.2,0.2,1.7,0.5s0.9,0.8,1.1,1.3s0.4,1.4,0.4,2.6
		v4.4H323v-4.1c0-1,0-1.6-0.1-2c-0.1-0.6-0.4-1-0.7-1.3s-0.8-0.4-1.4-0.4c-0.7,0-1.3,0.2-1.8,0.7s-0.9,1-1,1.6
		c-0.1,0.4-0.2,1.2-0.2,2.3v3.1h-1.1L316.7,509.8L316.7,509.8z"
        />
        <path
          className="st0"
          d="M326.9,506.3c0.2,0,0.5,0.1,0.6,0.3c0.2,0.2,0.3,0.4,0.3,0.6s-0.1,0.5-0.3,0.6c-0.2,0.2-0.4,0.3-0.6,0.3
		s-0.5-0.1-0.6-0.3c-0.2-0.2-0.3-0.4-0.3-0.6c0-0.3,0.1-0.5,0.3-0.6C326.4,506.3,326.6,506.3,326.9,506.3z M326.3,509.8h1.1v8.5
		h-1.1V509.8z"
        />
        <path
          className="st0"
          d="M338.1,509.8v8.5H337v-1.5c-0.5,0.6-1,1-1.5,1.3s-1.2,0.4-1.9,0.4c-1.2,0-2.2-0.4-3.1-1.3s-1.3-1.9-1.3-3.2
		c0-1.2,0.4-2.3,1.3-3.1s1.9-1.3,3.1-1.3c0.7,0,1.3,0.1,1.9,0.4s1.1,0.7,1.5,1.3v-1.6h1.1V509.8z M333.8,510.6
		c-0.6,0-1.2,0.1-1.7,0.4s-0.9,0.7-1.2,1.3c-0.3,0.5-0.5,1.1-0.5,1.7c0,0.6,0.2,1.2,0.5,1.7s0.7,1,1.2,1.3s1.1,0.5,1.7,0.5
		s1.2-0.2,1.7-0.5s0.9-0.7,1.2-1.2c0.3-0.5,0.4-1.1,0.4-1.7c0-1-0.3-1.8-1-2.5S334.7,510.6,333.8,510.6z"
        />
      </g>
      <g className="st3 st6" ref={fourthTextRef}>
        <path
          className="st0"
          d="M122.1,634.7v-11.5h0.3l7.6,8.8v-8.8h1.1v11.5h-0.3l-7.6-8.7v8.7H122.1z"
        />
        <path
          className="st0"
          d="M142.2,626.2v8.5h-1.1v-1.5c-0.5,0.6-1,1-1.5,1.3s-1.2,0.4-1.9,0.4c-1.2,0-2.2-0.4-3.1-1.3s-1.3-1.9-1.3-3.2
		c0-1.2,0.4-2.3,1.3-3.1s1.9-1.3,3.1-1.3c0.7,0,1.3,0.1,1.9,0.4c0.6,0.3,1.1,0.7,1.5,1.3v-1.6L142.2,626.2L142.2,626.2z M137.9,627
		c-0.6,0-1.2,0.1-1.7,0.4c-0.5,0.3-0.9,0.7-1.2,1.3s-0.5,1.1-0.5,1.7c0,0.6,0.2,1.2,0.5,1.7s0.7,1,1.2,1.3s1.1,0.5,1.7,0.5
		c0.6,0,1.2-0.2,1.7-0.5s0.9-0.7,1.2-1.2s0.4-1.1,0.4-1.7c0-1-0.3-1.8-1-2.5C139.6,627.4,138.8,627,137.9,627z"
        />
        <path
          className="st0"
          d="M149,627.1l-0.7,0.7c-0.6-0.6-1.2-0.9-1.7-0.9c-0.4,0-0.7,0.1-0.9,0.4c-0.3,0.2-0.4,0.5-0.4,0.8
		s0.1,0.5,0.3,0.8s0.6,0.6,1.3,0.9c0.8,0.4,1.4,0.8,1.7,1.2s0.4,0.8,0.4,1.3c0,0.7-0.2,1.3-0.7,1.8s-1.1,0.7-1.8,0.7
		c-0.5,0-1-0.1-1.4-0.3s-0.8-0.5-1.1-0.9l0.7-0.8c0.6,0.6,1.2,0.9,1.8,0.9c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.5-0.6,0.5-1
		c0-0.3-0.1-0.6-0.3-0.8s-0.7-0.5-1.4-0.9c-0.8-0.4-1.3-0.8-1.6-1.2s-0.4-0.8-0.4-1.3c0-0.6,0.2-1.2,0.7-1.6s1-0.6,1.7-0.6
		C147.4,626,148.2,626.4,149,627.1z"
        />
        <path
          className="st0"
          d="M150.2,626.2h6.2l-4.8,7.5h4.7v1h-6.5l4.8-7.5h-4.4V626.2z"
        />
        <path
          className="st0"
          d="M166.2,626.2v8.5h-1.1v-1.5c-0.5,0.6-1,1-1.5,1.3s-1.2,0.4-1.9,0.4c-1.2,0-2.2-0.4-3.1-1.3s-1.3-1.9-1.3-3.2
		c0-1.2,0.4-2.3,1.3-3.1s1.9-1.3,3.1-1.3c0.7,0,1.3,0.1,1.9,0.4c0.6,0.3,1.1,0.7,1.5,1.3v-1.6L166.2,626.2L166.2,626.2z M161.8,627
		c-0.6,0-1.2,0.1-1.7,0.4c-0.5,0.3-0.9,0.7-1.2,1.3s-0.5,1.1-0.5,1.7c0,0.6,0.2,1.2,0.5,1.7s0.7,1,1.2,1.3s1.1,0.5,1.7,0.5
		c0.6,0,1.2-0.2,1.7-0.5s0.9-0.7,1.2-1.2s0.4-1.1,0.4-1.7c0-1-0.3-1.8-1-2.5C163.5,627.4,162.7,627,161.8,627z"
        />
        <path
          className="st0"
          d="M173.1,626.2h1.1v1.6c0.4-0.6,0.9-1,1.5-1.3s1.2-0.4,1.9-0.4c1.2,0,2.2,0.4,3.1,1.3c0.9,0.9,1.3,1.9,1.3,3.1
		c0,1.3-0.4,2.3-1.3,3.2c-0.9,0.9-1.9,1.3-3.1,1.3c-0.7,0-1.3-0.1-1.9-0.4c-0.6-0.3-1.1-0.7-1.5-1.3v4.6h-1.1V626.2z M177.5,627
		c-0.9,0-1.7,0.3-2.4,1s-1,1.5-1,2.5c0,0.6,0.1,1.2,0.4,1.7c0.3,0.5,0.7,0.9,1.2,1.2s1.1,0.5,1.7,0.5c0.6,0,1.1-0.2,1.7-0.5
		c0.5-0.3,0.9-0.7,1.2-1.3s0.5-1.1,0.5-1.7c0-0.6-0.2-1.2-0.5-1.7s-0.7-1-1.2-1.3C178.6,627.2,178.1,627,177.5,627z"
        />
        <path
          className="st0"
          d="M183.6,626.2h1.1v1.2c0.3-0.5,0.7-0.9,1.1-1.1c0.4-0.2,0.8-0.4,1.2-0.4c0.3,0,0.6,0.1,1,0.3l-0.6,0.9
		c-0.2-0.1-0.4-0.1-0.6-0.1c-0.4,0-0.7,0.1-1.1,0.4c-0.3,0.3-0.6,0.8-0.8,1.4c-0.1,0.5-0.2,1.5-0.2,2.9v2.9h-1.1L183.6,626.2
		L183.6,626.2z"
        />
        <path
          className="st0"
          d="M192.8,626c1.3,0,2.4,0.5,3.3,1.4c0.8,0.9,1.2,1.9,1.2,3.1c0,1.2-0.4,2.2-1.2,3.1c-0.8,0.9-1.9,1.3-3.2,1.3
		s-2.4-0.4-3.2-1.3c-0.8-0.9-1.2-1.9-1.2-3.1c0-1.2,0.4-2.2,1.2-3.1C190.4,626.5,191.5,626,192.8,626z M192.8,627
		c-0.9,0-1.7,0.3-2.3,1s-1,1.5-1,2.4c0,0.6,0.1,1.2,0.4,1.7s0.7,0.9,1.2,1.2s1.1,0.4,1.7,0.4c0.6,0,1.2-0.1,1.7-0.4
		c0.5-0.3,0.9-0.7,1.2-1.2s0.4-1.1,0.4-1.7c0-1-0.3-1.8-1-2.4S193.7,627,192.8,627z"
        />
        <path
          className="st0"
          d="M199.3,626.2h1.1v1.6c0.4-0.6,0.9-1,1.5-1.3s1.2-0.4,1.9-0.4c1.2,0,2.2,0.4,3.1,1.3c0.9,0.9,1.3,1.9,1.3,3.1
		c0,1.3-0.4,2.3-1.3,3.2c-0.8,0.9-1.9,1.3-3.1,1.3c-0.7,0-1.3-0.1-1.9-0.4c-0.6-0.3-1.1-0.7-1.5-1.3v4.6h-1.1V626.2z M203.7,627
		c-0.9,0-1.7,0.3-2.4,1s-1,1.5-1,2.5c0,0.6,0.1,1.2,0.4,1.7c0.3,0.5,0.7,0.9,1.2,1.2s1.1,0.5,1.7,0.5c0.6,0,1.1-0.2,1.7-0.5
		c0.5-0.3,0.9-0.7,1.2-1.3s0.5-1.1,0.5-1.7c0-0.6-0.2-1.2-0.5-1.7s-0.7-1-1.2-1.3C204.8,627.2,204.3,627,203.7,627z"
        />
        <path
          className="st0"
          d="M214.2,626c1.3,0,2.4,0.5,3.3,1.4c0.8,0.9,1.2,1.9,1.2,3.1c0,1.2-0.4,2.2-1.2,3.1c-0.8,0.9-1.9,1.3-3.2,1.3
		s-2.4-0.4-3.2-1.3c-0.8-0.9-1.2-1.9-1.2-3.1c0-1.2,0.4-2.2,1.2-3.1C211.8,626.5,212.9,626,214.2,626z M214.2,627
		c-0.9,0-1.7,0.3-2.3,1s-1,1.5-1,2.4c0,0.6,0.1,1.2,0.4,1.7s0.7,0.9,1.2,1.2s1.1,0.4,1.7,0.4c0.6,0,1.2-0.1,1.7-0.4
		c0.5-0.3,0.9-0.7,1.2-1.2s0.4-1.1,0.4-1.7c0-1-0.3-1.8-1-2.4S215.1,627,214.2,627z"
        />
        <path
          className="st0"
          d="M220,626.2h6.2l-4.8,7.5h4.7v1h-6.5l4.8-7.5H220V626.2z"
        />
        <path
          className="st0"
          d="M226.5,626.2h1.2l2.9,6.5l2.8-6.5h1.2l-5.1,11.6h-1.2l1.6-3.8L226.5,626.2z"
        />
        <path
          className="st0"
          d="M244.3,628l-0.9,0.5c-0.8-1-1.8-1.5-3.1-1.5c-1,0-1.9,0.3-2.6,1s-1,1.5-1,2.4c0,0.6,0.2,1.2,0.5,1.7
		s0.7,1,1.3,1.3c0.5,0.3,1.2,0.5,1.8,0.5c1.2,0,2.3-0.5,3.1-1.5l0.9,0.6c-0.4,0.6-1,1.1-1.6,1.4c-0.7,0.3-1.5,0.5-2.3,0.5
		c-1.3,0-2.5-0.4-3.3-1.3c-0.9-0.9-1.3-1.9-1.3-3.1c0-0.8,0.2-1.6,0.6-2.3s1-1.3,1.7-1.7s1.5-0.6,2.4-0.6c0.6,0,1.1,0.1,1.6,0.3
		s1,0.4,1.3,0.7C243.7,627.2,244,627.5,244.3,628z"
        />
        <path
          className="st0"
          d="M246.3,626.2h1.1v9.8c0,0.7-0.1,1.2-0.4,1.6s-0.6,0.5-1.1,0.5c-0.4,0-0.8-0.1-1.3-0.3v-1
		c0.3,0.2,0.6,0.2,0.8,0.2c0.3,0,0.5-0.1,0.7-0.4c0.1-0.2,0.1-0.5,0.1-1L246.3,626.2L246.3,626.2z M246.8,622.7
		c0.3,0,0.5,0.1,0.6,0.3c0.2,0.2,0.3,0.4,0.3,0.6c0,0.2-0.1,0.5-0.3,0.6c-0.2,0.2-0.4,0.3-0.6,0.3s-0.5-0.1-0.6-0.3
		c-0.2-0.2-0.3-0.4-0.3-0.6c0-0.3,0.1-0.5,0.3-0.6C246.3,622.8,246.5,622.7,246.8,622.7z"
        />
        <path
          className="st0"
          d="M258.1,626.2v8.5H257v-1.5c-0.5,0.6-1,1-1.5,1.3s-1.2,0.4-1.9,0.4c-1.2,0-2.2-0.4-3.1-1.3s-1.3-1.9-1.3-3.2
		c0-1.2,0.4-2.3,1.3-3.1s1.9-1.3,3.1-1.3c0.7,0,1.3,0.1,1.9,0.4c0.6,0.3,1.1,0.7,1.5,1.3v-1.6L258.1,626.2L258.1,626.2z M253.7,627
		c-0.6,0-1.2,0.1-1.7,0.4c-0.5,0.3-0.9,0.7-1.2,1.3s-0.5,1.1-0.5,1.7c0,0.6,0.2,1.2,0.5,1.7s0.7,1,1.2,1.3s1.1,0.5,1.7,0.5
		c0.6,0,1.2-0.2,1.7-0.5s0.9-0.7,1.2-1.2s0.4-1.1,0.4-1.7c0-1-0.3-1.8-1-2.5C255.5,627.4,254.7,627,253.7,627z"
        />
      </g>
      <g className="st3 st6" ref={fifthTextRef}>
        <path
          className="st0"
          d="M151.6,735.2h2.3c1.3,0,2.1,0.1,2.6,0.2c0.7,0.2,1.2,0.5,1.7,1c0.4,0.5,0.6,1.1,0.6,1.9c0,0.6-0.1,1.2-0.4,1.7
		c-0.3,0.5-0.7,0.8-1.3,1.1c-0.5,0.2-1.3,0.4-2.3,0.4l4.1,5.3h-1.4l-4.1-5.3h-0.6v5.3h-1.1v-11.6H151.6z M152.8,736.4v3.9h2
		c0.8,0,1.3-0.1,1.7-0.2c0.4-0.1,0.7-0.4,0.9-0.7s0.3-0.7,0.3-1.1c0-0.4-0.1-0.7-0.3-1.1s-0.5-0.5-0.8-0.7c-0.3-0.1-0.9-0.2-1.7-0.2
		L152.8,736.4L152.8,736.4z"
        />
        <path
          className="st0"
          d="M168.3,743.9l0.9,0.5c-0.3,0.6-0.7,1.1-1,1.4s-0.8,0.6-1.3,0.8s-1.1,0.3-1.7,0.3c-1.4,0-2.5-0.5-3.3-1.4
		s-1.2-1.9-1.2-3.1c0-1.1,0.3-2,1-2.9c0.8-1.1,2-1.6,3.4-1.6s2.6,0.5,3.5,1.6c0.6,0.8,0.9,1.7,0.9,2.9h-7.6c0,1,0.3,1.8,0.9,2.4
		c0.6,0.6,1.4,0.9,2.3,0.9c0.4,0,0.9-0.1,1.3-0.2c0.4-0.2,0.8-0.4,1-0.6C167.6,744.9,167.9,744.5,168.3,743.9z M168.3,741.6
		c-0.1-0.6-0.4-1-0.6-1.4c-0.3-0.3-0.6-0.6-1.1-0.8s-0.9-0.3-1.4-0.3c-0.8,0-1.6,0.3-2.2,0.8c-0.4,0.4-0.8,1-1,1.8L168.3,741.6
		L168.3,741.6z"
        />
        <path
          className="st0"
          d="M180,738.2v8.5h-1.1v-1.5c-0.5,0.6-1,1-1.5,1.3s-1.2,0.4-1.9,0.4c-1.2,0-2.2-0.4-3.1-1.3s-1.3-1.9-1.3-3.2
		c0-1.2,0.4-2.3,1.3-3.1s1.9-1.3,3.1-1.3c0.7,0,1.3,0.1,1.9,0.4c0.6,0.3,1.1,0.7,1.5,1.3v-1.6L180,738.2L180,738.2z M175.6,739.1
		c-0.6,0-1.2,0.1-1.7,0.4c-0.5,0.3-0.9,0.7-1.2,1.3s-0.5,1.1-0.5,1.7c0,0.6,0.2,1.2,0.5,1.7s0.7,1,1.2,1.3s1.1,0.5,1.7,0.5
		c0.6,0,1.2-0.2,1.7-0.5s0.9-0.7,1.2-1.2s0.4-1.1,0.4-1.7c0-1-0.3-1.8-1-2.5C177.3,739.4,176.6,739.1,175.6,739.1z"
        />
        <path className="st0" d="M182.3,734.9h1.1v11.8h-1.1V734.9z" />
        <path
          className="st0"
          d="M186,734.7c0.2,0,0.5,0.1,0.6,0.3s0.3,0.4,0.3,0.6c0,0.2-0.1,0.5-0.3,0.6s-0.4,0.3-0.6,0.3
		c-0.2,0-0.5-0.1-0.6-0.3c-0.2-0.2-0.3-0.4-0.3-0.6c0-0.3,0.1-0.5,0.3-0.6C185.6,734.8,185.8,734.7,186,734.7z M185.5,738.2h1.1v8.5
		h-1.1V738.2z"
        />
        <path
          className="st0"
          d="M188.1,738.2h6.2l-4.8,7.5h4.7v1h-6.5l4.8-7.5h-4.4V738.2z"
        />
        <path
          className="st0"
          d="M204.1,738.2v8.5H203v-1.5c-0.5,0.6-1,1-1.5,1.3s-1.2,0.4-1.9,0.4c-1.2,0-2.2-0.4-3.1-1.3s-1.3-1.9-1.3-3.2
		c0-1.2,0.4-2.3,1.3-3.1s1.9-1.3,3.1-1.3c0.7,0,1.3,0.1,1.9,0.4c0.6,0.3,1.1,0.7,1.5,1.3v-1.6L204.1,738.2L204.1,738.2z
		 M199.7,739.1c-0.6,0-1.2,0.1-1.7,0.4c-0.5,0.3-0.9,0.7-1.2,1.3c-0.3,0.5-0.5,1.1-0.5,1.7c0,0.6,0.2,1.2,0.5,1.7s0.7,1,1.2,1.3
		s1.1,0.5,1.7,0.5c0.6,0,1.2-0.2,1.7-0.5s0.9-0.7,1.2-1.2s0.4-1.1,0.4-1.7c0-1-0.3-1.8-1-2.5S200.7,739.1,199.7,739.1z"
        />
        <path
          className="st0"
          d="M214.8,740l-0.9,0.5c-0.7-1-1.8-1.5-3.1-1.5c-1,0-1.9,0.3-2.6,1c-0.7,0.7-1,1.5-1,2.4c0,0.6,0.2,1.2,0.5,1.7
		s0.7,1,1.3,1.3c0.5,0.3,1.2,0.5,1.8,0.5c1.2,0,2.3-0.5,3.1-1.5l0.9,0.6c-0.4,0.6-1,1.1-1.6,1.4c-0.7,0.3-1.5,0.5-2.3,0.5
		c-1.3,0-2.5-0.4-3.3-1.3c-0.9-0.9-1.3-1.9-1.3-3.1c0-0.8,0.2-1.6,0.6-2.3s1-1.3,1.7-1.7s1.5-0.6,2.4-0.6c0.6,0,1.1,0.1,1.6,0.3
		s1,0.4,1.3,0.7C214.3,739.2,214.6,739.6,214.8,740z"
        />
        <path
          className="st0"
          d="M216.8,738.2h1.1v9.8c0,0.7-0.1,1.2-0.4,1.6s-0.6,0.5-1.1,0.5c-0.4,0-0.8-0.1-1.3-0.3v-1
		c0.3,0.2,0.6,0.2,0.8,0.2c0.3,0,0.5-0.1,0.7-0.4c0.1-0.2,0.1-0.5,0.1-1L216.8,738.2L216.8,738.2z M217.3,734.7
		c0.3,0,0.5,0.1,0.6,0.3c0.2,0.2,0.3,0.4,0.3,0.6c0,0.2-0.1,0.5-0.3,0.6c-0.2,0.2-0.4,0.3-0.6,0.3s-0.5-0.1-0.6-0.3
		c-0.2-0.2-0.3-0.4-0.3-0.6c0-0.3,0.1-0.5,0.3-0.6C216.9,734.8,217.1,734.7,217.3,734.7z"
        />
        <path
          className="st0"
          d="M228.6,738.2v8.5h-1.1v-1.5c-0.5,0.6-1,1-1.5,1.3s-1.2,0.4-1.9,0.4c-1.2,0-2.2-0.4-3.1-1.3s-1.3-1.9-1.3-3.2
		c0-1.2,0.4-2.3,1.3-3.1s1.9-1.3,3.1-1.3c0.7,0,1.3,0.1,1.9,0.4c0.6,0.3,1.1,0.7,1.5,1.3v-1.6L228.6,738.2L228.6,738.2z
		 M224.3,739.1c-0.6,0-1.2,0.1-1.7,0.4c-0.5,0.3-0.9,0.7-1.2,1.3c-0.3,0.5-0.5,1.1-0.5,1.7c0,0.6,0.2,1.2,0.5,1.7s0.7,1,1.2,1.3
		s1.1,0.5,1.7,0.5c0.6,0,1.2-0.2,1.7-0.5s0.9-0.7,1.2-1.2s0.4-1.1,0.4-1.7c0-1-0.3-1.8-1-2.5S225.2,739.1,224.3,739.1z"
        />
      </g>
      <g ref={sixthTextRef} className="st6">
        <g className="st3">
          <path
            className="st0"
            d="M167,861.9h1.2l2.3,8.3l3.3-8.3h0.2l3.3,8.3l2.4-8.3h1.2l-3.3,11.5h-0.2l-3.4-8.7l-3.5,8.7h-0.2L167,861.9z"
          />
          <path
            className="st0"
            d="M181.9,864.9h1.2l2.9,6.5l2.8-6.5h1.2l-5.1,11.6h-1.2l1.6-3.8L181.9,864.9z"
          />
          <path
            className="st0"
            d="M191.4,864.9h1.1v1.5c0.4-0.6,0.9-1,1.5-1.3s1.1-0.4,1.7-0.4c0.6,0,1.2,0.2,1.7,0.5s0.9,0.8,1.1,1.3
		s0.4,1.4,0.4,2.6v4.4h-1.1v-4.1c0-1,0-1.6-0.1-2c-0.1-0.6-0.4-1-0.7-1.3s-0.8-0.4-1.4-0.4c-0.7,0-1.3,0.2-1.8,0.7s-0.9,1-1,1.6
		c-0.1,0.4-0.2,1.2-0.2,2.3v3.1h-1.1L191.4,864.9L191.4,864.9z"
          />
          <path
            className="st0"
            d="M201.6,861.4c0.2,0,0.5,0.1,0.6,0.3c0.2,0.2,0.3,0.4,0.3,0.6c0,0.2-0.1,0.5-0.3,0.6c-0.2,0.2-0.4,0.3-0.6,0.3
		c-0.2,0-0.5-0.1-0.6-0.3c-0.2-0.2-0.3-0.4-0.3-0.6c0-0.3,0.1-0.5,0.3-0.6C201.1,861.5,201.3,861.4,201.6,861.4z M201,864.9h1.1v8.5
		H201V864.9z"
          />
          <path
            className="st0"
            d="M204.4,861.6h1.1v6.7l3.9-3.4h1.6l-4.7,4.1l4.9,4.4h-1.5l-4.3-3.8v3.8h-1.1v-11.8H204.4z"
          />
        </g>
        <g className="st3">
          <path
            className="st0"
            d="M119.5,882.2v-1.1h6.1l-4.8,10.4h4.6v1.1H119l4.8-10.4H119.5z"
          />
          <path
            className="st0"
            d="M135.9,884.1v8.5h-1.1v-1.5c-0.5,0.6-1,1-1.5,1.3s-1.2,0.4-1.9,0.4c-1.2,0-2.2-0.4-3.1-1.3s-1.3-1.9-1.3-3.2
		c0-1.2,0.4-2.3,1.3-3.1c0.9-0.8,1.9-1.3,3.1-1.3c0.7,0,1.3,0.1,1.9,0.4c0.6,0.3,1.1,0.7,1.5,1.3V884L135.9,884.1L135.9,884.1z
		 M131.6,884.9c-0.6,0-1.2,0.1-1.7,0.4c-0.5,0.3-0.9,0.7-1.2,1.3s-0.5,1.1-0.5,1.7c0,0.6,0.2,1.2,0.5,1.7s0.7,1,1.2,1.3
		s1.1,0.5,1.7,0.5c0.6,0,1.2-0.2,1.7-0.5s0.9-0.7,1.2-1.2s0.4-1.1,0.4-1.7c0-1-0.3-1.8-1-2.5C133.3,885.3,132.5,884.9,131.6,884.9z"
          />
          <path
            className="st0"
            d="M146.9,880.8v11.8h-1.1v-1.5c-0.5,0.6-1,1-1.5,1.3s-1.2,0.4-1.9,0.4c-1.2,0-2.2-0.4-3.1-1.3s-1.3-1.9-1.3-3.2
		c0-1.2,0.4-2.3,1.3-3.1s1.9-1.3,3.1-1.3c0.7,0,1.3,0.1,1.9,0.4c0.6,0.3,1.1,0.7,1.5,1.3v-4.8L146.9,880.8L146.9,880.8z
		 M142.5,884.9c-0.6,0-1.2,0.1-1.7,0.4c-0.5,0.3-0.9,0.7-1.2,1.3s-0.5,1.1-0.5,1.7c0,0.6,0.2,1.2,0.5,1.7s0.7,1,1.2,1.3
		s1.1,0.5,1.7,0.5c0.6,0,1.2-0.2,1.7-0.5s0.9-0.7,1.2-1.2s0.4-1.1,0.4-1.7c0-1-0.3-1.8-1-2.5C144.2,885.3,143.5,884.9,142.5,884.9z"
          />
          <path
            className="st0"
            d="M153.4,883.9c1.3,0,2.4,0.5,3.3,1.4c0.8,0.9,1.2,1.9,1.2,3.1c0,1.2-0.4,2.2-1.2,3.1c-0.8,0.9-1.9,1.3-3.2,1.3
		s-2.4-0.4-3.2-1.3c-0.8-0.9-1.2-1.9-1.2-3.1c0-1.2,0.4-2.2,1.2-3.1C151,884.3,152.1,883.9,153.4,883.9z M153.4,884.9
		c-0.9,0-1.7,0.3-2.3,1s-1,1.5-1,2.4c0,0.6,0.1,1.2,0.4,1.7s0.7,0.9,1.2,1.2s1.1,0.4,1.7,0.4s1.2-0.1,1.7-0.4s0.9-0.7,1.2-1.2
		s0.4-1.1,0.4-1.7c0-1-0.3-1.8-1-2.4C155.1,885.3,154.3,884.9,153.4,884.9z"
          />
          <path
            className="st0"
            d="M158.7,884.1h1.1l2.6,6.2l2.7-6.2h0.2l2.7,6.2l2.7-6.2h1.1l-3.7,8.5h-0.2l-2.7-6.1l-2.7,6.1h-0.2L158.7,884.1z
		"
          />
          <path
            className="st0"
            d="M177.1,883.9c1.3,0,2.4,0.5,3.3,1.4c0.8,0.9,1.2,1.9,1.2,3.1c0,1.2-0.4,2.2-1.2,3.1c-0.8,0.9-1.9,1.3-3.2,1.3
		s-2.4-0.4-3.2-1.3c-0.8-0.9-1.2-1.9-1.2-3.1c0-1.2,0.4-2.2,1.2-3.1C174.7,884.3,175.8,883.9,177.1,883.9z M177.1,884.9
		c-0.9,0-1.7,0.3-2.3,1s-1,1.5-1,2.4c0,0.6,0.1,1.2,0.4,1.7s0.7,0.9,1.2,1.2s1.1,0.4,1.7,0.4s1.2-0.1,1.7-0.4s0.9-0.7,1.2-1.2
		s0.4-1.1,0.4-1.7c0-1-0.3-1.8-1-2.4C178.8,885.3,178.1,884.9,177.1,884.9z"
          />
          <path className="st0" d="M183.4,880.8h1.1v11.8h-1.1V880.8z" />
          <path
            className="st0"
            d="M190.8,883.9c1.3,0,2.4,0.5,3.3,1.4c0.8,0.9,1.2,1.9,1.2,3.1c0,1.2-0.4,2.2-1.2,3.1s-1.9,1.3-3.2,1.3
		s-2.4-0.4-3.2-1.3s-1.2-1.9-1.2-3.1c0-1.2,0.4-2.2,1.2-3.1C188.4,884.3,189.5,883.9,190.8,883.9z M190.8,884.9
		c-0.9,0-1.7,0.3-2.3,1s-1,1.5-1,2.4c0,0.6,0.1,1.2,0.4,1.7s0.7,0.9,1.2,1.2s1.1,0.4,1.7,0.4s1.2-0.1,1.7-0.4s0.9-0.7,1.2-1.2
		s0.4-1.1,0.4-1.7c0-1-0.3-1.8-1-2.4C192.5,885.3,191.7,884.9,190.8,884.9z"
          />
          <path
            className="st0"
            d="M197.3,884.1h1.1v1.5c0.4-0.6,0.9-1,1.5-1.3s1.1-0.4,1.7-0.4c0.6,0,1.2,0.2,1.7,0.5s0.9,0.8,1.1,1.3
		s0.4,1.4,0.4,2.6v4.4h-1.1v-4.1c0-1,0-1.6-0.1-2c-0.1-0.6-0.4-1-0.7-1.3s-0.8-0.4-1.4-0.4c-0.7,0-1.3,0.2-1.8,0.7s-0.9,1-1,1.6
		c-0.1,0.4-0.2,1.2-0.2,2.3v3.1h-1.1L197.3,884.1L197.3,884.1z"
          />
          <path
            className="st0"
            d="M206.1,884.1h1.2l2.9,6.5l2.8-6.5h1.2l-5.1,11.6H208l1.6-3.8L206.1,884.1z"
          />
          <path
            className="st0"
            d="M220,880.8h1.1v6.7l3.9-3.4h1.6l-4.7,4.1l4.9,4.4h-1.5l-4.3-3.8v3.8h-1V880.8z"
          />
          <path className="st0" d="M227.9,880.8h1.1v11.8h-1.1V880.8z" />
          <path
            className="st0"
            d="M231.7,880.6c0.2,0,0.5,0.1,0.6,0.3c0.2,0.2,0.3,0.4,0.3,0.6c0,0.2-0.1,0.5-0.3,0.6c-0.2,0.2-0.4,0.3-0.6,0.3
		c-0.2,0-0.5-0.1-0.6-0.3c-0.2-0.2-0.3-0.4-0.3-0.6c0-0.3,0.1-0.5,0.3-0.6C231.2,880.7,231.4,880.6,231.7,880.6z M231.1,884.1h1.1
		v8.5h-1.1V884.1z"
          />
          <path
            className="st0"
            d="M241.6,889.8l0.9,0.5c-0.3,0.6-0.7,1.1-1,1.4s-0.8,0.6-1.3,0.8s-1.1,0.3-1.7,0.3c-1.4,0-2.5-0.5-3.3-1.4
		s-1.2-1.9-1.2-3.1c0-1.1,0.3-2,1-2.9c0.8-1.1,2-1.6,3.4-1.6s2.6,0.5,3.5,1.6c0.6,0.8,0.9,1.7,0.9,2.9h-7.6c0,1,0.3,1.8,0.9,2.4
		c0.6,0.6,1.4,0.9,2.3,0.9c0.4,0,0.9-0.1,1.3-0.2c0.4-0.2,0.8-0.4,1-0.6S241.3,890.3,241.6,889.8z M241.6,887.5
		c-0.1-0.6-0.4-1-0.6-1.4c-0.3-0.3-0.6-0.6-1.1-0.8s-0.9-0.3-1.4-0.3c-0.8,0-1.6,0.3-2.2,0.8c-0.4,0.4-0.8,1-1,1.8L241.6,887.5
		L241.6,887.5z"
          />
          <path
            className="st0"
            d="M244.9,884.1h1.1v1.5c0.4-0.6,0.9-1,1.5-1.3s1.1-0.4,1.7-0.4c0.6,0,1.2,0.2,1.7,0.5s0.9,0.8,1.1,1.3
		s0.4,1.4,0.4,2.6v4.4h-1.1v-4.1c0-1,0-1.6-0.1-2c-0.1-0.6-0.4-1-0.7-1.3s-0.8-0.4-1.4-0.4c-0.7,0-1.3,0.2-1.8,0.7s-0.9,1-1,1.6
		c-0.1,0.4-0.2,1.2-0.2,2.3v3.1H245L244.9,884.1L244.9,884.1z"
          />
          <path
            className="st0"
            d="M255.6,880.9h1.1v3.2h1.7v0.9h-1.7v7.6h-1.1V885h-1.5v-0.9h1.5V880.9z"
          />
        </g>
      </g>
    </Svg>
  );
};

export default cooperationMobile;
