import styled from 'styled-components';

export const Svg = styled.svg`
  width: 100%;
  height: 100%;

  @media (min-width: 850px) {
    display: none;
  }

  .st0 {
    fill: #ffffff;
  }
  .st1 {
    fill: transparent;
  }
  .st2 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #ffffff;
  }
  .st3 {
    enable-background: new;
  }
  .st4 {
    fill: #7c7c7c;
  }
  .st5 {
    enable-background: new 0 0 375 956.8;
  }
  .st6 {
    opacity: 0;
  }
`;
