import React from 'react';

import Layout from 'components/layout/layout';
import Animation from 'templates/aboutUsSection/animation/animation';
import Cooperation from 'templates/aboutUsSection/cooperation/cooperation';
import FooterMobile from 'components/footerMobile/footerMobile';
import SEO from 'components/SEO/SEO';

const aboutUs = () => (
  <Layout>
    <SEO title="O-nas" />
    <Animation />
    <Cooperation />
    <FooterMobile />
  </Layout>
);

export default aboutUs;
