import React, { createRef, useEffect } from 'react';
import gsap from 'gsap';
import { Svg } from './svgAboutUs.styled';

const SvgAboutUs = () => {
  const hexagonRef = createRef();
  const hexagonTextRef = createRef();
  const backgroundRef = createRef();
  const firstFlatRef = createRef();
  const secondFlatRef = createRef();
  const firstTabletRef = createRef();
  const secondTabletRef = createRef();
  const firstPhoneRef = createRef();
  const secondPhoneRef = createRef();
  const peopleRef = createRef();
  const groupPeopleRef = createRef();
  const dimmerRef = createRef();
  const lineRef = createRef();
  const hairRef = createRef();
  const shirtRef = createRef();

  const tl = gsap.timeline();

  const tablet = gsap.timeline({
    paused: true,
    repeat: -1,
    yoyo: true,
  });

  const phone = gsap.timeline({
    paused: true,
    repeat: -1,
    yoyo: true,
  });

  function onTabletComplete() {
    tablet.play();
  }

  function onPhoneComplete() {
    phone.play();
  }

  useEffect(() => {
    tablet.to(firstTabletRef.current, 10, { y: -20 });
    phone.to(firstPhoneRef.current, 10, { y: -20 });
    tl.set(
      [
        hexagonRef.current,
        hexagonTextRef.current,
        backgroundRef.current,
        firstFlatRef.current,
        secondFlatRef.current,
        firstTabletRef.current,
        secondTabletRef.current,
        firstPhoneRef.current,
        secondPhoneRef.current,
        peopleRef.current,
        groupPeopleRef.current,
        dimmerRef.current,
        lineRef.current,
      ],
      { opacity: 0 }
    )
      .to(backgroundRef.current, 0.25, { opacity: 1 })
      .to(secondFlatRef.current, 0.25, { opacity: 1 })
      .to(firstTabletRef.current, 0.25, { opacity: 1 })
      .set(groupPeopleRef.current, { opacity: 1 })
      .staggerFrom(groupPeopleRef.current.children, 1, { opacity: 0 }, 0.25)
      .set(
        dimmerRef.current,
        {
          opacity: 1,
          scale: 0,
          transformOrigin: 'center center',
        },
        '-=1.5'
      )
      .to(
        dimmerRef.current,
        0.25,
        {
          scale: 1,
          transformOrigin: 'center center',
        },
        '-=1.5'
      )
      .to(secondTabletRef.current, 0.25, { opacity: 1, onTabletComplete })
      .staggerFrom(secondTabletRef.current.children, 0.5, { opacity: 0 }, 0.25)
      .set(hexagonRef.current, {
        opacity: 1,
        scale: 0,
        transformOrigin: 'center center',
      })
      .to(hexagonRef.current, 0.5, {
        scale: 1,
        transformOrigin: 'center center',
      })
      .set(hexagonTextRef.current, { opacity: 1 })
      .staggerFrom(hexagonTextRef.current.children, 0.5, { opacity: 0 }, 0.5)
      .to(firstFlatRef.current, 0.25, { opacity: 1 })
      .to(firstPhoneRef.current, 0.25, { opacity: 1, onPhoneComplete })
      .set(peopleRef.current, { opacity: 1 })
      .staggerFrom(peopleRef.current.children, 0.5, { opacity: 0 }, 0.5)
      .set(secondPhoneRef.current, { opacity: 1 })
      .staggerFrom(secondPhoneRef.current.children, 0.25, { opacity: 0 }, 0.25)
      .to(lineRef.current, 0.25, { opacity: 1 });
  });

  return (
    <Svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 483.32 536.42"
      className="st50"
    >
      <polygon
        id="Tlo_1"
        className="st0"
        points="40.47,190.18 40.47,405.95 227.33,513.83 414.19,405.95 414.19,190.18 227.33,82.29 "
        ref={backgroundRef}
      />
      <g id="podloga_1" className="st1" ref={firstFlatRef}>
        <path
          className="st2"
          d="M400.01,375.73l-72.33-41.76c-2.39-1.38-6.24-1.38-8.61,0l-71.86,41.76c-2.37,1.38-2.36,3.61,0.03,4.99
		l72.33,41.76c2.39,1.38,6.24,1.38,8.61,0l71.86-41.76C402.41,379.34,402.4,377.11,400.01,375.73z"
        />
      </g>
      <g id="podloga_2" className="st1" ref={secondFlatRef}>
        <path
          className="st2"
          d="M333.98,383.31l-112.21-64.78c-3.7-2.14-9.68-2.14-13.35,0L96.94,383.31c-3.68,2.14-3.66,5.6,0.04,7.73
		l112.21,64.78c3.7,2.14,9.68,2.14,13.35,0l111.48-64.78C337.7,388.91,337.68,385.45,333.98,383.31z"
        />
      </g>
      <g id="ludzie_1a" ref={firstTabletRef}>
        <g>
          <g>
            <g className="st3">
              <g>
                <path
                  className="st4"
                  d="M89.97,274.72c-0.68,0.39-1.23,1.34-1.22,2.12l0.05,19.15c0,0.78,0.55,1.1,1.23,0.71s1.23-1.34,1.22-2.13
						l-0.05-19.15C91.2,274.64,90.65,274.33,89.97,274.72z"
                />
              </g>
              <g>
                <path
                  className="st5"
                  d="M202.38,169.9L88.2,235.82c-2.07,1.19-3.74,4.09-3.73,6.46l0.25,87.75c0.01,2.38,1.69,3.34,3.75,2.14
						l114.17-65.92c2.07-1.19,3.74-4.09,3.73-6.46l-0.25-87.75C206.13,169.67,204.44,168.71,202.38,169.9z M89.96,269.38
						c0.68-0.39,1.23-0.07,1.23,0.71s-0.55,1.73-1.22,2.12c-0.68,0.39-1.23,0.08-1.23-0.7C88.73,270.72,89.28,269.77,89.96,269.38z
						 M90.03,296.7c-0.68,0.39-1.23,0.08-1.23-0.71l-0.05-19.15c0-0.78,0.55-1.73,1.22-2.12c0.68-0.39,1.23-0.08,1.23,0.7
						l0.05,19.15C91.26,295.36,90.71,296.31,90.03,296.7z M95.97,325.43l-0.26-91.53l99.18-57.26l0.26,91.53L95.97,325.43z"
                />
              </g>
              <g>
                <path
                  className="st4"
                  d="M89.96,269.38c-0.68,0.39-1.23,1.35-1.22,2.13c0,0.78,0.55,1.09,1.23,0.7c0.68-0.39,1.23-1.34,1.22-2.12
						C91.19,269.31,90.64,268.99,89.96,269.38z"
                />
              </g>
              <path
                className="st4"
                d="M197.49,167.06L83.32,232.98c-2.07,1.19-3.74,4.09-3.73,6.46l0.25,87.75c0,1.18,0.42,2.01,1.09,2.4
					l4.89,2.84c-0.67-0.39-1.09-1.22-1.09-2.4l-0.25-87.75c-0.01-2.37,1.66-5.27,3.73-6.46l114.17-65.92
					c1.04-0.6,1.98-0.66,2.66-0.26l-4.89-2.84C199.47,166.4,198.53,166.46,197.49,167.06z"
              />
              <polygon
                className="st6"
                points="95.71,233.9 95.97,325.43 195.15,268.17 194.89,176.64 				"
              />
            </g>
            <polygon
              className="st7"
              points="101.72,247.51 101.92,300.78 144.15,275.34 143.95,222.07 			"
            />
            <polygon
              className="st7"
              points="147.32,220.55 147.52,273.82 189.75,248.38 189.55,195.11 			"
            />
            <g>
              <path
                className="st2"
                d="M122.12,250.76c-1.47,1.69-6.59,3.13-6.86,3.35c0,0.03-0.06,3.41-0.06,4.29c0,1.62,0.22,4.16,1.41,5.52
					c0.05,0.06,1.09,1.14,2.67,1.48c0.16,0.03,0.33,0.06,0.5,0.08c0.66,0.06,1.38-0.03,2.13-0.4l0.42-0.24
					c1.03-0.68,2-1.82,2.83-2.99c0.12-0.17,0.23-0.33,0.34-0.5c1.27-1.91,2.06-3.67,2.1-3.76c0.89-2.02,1.17-4.41,1.17-6.64
					c-0.01-4.06-1.09-5.97-1.13-6.15C127.54,244.94,125.93,246.37,122.12,250.76z"
              />
            </g>
            <g>
              <path
                className="st8"
                d="M125.49,261.34c-0.11,0.17-0.22,0.33-0.34,0.5c-0.82,1.17-1.79,2.31-2.83,2.99l-0.42,0.24
					c-0.75,0.37-1.47,0.46-2.13,0.4c-0.17-0.02-0.34-0.04-0.5-0.08c-0.07,1.2-0.28,3.61-0.93,4.56c-0.07,0.1-1.55,2-3.11,3.8
					c0.03,0.04,1.71,1.76,7.18-1.4c6.15-3.55,7.31-6.85,7.32-6.88c-1.62,0.01-3.21-0.18-3.28-0.2
					C125.71,265.03,125.53,262.14,125.49,261.34z"
              />
            </g>
            <g>
              <path
                className="st4"
                d="M136.07,264.47l-3.79,0.6l-2.53,0.4c-0.01,0.03-1.17,3.33-7.32,6.88c-5.47,3.16-7.14,1.44-7.18,1.4
					l-4.45,3.94l-1.7,1.51c-1.45,1.37-2.65,3.23-3.33,5.19c-1.76,5.03-1.26,13.13-1.96,15.25c1.99-1.14,30.33-18.44,37.13-22.37
					c-0.41-0.83-0.49-7.66-1.78-10.86C138.61,265.06,137.5,264.36,136.07,264.47z"
              />
            </g>
            <g>
              <path
                className="st9"
                ref={hairRef}
                d="M130.47,241.42c-0.46-1.51-4.01-3.92-7.94-1.65c-1.05,0.61-2.3,1.52-3.78,2.84
					c-7.96,7.14-7.49,15.36-7.49,19.61c0,3.26-0.34,12.32-0.46,15.48l4.45-3.94c1.56-1.8,3.04-3.7,3.11-3.8
					c0.65-0.96,0.86-3.37,0.93-4.56c-1.58-0.34-2.62-1.42-2.67-1.48c-1.2-1.36-1.41-3.9-1.41-5.52c0-0.88,0.06-3.5,0.06-3.53
					c6-2.02,11.11-4.3,13.13-8.47c0.05,0.18,0.36,0.48,0.37,4.54c0,2.24-0.28,4.62-1.17,6.64c-0.04,0.09-0.83,1.85-2.1,3.76
					c0.03,0.8,0.22,3.69,0.98,3.94c0.07,0.02,1.66,0.21,3.28,0.2l2.53-0.4c-0.33-5.49-1.22-20.26-1.5-22.21
					C130.69,242.37,130.59,241.89,130.47,241.42z"
              />
            </g>
            <g>
              <g>
                <path
                  className="st2"
                  d="M175.29,222.37c-0.14,0.08-0.31,0.27-0.46,0.47c-0.06,0.4-0.13,0.78-0.2,1.14
						c-0.22,1.15-0.37,1.96-0.7,2.33c0.05-0.66,0.09-1.34,0.12-2.02c0.02-0.63,0.04-1.26,0.04-1.88c0-0.62-0.01-1.22-0.04-1.8
						c-0.11-2.28-0.84-2.33-1.64-1.88c-0.06,0.04-0.13,0.08-0.19,0.12c-0.04,0.03-0.08,0.05-0.12,0.08
						c-0.07,0.05-0.13,0.09-0.2,0.14c-0.79,0.59-3.47,2.7-3.92,2.99c-0.44,0.22-3.25,1.43-4.05,1.76c-0.09,0.04-0.18,0.07-0.26,0.11
						c-0.04,0.02-0.08,0.04-0.19,0.09c-0.03,0.02-0.06,0.03-0.09,0.05c-0.6,0.35-0.95,0.97-1.03,3.29
						c-0.04,0.98-0.05,1.82-0.05,2.59c0,0.3,0,0.59,0.01,0.87c0.02,0.91,0.06,1.75,0.13,2.62c-0.03-0.01-0.06-0.02-0.09-0.03
						c-0.53-0.3-0.79-1.59-0.96-2.96c-0.08,0.01-0.17,0.03-0.25,0.07c-0.25,0.15-0.44,0.53-0.36,1.41c0.23,2.58,0.92,3.5,1.77,3.01
						c0.07,1.13-0.01,1.38,1.11,2.6c0.02,0.03,0.05,0.06,0.09,0.06c0.03,0.02,0.61,0.41,0.85,0.6c0.23,0.14,0.49,0.29,0.76,0.45
						c0.47,0.23,0.98,0.44,1.2,0.45c0.31,0.02,0.92-0.19,1.57-0.57c0.59-0.34,1.2-0.81,1.64-1.34c0.36-0.43,2.47-3.91,2.47-3.91
						c0.05-0.11,0.1-0.21,0.13-0.28c0.75-1.55,1.04-2.62,1.19-3.52c0.05-0.32,0.1-0.66,0.15-1.02c0.08-0.02,0.15-0.06,0.23-0.1
						c0.82-0.48,1.51-2.21,1.72-5.04C175.74,222.39,175.55,222.22,175.29,222.37z"
                />
              </g>
              <g>
                <path
                  className="st4"
                  d="M167.84,215.44c-0.19,0.1-0.38,0.2-0.55,0.3c-3.92,2.26-4.47,5.36-4.49,5.53
						c-0.12,0.13-2.4,2.55-1.76,6.11c0.13,0.73,0.22,1.94,0.36,3.09c0.17,1.37,0.43,2.65,0.96,2.96c0.03,0.01,0.06,0.02,0.09,0.03
						c-0.07-0.87-0.11-1.71-0.13-2.62c-0.01-0.28-0.01-0.57-0.01-0.87c0-0.77,0.01-1.61,0.05-2.59c0.08-2.33,0.44-2.95,1.03-3.29
						c0.03-0.02,0.06-0.03,0.16-0.08c0.04-0.02,0.08-0.04,0.12-0.06c0.08-0.04,0.17-0.07,0.26-0.11c0.79-0.33,3.6-1.53,4.05-1.76
						c0.44-0.29,3.13-2.4,3.92-2.99c0.06-0.05,0.13-0.1,0.2-0.14c0.04-0.03,0.08-0.06,0.12-0.08c0.06-0.04,0.13-0.08,0.23-0.14
						c0.77-0.43,1.5-0.38,1.61,1.9c0.03,0.58,0.04,1.18,0.04,1.8c0,0.62-0.01,1.25-0.04,1.88c-0.03,0.68-0.06,1.36-0.12,2.02
						c0.34-0.37,0.48-1.18,0.7-2.33c0.07-0.36,0.13-0.75,0.2-1.14c0.32-2.03,0.56-4.31,0.61-4.94
						C175.57,216.37,174.54,212.02,167.84,215.44z"
                />
              </g>
              <g>
                <path
                  className="st10"
                  ref={shirtRef}
                  d="M182.56,235.27c-2.75,0.5-7.76,1.19-8.51,1.16c-0.2,0.4-1.01,2.07-1.56,3.41
						c-0.07,0.16-0.18,0.41-0.31,0.7c-0.44,0.96-1.07,2.3-1.11,2.37l-1.82-0.79l-1.26-0.44l-0.96,1.52l-2,3.19
						c-0.06-0.05-1.13-1.06-1.43-1.43c-0.05-0.06-0.1-0.12-0.15-0.18c-0.51-0.62-1.2-1.23-1.48-1.43c-0.52,0.67-5.72,5.96-8.54,8.72
						c-1.6,1.56-2.84,3.77-3.32,5.92l0.07,14.21l37.22-22.42l-1.49-12.46C185.41,235.75,184.17,234.97,182.56,235.27z"
                />
              </g>
              <g>
                <path
                  className="st11"
                  d="M167.77,241.78c-0.14,0.06-0.29,0.1-0.43,0.14C167.53,241.86,167.67,241.81,167.77,241.78z"
                />
              </g>
              <g>
                <path
                  className="st2"
                  d="M172.25,233.31c0,0-2.12,3.48-2.47,3.91c-0.44,0.53-1.06,1-1.64,1.34c-0.65,0.37-1.26,0.59-1.57,0.57
						c-0.22-0.02-0.73-0.22-1.2-0.45c-0.3-0.15-0.58-0.31-0.76-0.45c-0.24-0.19-0.82-0.58-0.85-0.6c0,0.01,0,0.02,0,0.03
						c-0.06,0.86-0.1,1.8-0.12,2.04c-0.03,0.33-0.07,0.66-0.12,0.97c0.58,0.56,2.05,1.71,3.83,1.26c0.18-0.06,0.33-0.11,0.43-0.14
						c0.05-0.02,0.1-0.04,0.15-0.06c0.11-0.09,0.52-0.43,1.09-0.9c1.6-1.63,2.93-4.14,3.5-5.32c-0.06-0.26-0.11-0.54-0.13-0.86
						C172.36,234.44,172.32,234.03,172.25,233.31z"
                />
              </g>
              <g className="st3">
                <g>
                  <path
                    className="st12"
                    d="M172.51,235.5c-0.57,1.17-1.9,3.69-3.5,5.32c-0.33,0.33-0.67,0.63-1.02,0.87l1.26,0.44l1.82,0.79
							c0.03-0.07,0.67-1.41,1.11-2.37c0.13-0.29,0.24-0.54,0.31-0.7c0.55-1.34,1.37-3.01,1.56-3.41c0.03-0.06,0.04-0.09,0.04-0.09
							l-1.28-1.48C172.8,234.9,172.69,235.13,172.51,235.5z"
                  />
                </g>
                <g>
                  <path
                    className="st12"
                    d="M167.77,241.78c-0.14,0.06-0.29,0.1-0.43,0.14c-1.78,0.45-3.25-0.71-3.83-1.26
							c-0.17-0.17-0.26-0.27-0.27-0.28l-0.07,0.16l-1.19,2.8c0,0,0.02,0.02,0.06,0.05c0.21,0.18,0.9,0.79,1.41,1.41
							c0.05,0.06,0.1,0.12,0.15,0.18c0.29,0.37,1.37,1.37,1.43,1.43l2-3.19l0.96-1.52c-0.03,0.01-0.05,0.02-0.08,0.03
							C167.87,241.74,167.82,241.76,167.77,241.78z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <g>
                    <g>
                      <g>
                        <path
                          className="st13"
                          d="M165.44,227.4c-2.24,0.91-3.13,1.6-2.82,3.11c0.3,1.48,0.92,2.2,2.88,1.09
										c0.85-0.48,2.47-1.9,2.43-4.09C167.92,226.8,167.64,226.5,165.44,227.4z"
                        />
                      </g>
                      <g>
                        <path
                          className="st13"
                          d="M171.18,224.55c-1.97,1.16-2.29,1.77-2.29,2.48c0.02,2.21,1.57,2.14,2.33,1.87
										c1.73-0.61,2.18-1.84,2.37-3.66C173.8,223.4,173.12,223.4,171.18,224.55z"
                        />
                      </g>
                      <path
                        className="st4"
                        d="M173.93,223.16C173.93,223.15,173.93,223.15,173.93,223.16c0.01-0.01-0.89,0.18-2,0.8
									c0.01,0,0.01,0,0.02,0c-0.16,0.08-0.33,0.18-0.51,0.28c-0.08,0.05-0.17,0.1-0.25,0.15c-0.78,0.46-1.35,0.85-1.74,1.23
									c-0.06,0.05-0.13,0.12-0.2,0.19c-0.23,0.23-0.59,0.57-0.81,0.68c-0.21,0.1-0.63,0.14-0.87,0.15c-0.09,0-0.16,0.01-0.23,0.02
									c-0.41,0.02-1.02,0.23-1.89,0.58c-0.1,0.04-0.19,0.08-0.29,0.12c-0.19,0.08-0.37,0.16-0.54,0.24c0.01,0,0.01-0.01,0.01-0.01
									c-1.32,0.57-2.46,1.33-2.46,1.33c-0.07,0.03-0.14,0.1-0.14,0.18c-0.01,0.16-0.01,0.24-0.03,0.4c0,0.07,0.05,0.11,0.12,0.08
									c0.02-0.01,0.04-0.01,0.06-0.02c0,0,0.18-0.01,0.19,0.26c0,0.07,0.01,0.11,0.01,0.14c-0.01,0.21,0.01,0.43,0.06,0.67
									c0.27,1.32,0.8,2.1,2.34,1.5c0.23-0.09,0.48-0.21,0.74-0.36c1.14-0.64,2.4-2.04,2.55-3.85c0,0.01,0,0.01,0.01,0.02
									c0-0.04,0-0.08,0-0.12c0-0.03,0-0.06,0-0.08c0.03-0.51,0.24-0.66,0.33-0.7l0,0c0,0,0.01-0.01,0.02-0.01
									c0.01-0.01,0.02-0.01,0.02-0.01l0,0c0.08-0.04,0.24-0.07,0.29,0.32c0,0.07,0.01,0.14,0.02,0.2c0,0.02,0,0.04,0,0.07
									c0-0.01,0-0.01,0-0.02c0.19,1.64,1.41,1.87,2.44,1.51c0.24-0.08,0.46-0.18,0.65-0.29c1.32-0.73,1.71-1.97,1.88-3.6
									c0.03-0.31,0.04-0.57,0.02-0.79c0-0.03,0-0.07,0-0.12c-0.01-0.28,0.13-0.43,0.13-0.43c0.02-0.01,0.03-0.02,0.05-0.03
									c0.06-0.04,0.1-0.12,0.09-0.2c-0.01-0.16-0.02-0.24-0.03-0.4C174,223.18,173.97,223.15,173.93,223.16z M165.47,231.44
									c-0.25,0.14-0.47,0.25-0.68,0.33c-1.32,0.52-1.75-0.12-2-1.36c-0.11-0.56-0.06-0.98,0.17-1.33c0.31-0.48,1-0.89,2.18-1.39
									c0.09-0.04,0.19-0.08,0.28-0.12c2.19-0.89,2.34-0.55,2.34,0.01C167.81,229.93,165.87,231.21,165.47,231.44z M173.46,225.28
									c-0.16,1.53-0.49,2.54-1.62,3.17c-0.18,0.1-0.38,0.19-0.6,0.27c-0.36,0.13-2.17,0.61-2.2-1.76
									c-0.01-0.56,0.18-1.08,2.14-2.23c0.09-0.05,0.17-0.1,0.25-0.14c1.02-0.59,1.58-0.76,1.84-0.53
									C173.48,224.21,173.54,224.6,173.46,225.28z"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>

          <linearGradient
            id="SVGID_1_"
            gradientUnits="userSpaceOnUse"
            x1="126.8662"
            y1="287.3444"
            x2="146.8662"
            y2="210.6777"
            gradientTransform="matrix(1 0 0 -1 0 538)"
          >
            <stop offset="0" className="st51" />
            <stop offset="1" className="st52" />
          </linearGradient>
          <polygon
            className="st14"
            points="80.41,355.72 86.51,358.76 210.29,287.3 204.19,284.26 		"
          />
          <g>
            <g>
              <path
                className="st54"
                d="M161.08,270.29c-1.95,1.12-3.52,3.84-3.51,6.08c0.01,2.24,1.59,3.14,3.54,2.01
					c1.95-1.12,3.51-3.85,3.51-6.08C164.61,270.06,163.03,269.16,161.08,270.29z"
              />
            </g>
            <g>
              <path
                className="st15"
                d="M170.11,265.07c-1.95,1.12-3.52,3.84-3.51,6.08c0.01,2.24,1.59,3.14,3.54,2.01
					c1.95-1.12,3.51-3.85,3.51-6.08C173.64,264.85,172.06,263.95,170.11,265.07z"
              />
            </g>
            <g>
              <path
                className="st9"
                d="M179.14,259.86c-1.95,1.12-3.52,3.84-3.51,6.08c0.01,2.24,1.59,3.14,3.54,2.01
					c1.95-1.12,3.51-3.85,3.51-6.08C182.67,259.63,181.09,258.73,179.14,259.86z"
              />
            </g>
          </g>
        </g>
      </g>
      <g ref={groupPeopleRef}>
        <g>
          <path
            className="st16"
            d="M225.9,333.62c-3.52-0.15-8.49,1.39-11.58,3.1c-5.14,2.83,1.04,3.69,4.12,3.79
			c4.07,0.14,5.53,1.52,12.13-1.39C235.27,337.07,230.5,333.75,225.9,333.62z"
          />
          <g>
            <g>
              <g>
                <path
                  className="st4"
                  d="M219.56,336.89c0,0-3.11,0.48-5.33,0.49c-1.51,0-2.58,1.74-1.28,2.86l7.97,1.75c0,0,2.46,0.66,3.26-1.29
						l-0.2-2.91L219.56,336.89z"
                />
                <path
                  className="st4"
                  d="M228.69,332.29c0,0-2.77,0.28-4.88,0.29c-1.44,0-2.56,2.19-1.23,2.73l6.6,2.1c0,0,2.74,0.77,3.5-1.09
						l-0.18-2.83L228.69,332.29z"
                />
                <path
                  className="st5"
                  d="M232.65,289.48c-0.5,0.28-12.48,6.35-14.48,2.96l-1.54,23.36l2.59,22.1c0.65,2.27,4.8,0.5,4.8,0.5
						l-1.36-22.76l3.24-10.7l0.28,11.63l1.65,16.16c0.05,0.93,1.02,1.45,1.94,1.59c2.51,0.39,2.89-0.65,2.89-0.65l-0.13-17.01
						c0,0,0.77-8.02,1.15-12.9c0.32-4.06,0.61-3.82,0.44-6.25C233.98,295.49,232.65,289.48,232.65,289.48z"
                />
                <path
                  className="st17"
                  d="M225.9,304.93l0.28,11.63l1.65,16.16c0.05,0.93,1.02,1.45,1.94,1.59c0.35,0.05,0.66,0.08,0.93,0.08
						c-1.39-5.15-2.65-25.06-4.27-28.79C226.12,305.18,225.9,304.93,225.9,304.93z"
                />
                <path
                  className="st18"
                  d="M218.17,292.44l-1.54,23.36l2.59,22.1c0.23,0.79,0.88,1.09,1.64,1.14c-0.55-8.29-2.39-16.42-2.8-24.77
						c-0.46-9.15,2.58-17.91,10.85-22.65c1.44-0.83,2.84-0.94,4.07-0.58c-0.19-0.92-0.33-1.55-0.33-1.55
						C232.16,289.77,220.17,295.83,218.17,292.44z"
                />
              </g>
              <path
                className="st4"
                d="M231.88,272.06l-0.4,16.55c0,0,4.64-3.16,4.64-5.54c0.02-10.19-1.06-15.56-1.06-15.56L231.88,272.06z"
              />
              <path
                className="st17"
                d="M231.88,272.06l-0.4,16.55c0,0,1.43-0.98,2.71-2.26c1.68-5.31,1.26-11.55-0.77-16.5L231.88,272.06z"
              />
              <path
                className="st4"
                d="M230.81,263.85c0,0-4.67-2.01-8.43,0.05c-1.6,0.87-5.37,2.65-5.97,3.89c-0.55,1.13,0.23,3.9,0.7,6.15
					c0,0,0.54,6.12,0.94,12.31c0.28,4.29-0.49,10.04-0.49,10.04s2.95,1.64,8.87,0.33c5.92-1.3,7.54-2.03,7.54-2.03l-1.62-10.86
					c0,0,1.87-11.71,1.71-12.52C233.91,270.41,238.08,266.73,230.81,263.85z"
              />
              <path
                className="st17"
                d="M220.1,274.57c0.03-0.17,0.07-0.33,0.11-0.49c-0.02-0.45,0-0.91,0.02-1.37c-0.67,0.63-1.38,1.23-1.94,2.02
					c-0.29,0.41-0.66,0.66-1.05,0.78c0.19,2.21,0.53,6.45,0.81,10.73c0.28,4.29-0.49,10.04-0.49,10.04s1.14,0.62,3.41,0.79
					c-0.28-1.7-0.47-3.42-0.62-5.13C219.84,286.16,219.16,280.35,220.1,274.57z"
              />
              <path
                className="st19"
                d="M211.23,296.64l-2,3.28l-0.27,1.86c-0.16,1.14,0.67,2.17,1.81,2.26c0.21,0.02,0.43,0.02,0.66,0l1.71-1.18
					c0,0-0.06-0.33-0.54-0.55c0,0,1.1-2.18,0.74-2.83l1.15-2.63L211.23,296.64z"
              />
              <g>
                <path
                  className="st19"
                  d="M222.37,258.33l0.28,5.52c-0.02,1.66,5.63,1.11,5.65-0.55l-0.38-4.9L222.37,258.33z"
                />
                <path
                  className="st2"
                  d="M228.26,262.25c-0.5,1.57-3.93,1.43-5.67,0.75l-0.63,1.26c1.05,1.54,4.73,1.65,6.34-0.04
						c0.37-0.39,0.44-0.99,0.22-1.49L228.26,262.25z"
                />
                <path
                  className="st19"
                  d="M220.35,258.99c-0.03,2.55,1.72,3.69,4.14,3.72c0,0,4.5-1.34,4.53-3.84l0.56-4.64l-9.15-1.67
						L220.35,258.99z"
                />
                <path
                  className="st4"
                  d="M221.35,249.36c-4.11,2.05-1.79,4.41-0.94,5.04c0,0,1.23,0.32,1.21,2.28c-0.02,1.95,2.01,3.94,3.53,5.16
						c1.1,0.89,3.03-0.54,3.03-0.54s3-3.32,1.59-9.06C228.92,248.73,222.35,248.86,221.35,249.36z"
                />
                <path
                  className="st19"
                  d="M221.34,256.77l-0.03,2.12c0.88,0.42,1.63-0.78,1.5-1.89C222.65,255.52,221.34,256.77,221.34,256.77z"
                />
              </g>
              <path
                className="st4"
                d="M216.4,267.8c-0.84,0.64-0.84,11.07-1.79,15.96c-1.54,4.8-3.78,13.16-3.78,13.16s0.56,1.69,2.11,2.02
					c1.44,0.3,1.05,0.15,1.05,0.15s3.25-7.55,5.72-12.66c0.2-0.04,0.97-1.52,1.21-2.52c0.38-0.8,0.42-1.46,0.31-1.5
					C222.37,269.12,219.37,265.54,216.4,267.8z"
              />
              <path
                className="st17"
                d="M221.42,274.83c-0.36,2.24-1.22,4.55-1.99,7.03c-1.85,5.95-4.06,11.18-8.03,16.07
					c0.34,0.43,0.83,0.87,1.54,1.02c1.44,0.3,1.05,0.15,1.05,0.15s3.25-7.55,5.72-12.66c0.2-0.04,0.97-1.52,1.21-2.52
					c0.38-0.8,0.42-1.46,0.31-1.5C221.5,279.39,221.54,276.88,221.42,274.83z"
              />
            </g>
          </g>
        </g>
        <g>
          <path
            className="st16"
            d="M161.8,364.09c-2.41-1.29-6.82-0.13-8.64-0.12c-2.03,0-7.13-0.23-8.18,2.18c-1.22,2.8,3.98,2.17,5.55,2.33
			c3.46,0.35,6.6-0.24,10.07-0.79C163.03,367.31,164.92,365.76,161.8,364.09z"
          />
          <g>
            <path
              className="st9"
              d="M138.9,299.55c-0.48,2.91-1.57,10.05-1.57,10.53s0.36,3.51,2.06,3.75s5.09-0.61,5.09-0.61l0.85-6.18
				L141,293.33C141,293.33,139.39,296.64,138.9,299.55z"
            />
            <path
              className="st17"
              d="M142.41,301.42c-0.07-0.02-0.13-0.04-0.18-0.06c-0.93,2.16-1.4,4.75-1.79,7c-0.32,1.85-0.45,3.7-0.4,5.52
				c1.79,0.01,4.43-0.65,4.43-0.65l0.85-6.18l-1.68-5.32C143.24,301.69,142.83,301.59,142.41,301.42z"
            />
          </g>
          <g>
            <path
              className="st4"
              d="M159.28,363.95l-4,0.97l0.05,3.24c0.61,2.06,3.75,0.36,3.75,0.36s2.34-1.85,2.74-2.88
				C162.42,364.07,159.28,363.95,159.28,363.95z"
            />
            <path
              className="st4"
              d="M147.11,363.1l-3.45,0.73l-0.48,3.57c1.09,1.82,3.33,0.61,3.33,0.61s1-1.09,1.88-1.57
				c1.21-0.67,1.77-1.15,1.94-1.57C150.92,363.29,147.11,363.1,147.11,363.1z"
            />
            <path
              className="st20"
              d="M159.4,319.76c-0.19,0.04-11.4,0.25-16.3-1.16c-0.13,1.12-0.25,2.19-0.3,2.87
				c-0.18,2.47,0.51,23.32,0.51,23.32l0.17,19.51c0,0,0.51,1.13,2.56,0.85c0.94-0.13,1.95-0.75,2-1.71l1.67-17.13l1.1-15.77
				c0,0,1.86,8.49,2.47,16.38c0.33,4.3,1.74,18.32,1.74,18.32s1.1,1.16,3.3,0.56c1.37-0.37,1.71-1.07,1.74-1.79l-0.05-19.05
				L159.4,319.76z"
            />
            <path
              className="st4"
              d="M157.32,347.48c0.1-9.35-0.35-18.14-3.54-27.02c-0.09-0.24-0.14-0.48-0.18-0.72
				c-3.56-0.11-7.92-0.4-10.51-1.15c-0.13,1.12-0.25,2.19-0.3,2.87c-0.18,2.47,0.51,23.32,0.51,23.32l0.17,19.51
				c0,0,0.51,1.13,2.56,0.85c0.94-0.13,4.77-34.6,4.77-34.6s1.86,8.49,2.47,16.38c0.33,4.3,1.74,18.32,1.74,18.32
				s0.78,0.81,2.33,0.72C157.51,359.8,157.25,353.63,157.32,347.48z"
            />
            <path
              className="st9"
              d="M157.7,299.54l-0.73-6.78c0,0-2-1.71-6.66-2.06c-3.51-0.26-6.56,0.88-7.98,1.53
				c-0.63,0.29-1.11,0.83-1.32,1.49c-0.26,0.81-0.49,2.1-0.3,3.89c0.29,2.74,2.42,11.31,2.54,13.68c0.13,2.37-0.45,9.55-0.45,9.55
				s1.33,2.62,9.93,2.5c3.51-0.05,6.89-2.46,6.89-2.46l-1.72-10.22L157.7,299.54z"
            />
            <path
              className="st17"
              d="M157.7,299.54l-0.19-1.75c-0.04,0.18-0.08,0.35-0.11,0.53c-0.32,1.75-0.5,3.54-0.88,5.28
				c-0.31,1.43-0.74,2.86-1.12,4.29c0.02,0.45,0.03,0.9,0.03,1.35c0.01,0.93,2.64,8.98,3.16,12.29c0.64-0.36,1.02-0.63,1.02-0.63
				l-1.72-10.22L157.7,299.54z"
            />
            <path
              className="st17"
              d="M144.05,310.15c-0.37-1.58-0.73-2.99-1.45-4.44c-0.18-0.37-0.36-0.74-0.54-1.11
				c0.58,2.78,1.14,5.51,1.21,6.69c0.13,2.37-0.45,9.55-0.45,9.55s0.39,0.77,2.12,1.45c-0.13-2.91-0.12-5.82-0.12-8.74
				C144.57,312.42,144.31,311.28,144.05,310.15z"
            />
            <path
              className="st21"
              d="M147.47,285.61c-0.15,2.41,2.71,3.85,2.71,3.85c2.32,0.15,3.91-0.65,4.06-3.1l0.39-6.17l-8.9,0.94
				L147.47,285.61z"
            />
            <path
              className="st21"
              d="M162.74,317.32l-1.95,4.61l-0.34,0.5c-0.26,0.38-0.39,0.84-0.38,1.3c0.02,0.64,0.1,1.59,0.38,2.44v1.94
				l-0.46,0.92c0,0-0.02,0.44,0.46,0.53c0.49,0.1,0.32,0,0.32,0l1.88-1.44l0.57-2.84l-0.56-3.08L162.74,317.32z"
            />
            <path
              className="st21"
              d="M147.77,287.79l-0.85,3.39c3.39,1.45,5.81-0.12,5.81-0.12l-0.73-3.15L147.77,287.79z"
            />
            <path
              className="st2"
              d="M147.38,289.68l-0.81,1.26c2.66,2.18,6.78,0.24,6.78,0.24l-0.82-1.32
				C151.99,290.05,149.25,290.65,147.38,289.68z"
            />
            <path
              className="st9"
              d="M160.24,296.87c-0.4-4.36-4.28-4.76-4.28-4.76l-0.08,6.58l0.73,10.41l3.6,13.37l2.78-0.4
				c0,0-1.18-10.91-1.42-12.85C161.33,307.29,160.78,302.69,160.24,296.87z"
            />
            <path
              className="st22"
              d="M151.29,276.59c-6.3,0.12-6.22,2.59-6.18,5.27c0.06,4.78,2.36,6.84,2.36,6.84s1.51,0.3,3.27,0.18
				c1.88-0.13,4.37-7.69,3.93-9.08C153.95,277.5,151.29,276.59,151.29,276.59z"
            />
            <path
              className="st21"
              d="M153.22,283.54c-0.04,1.06,0.39,2.18,0.86,1.74l0.23-1.73C154.31,283.55,153.27,282.1,153.22,283.54z"
            />
          </g>
        </g>
        <g>
          <path
            className="st23"
            d="M185.68,346.15c0,0-1.05-8.75-2.09-12.8l-3.4,1.39l2.48,12.05l-0.72,8.38l-0.96,2.29l0.09,2.94
			c0,0,0.2,0.08,0.38-0.13c-0.01,0.09-0.01,0.15-0.01,0.15l0.56,1.82c0,0,0.31,0.08,0.4-0.39l0.52,0.71
			c0.41-0.08,0.32-0.57,0.18-0.95l0.37,0.5c0.68-0.14-0.02-1.4-0.02-1.4l0.02-0.25l0.55,0.98c0.57,0.05,0.11-0.97,0.11-0.97
			l-0.36-1.02l-0.03-0.49c0.18-0.76,0.69-2.37,0.2-3.17C183.93,355.81,186.1,350.58,185.68,346.15z"
          />
          <path
            className="st2"
            d="M180.19,334.75l1.54,7.46c1.06-0.21,2.21-0.4,3.34-0.66c-0.4-2.75-0.94-6.1-1.48-8.19L180.19,334.75z"
          />
          <g>
            <path
              className="st16"
              d="M177.68,394.97c-2.48,0.34-17.98,0.07-14.31,5.42c1.74,2.55,9.43,1.67,11.85,1.46
				c1.37-0.12,8.84-1.49,9.13-2.68C184.96,396.72,178.56,395.61,177.68,394.97z"
            />
            <g>
              <path
                className="st2"
                d="M170.65,398.46c0,0-3.38,0.02-3.69,0c-0.26-0.02-2.75-0.38-1.83,1.2c0.19,0.33,0.78,0.68,1.44,1.01
					c0,0,4.36,1.44,4.77,1.47s2.12,0.23,2.47-1.02l-0.11-2.08L170.65,398.46z"
              />
              <path
                className="st9"
                d="M165.13,399.66c0.14,0.24,0.5,0.5,0.95,0.75c0-0.1,0-0.21,0.02-0.31c0.14-0.71,0.52-1.21,1.01-1.64
					c-0.07,0-0.12,0-0.15,0C166.7,398.44,164.21,398.08,165.13,399.66z"
              />
              <path
                className="st9"
                d="M171.92,400.67c-0.28,0.45-0.36,0.97-0.34,1.49c0.62,0.04,1.93,0.04,2.23-1.04l-0.09-1.64
					C173.01,399.72,172.31,400.05,171.92,400.67z"
              />
              <path
                className="st4"
                d="M172.62,401.62c-1.27,0.23-2.74-0.31-4.23-0.82c-1.71-0.59-3.35-1.21-3.38-1.73c0-0.08-0.02-0.14-0.03-0.23
					c-0.09,0.19-0.07,0.45,0.15,0.82c0.19,0.33,0.78,0.68,1.44,1.01c0,0,4.36,1.44,4.77,1.47s2.12,0.23,2.47-1.02l-0.02-0.35
					C173.45,401.11,173.16,401.52,172.62,401.62z"
              />
              <path
                className="st2"
                d="M181.43,395.32c0,0-2.23-0.34-2.52-0.43c-0.25-0.08-2.92-0.83-2.4,0.92c0.11,0.36,0.49,1.08,1.86,1.39
					c2.14,0.48,3.18,1.64,3.53,1.87c0.55,0.37,2.28,0.74,2.62-0.83l-0.24-1.49L181.43,395.32z"
              />
              <path
                className="st9"
                d="M176.5,395.81c0.08,0.26,0.3,0.69,0.9,1.04c0.01-0.09,0.03-0.18,0.06-0.28c0.25-0.73,0.63-1.31,1.1-1.77
					C177.8,394.62,176.08,394.37,176.5,395.81z"
              />
              <path
                className="st9"
                d="M182.8,397.56c-0.34,0.47-0.43,1.11-0.59,1.67c0.72,0.28,2.01,0.34,2.29-0.99l-0.24-1.46
					C183.71,396.91,183.15,397.08,182.8,397.56z"
              />
              <path
                className="st4"
                d="M183.26,398.72c-0.98,0.24-1.7-0.24-2.44-0.79c-0.8-0.59-1.57-0.78-2.5-1.04c-0.72-0.2-1.42-0.54-1.75-1.26
					c-0.06-0.14-0.09-0.27-0.11-0.4c-0.03,0.15-0.03,0.34,0.04,0.58c0.11,0.36,0.49,1.08,1.86,1.39c2.14,0.48,3.18,1.64,3.53,1.87
					c0.55,0.37,2.28,0.74,2.62-0.83l-0.06-0.35C184.19,398.29,183.81,398.58,183.26,398.72z"
              />
              <path
                className="st23"
                d="M181.73,377.16l1.05-14.58c0.51-2.87,0.28-5.82-0.68-8.57l-0.4-1.14l-6.13,4.35l1.72,21.99l4.1,16.41
					c0,0,0.41,2.38,2.86,1.13c0,0-0.42-10.24-0.97-13.6C183.04,381.63,181.73,377.16,181.73,377.16z"
              />
              <path
                className="st24"
                d="M181.87,370.37c0.12-0.07,0.24-0.12,0.36-0.15l0.55-7.64c0.51-2.87,0.28-5.82-0.68-8.57l-0.4-1.14
					l-6.13,4.35l1.72,21.99l4.1,16.41c0,0,0.29,1.65,1.81,1.47c-1.57-4.14-2.4-8.55-3.04-12.95
					C179.58,380.11,177.35,373.13,181.87,370.37z"
              />
              <path
                className="st23"
                d="M170.7,355.44c0,0-1.64,18-1.63,23.31l1.67,20.17c1.15,1.52,2.9,0.41,2.93,0.26
					c0.07-0.37,1.5-17.33,0.27-20.28c-0.39-0.95,3.78-12.66,3.78-12.66l4.49-3.08l-3.58-7.57L170.7,355.44z"
              />
              <path
                className="st24"
                d="M170.7,355.44c0,0-1.64,18-1.63,23.31l1.67,20.17c0.36,0.47,0.77,0.69,1.17,0.76
					c-0.18-1.4-0.23-2.81-0.25-4.23c-0.04-2.98-0.18-5.95-0.31-8.92c-0.21-4.64-1.09-10.61,2.62-14.16
					c0.55-0.53,1.24-0.38,1.66,0.03c1.01-3.11,2.1-6.16,2.1-6.16l4.49-3.08l-3.58-7.57L170.7,355.44z"
              />
              <path
                className="st4"
                d="M182.64,355.01l-11.94,0.43c0,0-1.45,8.26-1.93,14.83c1.92,1.02,4.07,1.33,6.4,1.56
					c1.79,0.04,8.94-2.05,8.94-2.05s-0.49-8.8-0.98-11.74C183,357.31,182.64,355.01,182.64,355.01z"
              />
              <path
                className="st25"
                d="M170.7,355.44c0,0-1.45,8.26-1.93,14.83c1.07,0.57,2.21,0.91,3.42,1.15c0.53-3.13,0.38-6.44,1.59-9.43
					c1.5-3.72,4.97-5.4,8.63-6.42c0.11-0.03,0.22-0.04,0.32-0.05c-0.05-0.31-0.08-0.51-0.08-0.51L170.7,355.44z"
              />
              <path
                className="st2"
                d="M184.02,336.75c-0.14-0.72,0.4-5.07-2.6-6c0,0-3.44,0.33-7.11,1.4c-2.4,0.7-2.34,4.04-2.94,4.84
					c-1.39,1.86-2.11,2.71-1.91,3.51c0.44,1.76,1.84,3.06,1.84,3.06l-0.42,7.02l-0.47,6.29c0,0,2.77,1.59,8.04,0.43
					c5.27-1.16,4.5-1.09,4.5-1.09l-0.8-4.1c-0.14-0.74-0.16-1.49-0.04-2.24C182.65,346.63,184.13,337.35,184.02,336.75z"
              />
              <path
                className="st24"
                d="M175.83,343.64c0.12-3.21-0.78-6.09-2.91-8.35c-0.29-0.31-0.37-0.66-0.32-0.99
					c-0.12-0.01-0.22-0.03-0.32-0.06c-0.44,1.11-0.58,2.31-0.91,2.75c-1.39,1.86-2.11,2.71-1.91,3.51c0.44,1.76,1.84,3.06,1.84,3.06
					l-0.42,7.02l-0.47,6.29c0,0,1.79,1.02,5.2,0.83c-0.73-1.66-1.01-3.59-0.8-5.49C175.17,349.1,175.72,346.77,175.83,343.64z"
              />
              <path
                className="st23"
                d="M174.91,325.63l1.1,5.82c-0.02,1.58,4.48,0.98,4.5-0.6l-0.32-5.15L174.91,325.63z"
              />
              <path
                className="st23"
                d="M172.98,326.26c-0.03,2.43,1.64,3.51,3.94,3.54c0,0,4.29-1.27,4.32-3.66l0.54-4.42l-8.72-1.59
					L172.98,326.26z"
              />
            </g>
          </g>
          <path
            className="st23"
            d="M173.49,340.86c-0.83-0.28-1.76,0.01-1.93,0.01c-0.51,0.01-1.21,10.35-1.73,10.42l-3.05,7.23l-1.36,2.08
			l-0.46,2.91c0,0,0.18,0.11,0.39-0.06c-0.02,0.09-0.04,0.15-0.04,0.15l0.22,1.9c0,0,0.29,0.14,0.46-0.31l0.38,0.79
			c0.42-0.01,0.42-0.5,0.36-0.9l0.27,0.56c0.69-0.01,0.24-1.38,0.24-1.38l0.06-0.24l0.36,1.06c0.55,0.15,0.29-0.93,0.29-0.93
			l-0.17-1.07l0.06-0.49c0.31-0.72,1.43-2.63,0.75-3.49c0,0,3.67-4.8,4.43-6.65c0.25-0.61,2-7.68,1.78-9.6
			C174.68,341.61,174.11,341.07,173.49,340.86z"
          />
          <path
            className="st2"
            d="M171.74,334.41c-0.35,0.56-1.25,5.76-1.43,9.07c1.13,0.96,2.91,1.31,4.75,1.27c0.26-1.88,0.62-4.49,0.75-5.54
			C176.21,336,174.74,329.55,171.74,334.41z"
          />
          <path
            className="st4"
            d="M175.29,318.17c-1.77,0.96-3.35,2.47-2.12,5.18c0,0,0.96,0.61,1.12,2.41c0.16,1.81,3.35,3.5,4.69,3.6
			c2.42,0.18,3-3.03,3-3.03s0.73-1.7-0.35-5.85C180.79,317.25,176.54,317.49,175.29,318.17z"
          />
          <path
            className="st23"
            d="M174.52,324.58c-0.57-0.24-0.74,0.58-0.74,0.58s0.74,1.97,1.2,2.12
			C175.44,327.43,175.08,324.83,174.52,324.58z"
          />
          <path
            className="st4"
            d="M183.1,320.65c-2.18-1.27-3.75,1.03-3.75,1.03c-0.36,1.66,1.45,1.88,2.06,4.51s2.91,4.03,2.66,3.75
			c-0.24-0.27,0.58-3.42,0.67-4.03C184.83,325.31,185.28,321.92,183.1,320.65z"
          />
        </g>
        <g>
          <path
            className="st4"
            d="M200.7,391.8c0,0-3.07,1.85-4.79,2.39c-0.79,0.25-2.75,1.67-0.71,2.27c2.37,0.7,6.95-0.56,9.55-0.7
			c1.85-0.1,1.7-0.68,1.81-1.26l-0.67-2.7L200.7,391.8L200.7,391.8z"
          />
          <path
            className="st4"
            d="M208.66,384.35c0,0-3.07,1.85-4.79,2.39c-0.79,0.25-2.75,1.67-0.71,2.27c2.37,0.7,6.95-0.56,9.55-0.7
			c1.85-0.1,1.7-0.68,1.81-1.26l-0.67-2.7L208.66,384.35L208.66,384.35z"
          />
          <path
            className="st4"
            d="M209.63,350.46L202.74,366l4.98,19.23c0,0,3.07,2.9,6.86-0.38l-4.44-19.53L209.63,350.46z"
          />
          <path
            className="st26"
            d="M208.99,355.34c0.17-0.55,0.43-0.99,0.76-1.34l-0.12-3.54L202.74,366l4.98,19.23c0,0,2.5,2.35,5.78,0.4
			c-1.8-3.83-4.05-6.69-4.69-11.02C207.91,368.48,207.15,361.34,208.99,355.34z"
          />
          <path
            className="st4"
            d="M198.36,348.82l-0.09,20.1l1.08,23.23c0,0,3.73,2.12,7.24,0l-0.7-20.93c0,0,2.32-11.82,3.55-14.42
			c1.96-4.15,1.45-10.77,1.45-10.77L198.36,348.82z"
          />
          <path
            className="st26"
            d="M198.27,368.92l1.08,23.23c0,0,1.19,0.67,2.85,0.87c-3.45-12.62-1.78-26.15,3.9-38.06
			c0.01-0.03,0.03-0.05,0.05-0.08c0.16-0.46,0.1-1.05,0.46-1.37c4.33-3.79,4.27-7.48,4.27-7.48l-12.51,2.8L198.27,368.92z"
          />
          <path
            className="st10"
            d="M200.69,320.14c-1.99,2.9-2.72,7.52-2.72,9.37c0,3.19,0.15,2.86,0.19,5.71c0.03,1.9,0.14,2.35,0.1,3.69
			c-0.18,5.9,0.02,11.53,0.02,11.53s7.61,1.7,10.4-0.29c0.87-0.62,2.47-3.21,2.47-3.21c-0.18-1.82-0.09-6.26,0.05-7.74
			c0.9-9.93,1.8-11.29-1.33-18.29C207.99,316.73,204.8,314.15,200.69,320.14z"
          />
          <path
            className="st27"
            d="M205.79,325.95c-0.91-1.06-2.41-0.33-2.86-1.65c-0.16-0.47,0.62-2.74,0.48-3.22
			c-0.05-0.16-0.1-0.32-0.15-0.47c-0.46-0.4-0.93-0.73-1.43-0.96c-0.2-0.09-0.37-0.2-0.54-0.31c-0.2,0.25-0.4,0.52-0.6,0.81
			c-1.99,2.9-2.72,7.52-2.72,9.37c0,3.19,0.15,2.85,0.19,5.71c0.03,1.9,0.14,2.35,0.1,3.69c-0.18,5.9,0.02,11.53,0.02,11.53
			s7.61,1.7,10.41-0.29c0.36-0.26,0.85-0.86,1.3-1.47c-1.17-4.16-0.67-20.12-2.07-21.76C207.09,326.86,206.31,326.56,205.79,325.95z
			"
          />
          <path
            className="st28"
            d="M198.34,352.22l-2.01,3.03l-0.42,3.67c0,0,0.3,0.28,0.7,0v0.68c0,0,0.67,0.24,0.95-0.73l0.21,0.73
			c0,0,0.61-0.18,0.61-0.68l0.13-1.24l0.43,0.95c0,0,0.67-0.11,0.42-0.85l0.01-1.36c0,0,1.51-1.42,1.22-2.19
			C200.33,353.53,198.34,352.22,198.34,352.22z"
          />
          <path
            className="st10"
            d="M205.73,322.84c-1.85-0.55-3.78,1.57-3.97,3.28c-0.47,4.15,0.28,13.44,0.28,13.44l-4.62,13.35
			c0,0,0.78,2.92,3.9,1.36c0.85-0.43,6.42-13.01,6.42-13.01l0.7-15.32C208.44,325.94,207.58,323.38,205.73,322.84z"
          />
          <path
            className="st28"
            d="M201.95,317.26l0.24,1.66c0.09,0.63,0.53,1.14,1.14,1.33c0.36,0.11,0.82,0.2,1.36,0.22
			c0.93,0.03,2.27-0.37,2.76-1.38c0.35-0.73,0.19-0.87,0.02-3.24L201.95,317.26z"
          />
          <path
            className="st4"
            d="M205.05,305.69c-2.48-0.15-4.86,0.84-5.57,1.26c-0.99,0.59-0.99,1.41-0.18,2.19l0,0c0,0,3.63,6.5,5.76,7.77
			c1.95,1.16,3.12,0.13,3.12,0.13s1.99-4.11,1.92-5.99C210.04,309.17,209.29,305.94,205.05,305.69z"
          />
          <path
            className="st16"
            d="M207.85,386.18c-4.66,0.98-8.84,1.94-12.24,5.59c-1.19,1.28-2.1,2.09-1.86,4.06
			c0.42,3.31,2.84,2.16,6.14,1.8c2.92-0.32,17.92-1.64,17.27-7.57C216.87,387.43,210.92,385.96,207.85,386.18z"
          />
          <path
            className="st28"
            d="M204.99,314.79c0.16-0.32,0.33-0.81,0.22-1.14c-0.31-0.89-1.14-0.18-1.14-0.18
			c-0.49-2.33-1.65-2.52-2.35-2.86c-0.83-0.41-2.42-1.45-2.42-1.45s-0.35,1.68-0.33,2.25c0.01,0.23,0.19,0.91,0.19,0.91
			s-0.88,1.23-1.39,2.18c-0.12,0.22,0.87,0.55,0.87,0.55l0.7,2.65c0.14,0.66,0.73,1.15,1.44,1.22l2.13,0.2c0,0,3.59-1.44,3.16-1.74
			C205.59,317.01,204.99,314.79,204.99,314.79z"
          />
          <path
            className="st10"
            d="M207.64,317.66c0,0,0.03,0.86-2.08,1.79c-0.99,0.43-3.19,0-3.19,0l-0.97,1.03c0,0,0.28,0.44,2.91,0.51
			c1.58,0.04,2.63-0.79,3.22-1.47c0.39-0.45,0.48-1.08,0.22-1.61L207.64,317.66z"
          />
          <path
            className="st10"
            d="M201.84,318.99c-0.28,0.27-0.69,0.88-0.69,0.88l1.23-0.42l-0.15-0.42
			C202.03,318.99,201.84,318.99,201.84,318.99z"
          />
          <path
            className="st4"
            d="M202.8,315.65c-0.4,0.48-1.78,0.52-1.9,0.52s-1.53-0.97-2.22-0.85l0.4,1.31c0,0,0.12,2.36,0.42,2.42
			c0.3,0.06,1.96,0.61,2.7-0.03c0,0,1.47-1.36,1.63-2.65s0.32-3.11,0.32-3.11l-0.32-0.77C203.85,312.5,203.21,315.16,202.8,315.65z"
          />
          <path
            className="st28"
            d="M199.09,316.64c-0.04,0.26,0.08,0.54,0.08,0.54c0.61,0,0.74-0.36,0.74-0.36
			C199.5,316.9,199.09,316.64,199.09,316.64z"
          />
        </g>
      </g>
      <g id="ludzie_1b" ref={secondTabletRef}>
        <g>
          <path
            className="st9"
            d="M65.44,320.17l-10.3,5.94c-1.27,0.73-2.3,2.52-2.3,3.98l0.03,11.83c0,1.46,1.04,2.05,2.31,1.32l10.3-5.94
				c1.27-0.73,2.3-2.52,2.3-3.98l-0.03-11.83C67.75,320.03,66.71,319.44,65.44,320.17z"
          />
          <path
            className="st2"
            d="M63.96,325.68v-0.75l-5.69,4.48v1.52l0.01,3.92c-0.02,0.01-0.05,0.02-0.07,0.04
			c-0.31,0.18-0.66,0.59-0.93,1.11c-0.4,0.79-0.45,1.54-0.11,1.67c0.11,0.04,0.26,0.01,0.41-0.08c0.31-0.18,0.67-0.59,0.94-1.12
			c0.16-0.32,0.26-0.63,0.31-0.89l0.03-0.02l-0.01-5.07l4.56-3.59l0.01,3.82c-0.02,0.01-0.05,0.02-0.07,0.04
			c-0.31,0.18-0.66,0.59-0.93,1.11c-0.4,0.79-0.45,1.53-0.11,1.67c0.11,0.04,0.25,0.01,0.41-0.08c0.31-0.18,0.67-0.59,0.94-1.12
			c0.17-0.33,0.27-0.65,0.31-0.92l0.02-0.01l-0.01-4.96L63.96,325.68z"
          />
        </g>
        <polygon
          className="st29"
          points="176.81,207.26 176.76,207.29 176.84,234.6 206.17,217.66 206.17,190.28 176.78,207.25 	"
        />
        <g>
          <g>
            <polygon
              className="st2"
              points="180.59,200.42 180.67,227.73 217.96,206.19 217.89,178.89 			"
            />
          </g>
          <g>
            <polygon
              className="st6"
              points="200.97,207.14 217.9,178.85 180.6,200.38 			"
            />
          </g>
          <g>
            <polygon
              className="st2"
              points="200.94,206.05 217.9,178.85 180.6,200.38 			"
            />
          </g>
        </g>
        <g className="st1">
          <path
            className="st4"
            d="M99.6,220.48c-0.02-6.99-4.97-9.81-11.06-6.3l-3.95,2.24v40.73l8.11-4.62l11.6,2.4l-4.65-12.12L99.6,220.48z"
          />
        </g>
        <g>
          <path
            className="st2"
            d="M100.78,215.97c-0.02-6.99-4.97-9.81-11.06-6.3l-26.58,15.09c-6.09,3.51-11,12.03-10.98,19.02l0.04,15.39
			c0.02,6.99,4.97,9.81,11.06,6.3l30.63-17.46l11.6,2.4l-4.65-12.12L100.78,215.97z"
          />
        </g>
        <g>
          <path
            className="st30"
            d="M144.31,203.26l0.04,15.3c0.01,2.46,1.75,3.45,3.89,2.22l17.31-9.99c2.14-1.23,3.87-4.23,3.86-6.69
			l-0.04-15.04C168.81,189.44,145.11,202.94,144.31,203.26z"
          />
        </g>
        <g>
          <path
            className="st9"
            d="M167.42,177.48l-17.31,9.99c-2.14,1.23-3.87,4.23-3.86,6.69l0.06,19.88c0.01,2.46,1.75,3.45,3.89,2.22
			l17.31-9.99c2.14-1.23,3.87-4.23,3.86-6.69l-0.06-19.88C171.3,177.24,169.56,176.24,167.42,177.48z"
          />
        </g>
        <g className="st31">
          <path
            className="st18"
            d="M121.65,295.07l-17.31,9.99c-2.14,1.23-3.87,4.23-3.86,6.69l0.04,13.43c0.21-0.15,20.87-11.8,25.07-13.86
			l-0.05-14.03C125.53,294.83,123.79,293.84,121.65,295.07z"
          />
        </g>
        <g>
          <path
            className="st32"
            d="M87.9,291.15L84.7,293v23.43l3.27-1.89c1.74-1,3.14-3.44,3.14-5.44l-0.05-16.16
			C91.05,290.95,89.64,290.14,87.9,291.15z"
          />
        </g>
        <g>
          <path
            className="st4"
            d="M89.28,286.29l-14.06,8.12c-1.74,1-3.14,3.44-3.14,5.44L72.12,316c0.01,2,1.42,2.8,3.16,1.8l14.06-8.12
			c1.74-1,3.14-3.44,3.14-5.44l-0.05-16.16C92.43,286.09,91.01,285.29,89.28,286.29z"
          />
        </g>
        <g>
          <path
            className="st33"
            d="M85.49,233.13c0-1-0.63-1.49-1.45-1.21c0.86-0.7,1.5-1.99,1.43-3.02c-0.07-1.08-0.91-1.43-1.88-0.87
			l-4.77,2.75l-0.16,0.09l0.17-4.16c-0.01-3.26-1.38-3.19-3.02-2.51c-0.19,0.11-0.34,0.36-0.34,0.59l0.01,3.84
			c0,0.32-0.09,0.69-0.27,1.05l-3.82,8.07l-4.44,2.39l0.03,10.94l5.14-2.97c1,0.5,2.64,0.27,3.81-0.41l2.83-1.63l0.43-0.25
			l2.88-1.66c0.84-0.49,1.53-1.74,1.53-2.77c0-0.69-0.31-1.12-0.76-1.19l0.1-0.06c0.82-0.47,1.56-1.57,1.68-2.58
			c0.09-0.81-0.2-1.32-0.69-1.44C84.8,235.46,85.49,234.19,85.49,233.13z"
          />
        </g>
        <g>
          <path
            className="st2"
            d="M162.68,188.03c-1.85,1.07-3.4,3.59-3.78,6c-0.01,0.07-0.05,0.12-0.09,0.15c-0.04,0.02-0.08,0.02-0.1-0.04
			c-0.4-1.95-1.95-2.69-3.8-1.62c-2.15,1.24-3.88,4.43-3.87,7.13c0,0.75,0.37,1.87,0.63,2.36c1.35,2.57,4.78,3.75,6.36,4.18
			c0.24,0.06,0.53,0,0.81-0.17c0.29-0.16,0.57-0.43,0.81-0.77c1.57-2.24,5-7.39,6.32-11.5c0.25-0.78,0.61-2.32,0.61-3.07
			C166.58,187.97,164.83,186.79,162.68,188.03z"
          />
        </g>
        <g className="st1">
          <path
            className="st10"
            d="M188.16,149.02l-12.72,7.35c-1.57,0.91-2.85,3.11-2.84,4.92l0.04,14.62c0.01,1.81,1.29,2.54,2.86,1.63
			l12.72-7.35c1.57-0.91,2.85-3.11,2.84-4.92l-0.04-14.62C191.02,148.85,189.74,148.12,188.16,149.02z"
          />
          <g>
            <path
              className="st2"
              d="M185.25,157.13l-6.86,3.96c-1.35,0.78-2.45,2.68-2.44,4.23l0.01,2.71c0,1.55,1.11,2.18,2.46,1.4l4.49-2.59
				l2.37,1.42c0.05,0.03,0.12,0.02,0.18-0.01c0.14-0.08,0.28-0.3,0.25-0.48l-0.42-2.29c1.35-0.78,2.45-2.68,2.44-4.23l-0.01-2.71
				C187.71,156.98,186.61,156.35,185.25,157.13z"
            />
          </g>
        </g>
        <g>
          <polygon
            className="st2"
            points="84.68,298.34 82.85,295.15 81.05,300.44 76.98,303.47 79.94,305.06 79.25,310.12 82.88,305.82 
			86.53,305.92 85.82,301.66 88.75,296.67 		"
          />
        </g>
        <g>
          <g>
            <path
              className="st2"
              d="M128.75,202.61c-0.55,0-1,0.45-1,1v0.05c0,0.55,0.45,0.97,1,0.97s1-0.47,1-1.02S129.3,202.61,128.75,202.61z
				"
            />
          </g>
          <g>
            <path
              className="st2"
              d="M128.75,198.79c-0.55,0-1,0.45-1,1v0.09c0,0.55,0.45,0.95,1,0.95s1-0.49,1-1.05
				C129.75,199.24,129.3,198.79,128.75,198.79z"
            />
            <path
              className="st2"
              d="M128.75,194.96c-0.55,0-1,0.45-1,1v0.09c0,0.55,0.45,0.95,1,0.95s1-0.49,1-1.05
				C129.75,195.41,129.3,194.96,128.75,194.96z"
            />
            <path
              className="st2"
              d="M128.75,191.14c-0.55,0-1,0.45-1,1v0.09c0,0.55,0.45,0.95,1,0.95s1-0.49,1-1.05
				C129.75,191.59,129.3,191.14,128.75,191.14z"
            />
          </g>
          <g>
            <path
              className="st2"
              d="M128.26,187.49c-0.32,0.18-0.51,0.51-0.51,0.87v0.05c0,0.55,0.45,0.98,1,0.98c0.26,0,0.5-0.11,0.68-0.28
				c0.36-0.3,0.46-0.82,0.22-1.25C129.38,187.37,128.74,187.21,128.26,187.49z"
            />
          </g>
          <g>
            <path
              className="st2"
              d="M146.69,176.97l-0.09,0.05c-0.48,0.27-0.65,0.88-0.38,1.36c0.18,0.33,0.52,0.51,0.87,0.51
				c0.17,0,0.34-0.04,0.49-0.13l0.09-0.05c0.48-0.27,0.65-0.88,0.38-1.36C147.78,176.87,147.18,176.7,146.69,176.97z"
            />
            <path
              className="st2"
              d="M143.02,179.07l-0.09,0.05c-0.48,0.27-0.65,0.88-0.38,1.36c0.18,0.33,0.52,0.51,0.87,0.51
				c0.17,0,0.34-0.04,0.49-0.13l0.09-0.05c0.48-0.27,0.65-0.88,0.38-1.36C144.11,178.97,143.5,178.8,143.02,179.07z"
            />
            <path
              className="st2"
              d="M139.33,181.17l-0.09,0.05c-0.48,0.28-0.64,0.89-0.36,1.37c0.19,0.32,0.52,0.5,0.87,0.5
				c0.17,0,0.34-0.04,0.5-0.13l0.09-0.05c0.48-0.28,0.64-0.89,0.36-1.37S139.81,180.9,139.33,181.17z"
            />
            <path
              className="st2"
              d="M131.98,185.37l-0.09,0.05c-0.48,0.27-0.65,0.88-0.38,1.36c0.18,0.33,0.52,0.51,0.87,0.51
				c0.17,0,0.34-0.04,0.49-0.13l0.09-0.05c0.48-0.27,0.65-0.88,0.38-1.36C133.07,185.26,132.46,185.09,131.98,185.37z"
            />
            <path
              className="st2"
              d="M135.66,183.27l-0.09,0.05c-0.48,0.27-0.65,0.88-0.38,1.36c0.18,0.33,0.52,0.51,0.87,0.51
				c0.17,0,0.34-0.04,0.49-0.13l0.09-0.05c0.48-0.27,0.65-0.88,0.38-1.36C136.75,183.17,136.14,183,135.66,183.27z"
            />
            <path
              className="st2"
              d="M161.4,168.58l-0.09,0.05c-0.48,0.27-0.64,0.89-0.37,1.36c0.18,0.32,0.52,0.5,0.87,0.5
				c0.17,0,0.34-0.04,0.5-0.13l0.09-0.05c0.48-0.27,0.64-0.89,0.37-1.36C162.49,168.47,161.88,168.31,161.4,168.58z"
            />
            <path
              className="st2"
              d="M165.08,166.48l-0.09,0.05c-0.48,0.27-0.65,0.88-0.37,1.36c0.18,0.32,0.52,0.5,0.87,0.5
				c0.17,0,0.34-0.04,0.49-0.13l0.09-0.05c0.48-0.27,0.65-0.88,0.37-1.36C166.17,166.37,165.56,166.21,165.08,166.48z"
            />
            <path
              className="st2"
              d="M150.36,174.88l-0.09,0.05c-0.48,0.28-0.64,0.89-0.36,1.37c0.19,0.32,0.52,0.5,0.87,0.5
				c0.17,0,0.34-0.04,0.5-0.13l0.09-0.05c0.48-0.28,0.64-0.89,0.36-1.37C151.45,174.76,150.84,174.6,150.36,174.88z"
            />
            <path
              className="st2"
              d="M154.04,172.78l-0.09,0.05c-0.48,0.28-0.64,0.89-0.37,1.37c0.18,0.32,0.52,0.5,0.87,0.5
				c0.17,0,0.34-0.04,0.5-0.13l0.09-0.05c0.48-0.28,0.64-0.89,0.37-1.37C155.13,172.67,154.52,172.5,154.04,172.78z"
            />
            <path
              className="st2"
              d="M157.73,170.68l-0.09,0.05c-0.48,0.27-0.65,0.88-0.38,1.36c0.18,0.33,0.52,0.51,0.87,0.51
				c0.17,0,0.34-0.04,0.49-0.13l0.09-0.05c0.48-0.27,0.65-0.88,0.38-1.36C158.82,170.57,158.21,170.4,157.73,170.68z"
            />
          </g>
          <g>
            <path
              className="st2"
              d="M168.71,164.41l-0.04,0.02c-0.48,0.27-0.62,0.87-0.35,1.35c0.18,0.32,0.52,0.49,0.87,0.49
				c0.18,0,0.35-0.04,0.51-0.14c0.48-0.27,0.64-0.89,0.37-1.36C169.8,164.3,169.19,164.14,168.71,164.41z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              className="st2"
              d="M121.1,208.5c-0.55,0-1,0.45-1,1v0.05c0,0.55,0.45,0.97,1,0.97s1-0.47,1-1.02S121.65,208.5,121.1,208.5z"
            />
          </g>
          <g>
            <path
              className="st2"
              d="M121.1,204.35c-0.55,0-1,0.45-1,1v0.1c0,0.55,0.45,1,1,1s1-0.45,1-1v-0.1
				C122.1,204.8,121.65,204.35,121.1,204.35z"
            />
            <path
              className="st2"
              d="M121.1,200.21c-0.55,0-1,0.45-1,1v0.1c0,0.55,0.45,1,1,1s1-0.45,1-1v-0.1
				C122.1,200.65,121.65,200.21,121.1,200.21z"
            />
          </g>
          <g>
            <path
              className="st2"
              d="M121.57,196.25c-0.31-0.18-0.72-0.16-1.03,0.02c-0.48,0.28-0.61,0.88-0.33,1.36
				c0.02,0.03,0.04,0.07,0.06,0.1c0.18,0.25,0.48,0.41,0.82,0.41c0.55,0,1-0.47,1-1.02C122.1,196.75,121.89,196.43,121.57,196.25z"
            />
          </g>
          <g>
            <path
              className="st2"
              d="M81.4,219.46l-0.09,0.05c-0.47,0.28-0.63,0.9-0.35,1.37c0.19,0.31,0.52,0.49,0.86,0.49
				c0.17,0,0.35-0.04,0.51-0.14l0.09-0.05c0.47-0.28,0.63-0.9,0.35-1.37C82.48,219.33,81.87,219.18,81.4,219.46z"
            />
            <path
              className="st2"
              d="M88.53,215.24l-0.09,0.05c-0.47,0.28-0.63,0.9-0.35,1.37c0.19,0.31,0.52,0.49,0.86,0.49
				c0.17,0,0.35-0.04,0.51-0.14l0.09-0.05c0.47-0.28,0.63-0.9,0.35-1.37C89.62,215.11,89,214.95,88.53,215.24z"
            />
            <path
              className="st2"
              d="M77.83,221.57l-0.09,0.05c-0.47,0.28-0.63,0.9-0.35,1.37c0.19,0.31,0.52,0.49,0.86,0.49
				c0.17,0,0.35-0.04,0.51-0.14l0.09-0.05c0.47-0.28,0.63-0.9,0.35-1.37C78.92,221.45,78.3,221.29,77.83,221.57z"
            />
            <path
              className="st2"
              d="M84.97,217.35l-0.09,0.05c-0.48,0.28-0.64,0.89-0.36,1.37c0.19,0.32,0.52,0.5,0.86,0.5
				c0.17,0,0.34-0.04,0.5-0.14l0.09-0.05c0.48-0.28,0.64-0.89,0.36-1.37C86.06,217.23,85.45,217.07,84.97,217.35z"
            />
            <path
              className="st2"
              d="M67.14,227.91l-0.09,0.05c-0.48,0.28-0.64,0.89-0.36,1.37c0.19,0.32,0.52,0.5,0.87,0.5
				c0.17,0,0.34-0.04,0.5-0.14l0.09-0.05c0.48-0.28,0.64-0.89,0.36-1.37C68.23,227.79,67.61,227.63,67.14,227.91z"
            />
            <path
              className="st2"
              d="M63.56,230.02l-0.09,0.05c-0.48,0.28-0.63,0.89-0.35,1.37c0.19,0.32,0.52,0.49,0.86,0.49
				c0.17,0,0.35-0.04,0.51-0.14l0.09-0.05c0.48-0.28,0.63-0.89,0.35-1.37C64.65,229.9,64.04,229.74,63.56,230.02z"
            />
            <path
              className="st2"
              d="M70.7,225.8l-0.09,0.05c-0.48,0.28-0.63,0.89-0.35,1.37c0.19,0.32,0.52,0.49,0.86,0.49
				c0.17,0,0.35-0.04,0.51-0.14l0.09-0.05c0.48-0.28,0.63-0.89,0.35-1.37C71.79,225.67,71.17,225.52,70.7,225.8z"
            />
            <path
              className="st2"
              d="M74.26,223.69l-0.09,0.05c-0.47,0.28-0.63,0.89-0.35,1.37c0.19,0.32,0.52,0.49,0.86,0.49
				c0.17,0,0.35-0.04,0.51-0.14l0.09-0.05c0.48-0.28,0.63-0.89,0.35-1.37C75.35,223.56,74.74,223.4,74.26,223.69z"
            />
            <path
              className="st2"
              d="M92.1,213.12l-0.09,0.05c-0.47,0.28-0.63,0.9-0.35,1.37c0.19,0.31,0.52,0.49,0.86,0.49
				c0.17,0,0.35-0.04,0.51-0.14l0.09-0.05c0.47-0.28,0.63-0.9,0.35-1.37C93.18,213,92.57,212.84,92.1,213.12z"
            />
            <path
              className="st2"
              d="M113.5,200.45l-0.09,0.05c-0.47,0.28-0.63,0.9-0.35,1.37c0.19,0.31,0.52,0.49,0.86,0.49
				c0.17,0,0.35-0.04,0.51-0.14l0.09-0.05c0.47-0.28,0.63-0.9,0.35-1.37C114.59,200.32,113.97,200.17,113.5,200.45z"
            />
            <path
              className="st2"
              d="M117.06,198.34l-0.09,0.05c-0.47,0.28-0.63,0.9-0.35,1.37c0.19,0.31,0.52,0.49,0.86,0.49
				c0.17,0,0.35-0.04,0.51-0.14l0.09-0.05c0.47-0.28,0.63-0.9,0.35-1.37C118.15,198.21,117.54,198.05,117.06,198.34z"
            />
            <path
              className="st2"
              d="M109.93,202.56l-0.09,0.05c-0.47,0.28-0.63,0.9-0.35,1.37c0.19,0.31,0.52,0.49,0.86,0.49
				c0.17,0,0.35-0.04,0.51-0.14l0.09-0.05c0.47-0.28,0.63-0.9,0.35-1.37C111.02,202.43,110.41,202.28,109.93,202.56z"
            />
            <path
              className="st2"
              d="M95.66,211.01l-0.09,0.05c-0.47,0.28-0.63,0.9-0.35,1.37c0.19,0.31,0.52,0.49,0.86,0.49
				c0.17,0,0.35-0.04,0.51-0.14l0.09-0.05c0.47-0.28,0.63-0.9,0.35-1.37C96.75,210.88,96.14,210.73,95.66,211.01z"
            />
            <path
              className="st2"
              d="M99.23,208.9l-0.09,0.05c-0.47,0.28-0.63,0.9-0.35,1.37c0.19,0.31,0.52,0.49,0.86,0.49
				c0.17,0,0.35-0.04,0.51-0.14l0.09-0.05c0.47-0.28,0.63-0.9,0.35-1.37C100.32,208.77,99.71,208.62,99.23,208.9z"
            />
            <path
              className="st2"
              d="M106.37,204.67l-0.09,0.05c-0.48,0.28-0.64,0.89-0.36,1.37c0.19,0.32,0.52,0.5,0.86,0.5
				c0.17,0,0.34-0.04,0.5-0.14l0.09-0.05c0.48-0.28,0.64-0.89,0.36-1.37C107.46,204.55,106.85,204.39,106.37,204.67z"
            />
            <path
              className="st2"
              d="M102.8,206.78l-0.09,0.05c-0.48,0.28-0.64,0.89-0.36,1.37c0.19,0.32,0.52,0.5,0.86,0.5
				c0.17,0,0.34-0.04,0.5-0.14l0.09-0.05c0.48-0.28,0.64-0.89,0.36-1.37C103.89,206.67,103.28,206.5,102.8,206.78z"
            />
          </g>
          <g>
            <path
              className="st2"
              d="M59.95,232.16c-0.3,0.18-0.49,0.56-0.49,0.91c0,0.55,0.45,0.98,1,0.98c0.27,0,0.51-0.11,0.69-0.29
				c0.36-0.31,0.45-0.83,0.19-1.26C61.06,232.02,60.43,231.88,59.95,232.16z"
            />
          </g>
          <g>
            <path
              className="st2"
              d="M60.46,284.11c-0.55,0-1,0.5-1,1.05s0.45,1,1,1s1-0.45,1-1v-0.1C61.46,284.51,61.01,284.11,60.46,284.11z"
            />
            <path
              className="st2"
              d="M60.46,288.12c-0.55,0-1,0.5-1,1.05s0.45,1,1,1s1-0.45,1-1v-0.1C61.46,288.52,61.01,288.12,60.46,288.12z"
            />
            <path
              className="st2"
              d="M60.46,276.1c-0.55,0-1,0.5-1,1.05s0.45,1,1,1s1-0.45,1-1v-0.1C61.46,276.5,61.01,276.1,60.46,276.1z"
            />
            <path
              className="st2"
              d="M60.46,280.11c-0.55,0-1,0.5-1,1.05s0.45,1,1,1s1-0.45,1-1v-0.1C61.46,280.5,61.01,280.11,60.46,280.11z"
            />
            <path
              className="st2"
              d="M60.46,300.14c-0.55,0-1,0.5-1,1.05s0.45,1,1,1s1-0.45,1-1v-0.1C61.46,300.54,61.01,300.14,60.46,300.14z"
            />
            <path
              className="st2"
              d="M60.46,304.15c-0.55,0-1,0.5-1,1.05s0.45,1,1,1s1-0.45,1-1v-0.1C61.46,304.55,61.01,304.15,60.46,304.15z"
            />
            <path
              className="st2"
              d="M60.46,308.16c-0.55,0-1,0.5-1,1.05s0.45,1,1,1s1-0.45,1-1v-0.1C61.46,308.55,61.01,308.16,60.46,308.16z"
            />
            <path
              className="st2"
              d="M60.46,296.13c-0.55,0-1,0.5-1,1.05s0.45,1,1,1s1-0.45,1-1v-0.1C61.46,296.53,61.01,296.13,60.46,296.13z"
            />
            <path
              className="st2"
              d="M60.46,292.13c-0.55,0-1,0.5-1,1.05s0.45,1,1,1s1-0.45,1-1v-0.1C61.46,292.53,61.01,292.13,60.46,292.13z"
            />
            <path
              className="st2"
              d="M60.46,272.09c-0.55,0-1,0.5-1,1.05s0.45,1,1,1s1-0.45,1-1v-0.1C61.46,272.49,61.01,272.09,60.46,272.09z"
            />
            <path
              className="st2"
              d="M60.46,244.04c-0.55,0-1,0.5-1,1.05s0.45,1,1,1s1-0.45,1-1v-0.1C61.46,244.44,61.01,244.04,60.46,244.04z"
            />
            <path
              className="st2"
              d="M60.46,268.08c-0.55,0-1,0.5-1,1.05s0.45,1,1,1s1-0.45,1-1v-0.1C61.46,268.48,61.01,268.08,60.46,268.08z"
            />
            <path
              className="st2"
              d="M60.46,236.03c-0.55,0-1,0.5-1,1.05s0.45,1,1,1s1-0.45,1-1v-0.1C61.46,236.43,61.01,236.03,60.46,236.03z"
            />
            <path
              className="st2"
              d="M60.46,248.05c-0.55,0-1,0.5-1,1.05s0.45,1,1,1s1-0.45,1-1V249C61.46,248.45,61.01,248.05,60.46,248.05z"
            />
            <path
              className="st2"
              d="M60.46,240.04c-0.55,0-1,0.5-1,1.05s0.45,1,1,1s1-0.45,1-1v-0.1C61.46,240.43,61.01,240.04,60.46,240.04z"
            />
            <path
              className="st2"
              d="M60.46,252.06c-0.55,0-1,0.5-1,1.05s0.45,1,1,1s1-0.45,1-1v-0.1C61.46,252.46,61.01,252.06,60.46,252.06z"
            />
            <path
              className="st2"
              d="M60.46,260.07c-0.55,0-1,0.5-1,1.05s0.45,1,1,1s1-0.45,1-1v-0.1C61.46,260.47,61.01,260.07,60.46,260.07z"
            />
            <path
              className="st2"
              d="M60.46,264.08c-0.55,0-1,0.5-1,1.05s0.45,1,1,1s1-0.45,1-1v-0.1C61.46,264.48,61.01,264.08,60.46,264.08z"
            />
            <path
              className="st2"
              d="M60.46,256.06c-0.55,0-1,0.5-1,1.05s0.45,1,1,1s1-0.45,1-1v-0.1C61.46,256.46,61.01,256.06,60.46,256.06z"
            />
          </g>
          <g>
            <path
              className="st2"
              d="M61.28,312.54c-0.18-0.25-0.48-0.41-0.82-0.41c-0.55,0-1,0.47-1,1.02c0,0.36,0.22,0.69,0.54,0.86
				c0.14,0.08,0.31,0.12,0.47,0.12c0.2,0,0.4-0.05,0.56-0.16c0.47-0.29,0.59-0.9,0.29-1.37C61.31,312.59,61.3,312.57,61.28,312.54z"
            />
          </g>
          <g>
            <path
              className="st2"
              d="M62.91,310.43l-0.07,0.04c-0.47,0.29-0.57,0.89-0.28,1.36c0.19,0.3,0.52,0.45,0.85,0.45
				c0.19,0,0.39-0.05,0.56-0.16c0.47-0.29,0.61-0.91,0.32-1.38C64,310.28,63.38,310.13,62.91,310.43z"
            />
            <path
              className="st2"
              d="M65.85,308.57l-0.07,0.04c-0.47,0.29-0.57,0.89-0.28,1.36c0.19,0.3,0.52,0.45,0.85,0.45
				c0.19,0,0.39-0.05,0.56-0.16c0.47-0.29,0.61-0.91,0.32-1.38C66.94,308.42,66.32,308.27,65.85,308.57z"
            />
          </g>
          <g>
            <path
              className="st2"
              d="M68.75,306.73l-0.04,0.03c-0.46,0.3-0.58,0.9-0.28,1.37c0.19,0.29,0.51,0.45,0.84,0.45
				c0.19,0,0.39-0.05,0.56-0.16c0.46-0.3,0.6-0.92,0.3-1.38C69.84,306.56,69.22,306.43,68.75,306.73z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              className="st34"
              d="M132.51,189.39c-0.9,0.52-1.63,1.78-1.62,2.81c0,1.04,0.74,1.45,1.63,0.93c0.9-0.52,1.63-1.78,1.63-2.82
				C134.14,189.29,133.41,188.87,132.51,189.39z"
            />
          </g>
          <g>
            <path
              className="st35"
              d="M138.11,186.16c-0.9,0.52-1.63,1.78-1.62,2.81c0,1.04,0.74,1.45,1.63,0.93c0.9-0.52,1.63-1.78,1.63-2.82
				C139.75,186.06,139.01,185.64,138.11,186.16z"
            />
          </g>
          <g>
            <path
              className="st2"
              d="M143.71,182.92c-0.9,0.52-1.63,1.78-1.63,2.81c0,1.04,0.74,1.45,1.64,0.93s1.63-1.78,1.63-2.82
				C145.35,182.82,144.62,182.4,143.71,182.92z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              className="st34"
              d="M66.95,282.42c-0.94,0.54-1.71,1.86-1.7,2.95c0,1.08,0.77,1.52,1.71,0.98c0.94-0.54,1.7-1.86,1.7-2.95
				C68.65,282.31,67.89,281.88,66.95,282.42z"
            />
          </g>
          <g>
            <path
              className="st35"
              d="M66.93,275.68c-0.94,0.54-1.71,1.86-1.7,2.95c0,1.08,0.77,1.52,1.71,0.98c0.94-0.54,1.7-1.86,1.7-2.95
				C68.64,275.58,67.87,275.14,66.93,275.68z"
            />
          </g>
          <g>
            <path
              className="st2"
              d="M66.91,268.95c-0.94,0.54-1.71,1.86-1.7,2.95c0,1.08,0.77,1.52,1.71,0.98c0.94-0.54,1.7-1.86,1.7-2.95
				S67.85,268.4,66.91,268.95z"
            />
          </g>
        </g>
        <g>
          <g>
            <g>
              <path
                className="st2"
                d="M126.21,289.92l-17.31,9.99c-2.14,1.23-3.87,4.23-3.86,6.69l0.06,19.88c0.01,2.46,1.75,3.45,3.89,2.22
					l17.31-9.99c2.14-1.23,3.87-4.23,3.86-6.69l-0.06-19.88C130.09,289.68,128.35,288.69,126.21,289.92z"
              />
            </g>
            <g>
              <path
                className="st33"
                d="M125.99,297.48l-16.83,9.72c-0.3,0.17-0.54,0.59-0.54,0.94l0.04,13.06c0,0.34,0.25,0.48,0.54,0.31
					l16.83-9.72c0.3-0.17,0.54-0.6,0.54-0.94l-0.04-13.06C126.54,297.45,126.3,297.31,125.99,297.48z"
              />
            </g>
          </g>
          <polygon
            className="st2"
            points="114.85,313.54 121.1,307.73 114.85,308.49 		"
          />
        </g>
      </g>
      <g id="ludzie_2" ref={peopleRef}>
        <g>
          <path
            className="st23"
            d="M295.05,320.92c-1.09,4.21-2.18,13.31-2.18,13.31c-0.44,4.6,1.82,10.04,1.82,10.04
			c-0.52,0.83,0.02,2.5,0.21,3.29l-0.03,0.51l-0.37,1.06c0,0-0.47,1.06,0.12,1.01l0.57-1.02l0.02,0.26c0,0-0.72,1.32-0.02,1.46
			l0.38-0.52c-0.14,0.39-0.23,0.9,0.19,0.99l0.54-0.74c0.09,0.49,0.41,0.41,0.41,0.41l0.58-1.9c0,0-0.01-0.06-0.01-0.16
			c0.18,0.21,0.39,0.13,0.39,0.13l0.09-3.06l-1-2.38l-0.75-8.72l2.58-12.53L295.05,320.92z"
          />
          <path
            className="st9"
            d="M293.51,329.44c1.18,0.28,2.37,0.47,3.48,0.69l1.6-7.76l-3.54-1.45C294.49,323.1,293.93,326.58,293.51,329.44
			z"
          />
          <g>
            <path
              className="st17"
              d="M301.19,384.98c-0.91,0.67-7.57,1.82-6.94,4.36c0.31,1.23,8.07,2.65,9.5,2.78
				c2.52,0.23,10.51,1.13,12.32-1.51C319.89,385.06,303.77,385.33,301.19,384.98z"
            />
            <g>
              <path
                className="st2"
                d="M312.34,388.61c-0.32,0.02-3.84,0-3.84,0l-3.17,0.61l-0.12,2.16c0.36,1.3,2.14,1.09,2.57,1.06
					s4.96-1.53,4.96-1.53c0.68-0.34,1.3-0.71,1.5-1.05C315.19,388.22,312.61,388.59,312.34,388.61z"
              />
              <path
                className="st9"
                d="M312.34,388.61c-0.03,0-0.09,0-0.16,0c0.5,0.44,0.91,0.97,1.05,1.7c0.02,0.1,0.03,0.21,0.02,0.32
					c0.46-0.26,0.84-0.53,0.99-0.78C315.19,388.22,312.61,388.59,312.34,388.61z"
              />
              <path
                className="st9"
                d="M305.3,389.68l-0.09,1.7c0.31,1.12,1.67,1.12,2.32,1.08c0.03-0.54-0.06-1.08-0.35-1.55
					C306.77,390.26,306.05,389.92,305.3,389.68z"
              />
              <path
                className="st4"
                d="M314.36,389.25c-0.03,0.54-1.73,1.18-3.52,1.8c-1.54,0.53-3.08,1.09-4.39,0.85
					c-0.56-0.1-0.86-0.53-1.22-0.88l-0.02,0.36c0.36,1.3,2.14,1.09,2.57,1.06s4.96-1.53,4.96-1.53c0.68-0.34,1.3-0.71,1.5-1.05
					c0.22-0.39,0.25-0.66,0.16-0.85C314.38,389.1,314.36,389.16,314.36,389.25z"
              />
              <path
                className="st2"
                d="M299.92,384.9c-0.31,0.1-2.62,0.45-2.62,0.45l-2.95,1.49l-0.25,1.55c0.34,1.64,2.14,1.25,2.72,0.86
					c0.36-0.24,1.44-1.44,3.67-1.94c1.42-0.32,1.82-1.07,1.93-1.45C302.96,384.04,300.18,384.82,299.92,384.9z"
              />
              <path
                className="st9"
                d="M300.27,384.81c0.49,0.48,0.88,1.08,1.14,1.84c0.03,0.1,0.06,0.19,0.07,0.29c0.63-0.36,0.86-0.81,0.94-1.08
					C302.86,384.36,301.06,384.62,300.27,384.81z"
              />
              <path
                className="st9"
                d="M294.35,386.87l-0.25,1.52c0.29,1.39,1.63,1.32,2.38,1.03c-0.16-0.58-0.25-1.25-0.61-1.74
					C295.5,387.17,294.93,387,294.35,386.87z"
              />
              <path
                className="st4"
                d="M302.35,385.67c-0.34,0.74-1.07,1.09-1.82,1.31c-0.96,0.27-1.77,0.47-2.6,1.08
					c-0.77,0.57-1.52,1.07-2.54,0.82c-0.57-0.14-0.97-0.44-1.23-0.85l-0.06,0.36c0.34,1.64,2.14,1.25,2.72,0.86
					c0.36-0.24,1.44-1.44,3.67-1.94c1.42-0.32,1.82-1.07,1.93-1.45c0.07-0.25,0.08-0.45,0.05-0.61
					C302.45,385.39,302.41,385.53,302.35,385.67z"
              />
              <path
                className="st23"
                d="M296.59,342.4c-1,2.86-1.24,5.93-0.71,8.91l1.09,15.16c0,0-1.36,4.64-1.62,6.23
					c-0.57,3.5-1.01,14.14-1.01,14.14c2.54,1.3,2.97-1.17,2.97-1.17l4.27-17.07l1.79-22.87l-6.37-4.52L296.59,342.4z"
              />
              <path
                className="st24"
                d="M296.59,342.4c-1,2.86-1.24,5.93-0.71,8.91l0.57,7.94c0.12,0.03,0.25,0.07,0.38,0.15
					c4.71,2.86,2.38,10.13,1.77,14.33c-0.67,4.57-1.53,9.16-3.17,13.46c1.58,0.19,1.88-1.53,1.88-1.53l4.27-17.07l1.79-22.87
					L297,341.2L296.59,342.4z"
              />
              <path
                className="st23"
                d="M300.22,344.03l-3.73,7.87l4.66,3.2c0,0,4.34,12.18,3.93,13.16c-1.28,3.07,0.2,20.7,0.28,21.09
					c0.03,0.16,1.86,1.31,3.05-0.27l1.74-20.97c0.01-5.52-1.7-24.24-1.7-24.24L300.22,344.03z"
              />
              <path
                className="st24"
                d="M300.22,344.03l-3.73,7.87l4.66,3.2c0,0,1.13,3.17,2.18,6.4c0.44-0.43,1.16-0.58,1.73-0.03
					c3.86,3.69,2.94,9.89,2.72,14.72c-0.14,3.1-0.29,6.18-0.33,9.28c-0.02,1.48-0.08,2.95-0.26,4.4c0.42-0.08,0.85-0.3,1.22-0.79
					l1.74-20.97c0.01-5.52-1.7-24.24-1.7-24.24L300.22,344.03z"
              />
              <path
                className="st4"
                d="M296.04,343.43c0,0-0.38,2.39-0.51,3.16c-0.51,3.05-1.69,26.09-1.69,26.09s7.44,2.18,9.3,2.13
					c2.42-0.24,7.69-1.9,9.68-2.95c-0.5-6.84-4.37-27.98-4.37-27.98L296.04,343.43z"
              />
              <path
                className="st42"
                d="M305.7,343.78c2.45,6.87,2.52,15.13,2.51,22.21c0,2.59-0.14,5.17-0.38,7.74c1.98-0.6,3.94-1.32,5-1.88
					c-0.5-6.84-4.37-27.98-4.37-27.98L305.7,343.78z"
              />
              <path
                className="st25"
                d="M296.04,343.43c0,0-0.03,0.22-0.09,0.53c0.11,0,0.22,0.01,0.33,0.05c3.8,1.06,7.41,2.81,8.97,6.68
					c1.25,3.11,1.1,6.55,1.65,9.8c1.25-0.25,2.44-0.6,3.55-1.19c-0.5-6.84-2.01-15.42-2.01-15.42L296.04,343.43z"
              />
              <path
                className="st9"
                d="M304.7,319.67c-3.82-1.11-7.39-1.45-7.39-1.45c-3.12,0.97-2.56,5.49-2.7,6.24
					c-0.12,0.62,1.43,10.27,1.98,13.65c0.13,0.77,0.11,1.56-0.04,2.32l-0.83,4.26c0,0-0.79-0.07,4.68,1.14
					c5.48,1.2,8.36-0.45,8.36-0.45l-0.49-6.54l-0.44-7.3c0,0,1.46-1.35,1.92-3.18c0.21-0.83-0.54-1.72-1.98-3.65
					C307.13,323.87,307.19,320.39,304.7,319.67z"
              />
              <path
                className="st24"
                d="M306.81,321.84c-0.1,0.03-0.21,0.06-0.34,0.06c0.05,0.34-0.03,0.7-0.33,1.03
					c-2.22,2.35-3.15,5.34-3.03,8.68c0.12,3.26,0.69,5.69,1.06,8.92c0.22,1.98-0.07,3.98-0.83,5.71c3.55,0.2,5.41-0.86,5.41-0.86
					l-0.49-6.54l-0.44-7.3c0,0,1.46-1.35,1.92-3.18c0.21-0.83-0.54-1.72-1.98-3.65C307.41,324.25,307.27,323,306.81,321.84z"
              />
              <path
                className="st23"
                d="M298.24,318.31c0.02,1.64,4.7,2.26,4.68,0.62l1.14-6.05l-5.5,0.07L298.24,318.31z"
              />
              <path
                className="st23"
                d="M296.93,308.82l0.56,4.59c0.03,2.48,4.49,3.81,4.49,3.81c2.4-0.03,4.13-1.15,4.1-3.68l-0.08-6.37
					L296.93,308.82z"
              />
              <path
                className="st4"
                d="M303.87,304.07c-1.4-0.59-6.76-1.08-7.53,3.4c-2.22,12.95-1.19,13.27-1.19,13.27s4.92,2.21,6.01,1.33
					c1.5-1.21,2.65-8.16,2.82-10.09c0.26-2.94,2.82-3.39,2.82-3.39C306.47,305.63,305.85,304.91,303.87,304.07z"
              />
            </g>
          </g>
          <path
            className="st23"
            d="M312.52,347.09l-3.17-7.52c-0.53-0.07-1.26-10.82-1.79-10.83c-0.18,0-1.15-0.3-2.01-0.01
			c-0.65,0.22-1.24,0.78-1.39,2.06c-0.22,2,1.6,9.35,1.86,9.98c0.79,1.92,4.61,6.91,4.61,6.91c-0.7,0.9,0.46,2.89,0.78,3.63
			l0.06,0.51l-0.17,1.11c0,0-0.27,1.13,0.3,0.97l0.37-1.11l0.07,0.25c0,0-0.47,1.43,0.25,1.44l0.28-0.58
			c-0.07,0.41-0.06,0.93,0.37,0.94l0.4-0.83c0.18,0.47,0.48,0.33,0.48,0.33l0.22-1.97c0,0-0.02-0.06-0.04-0.15
			c0.22,0.17,0.41,0.06,0.41,0.06l-0.47-3.03L312.52,347.09z"
          />
          <path
            className="st9"
            d="M303.13,327c0.14,1.09,0.5,3.81,0.78,5.76c1.91,0.04,3.76-0.32,4.94-1.32c-0.18-3.44-1.12-8.85-1.48-9.43
			C304.24,316.97,302.72,323.67,303.13,327z"
          />
        </g>
        <g>
          <path
            className="st36"
            d="M276.84,368.81c-7.59-1.08-14.91-2.57-22.68-2.57c-1.12-0.07-2.29-0.13-3.3,0.34c-1,0.47-1.71,1.67-1.19,2.6
		c0.42,0.77,1.43,1.04,2.33,1.22c7.62,1.57,15.35,2.69,23.12,3.35c1.21,0.1,2.48,0.19,3.6-0.26s2.02-1.61,1.69-2.71
		C280.03,369.46,278.29,369.02,276.84,368.81z"
          />
          <g>
            <path
              className="st9"
              d="M255.24,361.58l-1.86-1.82c0,0-1.22,0.6-2.16,1.47c-0.24,0.22-0.45,0.71-0.56,1.02l-0.53,3.3l0.35,0.55h0.39
			l0.79-1.33c0.22,1.4,0.5,3.21,0.65,3.54c0.89,1.93,2.58,2.58,3.44,2.06c0.62-0.38,0.2-0.89-0.28-2.45L255.24,361.58z"
            />
            <g>
              <path
                className="st19"
                d="M257.05,328.08c0.01,1.22,0.5,4.61,0.5,4.61l2.26,14.34c-0.84,1-2.38,2.66-3.37,4.88
				c-1.59,3.57-2.4,6.67-3.07,7.86c0,0-0.4,0.2-0.91,0.52c-0.63,1.51,1,7.94,1.56,7.94c0.23,0,1.18-0.22,1.44-0.29
				c0-0.01-0.01-0.01-0.01-0.02l-0.22-6.34c0,0,6.13-7.01,9.86-11.63c0.37-0.45,0.58-1.32,0.49-2.01c0.77-5,1.8-14.4,1.8-14.4
				l3.33-8.37l-12.22-1.86C258.5,323.31,257.02,322.6,257.05,328.08z"
              />
              <path
                className="st37"
                d="M257.05,328.08c0.01,1.22,0.5,4.61,0.5,4.61l0.54,5.1c1.22-0.1,3.76,0.52,4.35,1.93
				c0.81,1.96,1.88,3.88,1.88,6.07c0,2.47-1.4,4.25-2.81,6.13c-2.34,3.13-4.61,6.62-7.32,9.53c-0.01,2.2-0.19,4.39-0.37,6.59
				c0.08,0.13,0.15,0.2,0.21,0.2c0.23,0,1.18-0.22,1.44-0.29c0-0.01-0.01-0.01-0.01-0.02l-0.22-6.34c0,0,6.13-7.01,9.86-11.63
				c0.37-0.45,0.58-1.32,0.49-2.01c0.77-5,1.8-14.4,1.8-14.4l3.33-8.37l-12.22-1.86C258.5,323.31,257.02,322.6,257.05,328.08z"
              />
              <path
                className="st19"
                d="M252.39,360.34c-0.03,0.02-0.06,0.04-0.09,0.06C252.3,360.41,252.35,360.37,252.39,360.34z"
              />
              <path
                className="st19"
                d="M252.47,360.29L252.47,360.29c0.01-0.01-0.03,0.02-0.08,0.05C252.42,360.32,252.44,360.31,252.47,360.29z"
              />
            </g>
            <path
              className="st38"
              d="M259.84,282.19c-2.5,2.09,0.67,8.85,0.67,8.85l0.87-1.66l2.5-7.03C263.88,282.35,262.34,280.1,259.84,282.19
			z"
            />
            <path
              className="st9"
              d="M278.89,370.04c-0.38-0.1-0.41-0.26-1.23-1.09c-1.37-1.38-3.32-4-3.58-5.16l-2.78,0.13
			c0,0-0.74,2.43-0.35,4.08c0,0,0.25,0.3,1.14,0.6l0.46,3.02l0.33-0.16l0.04-2.63c1.54,0.33,2.95,2.52,4.28,2.99
			c1.11,0.39,2.6,0.23,2.97,0.09c0,0,0.39-0.11,0.35-0.51C280.51,371.38,280.38,370.43,278.89,370.04z"
            />
            <path
              className="st4"
              d="M262.03,283.17l2.21-0.9C264.24,282.28,262.63,281.64,262.03,283.17z"
            />
            <path
              className="st19"
              d="M277.66,368.95c-1.37-1.38-3.32-3.99-3.58-5.16c0.1-1.42,0.44-5.51,1.61-15.12c0.16-1.27,0.37-3.2,0.5-4.51
			c0.13-0.5,0.17-1.03,0.1-1.51c-0.48-3.69-1.44-8.99-3.91-21.47l-7.15,10.3l4.7,13.87c-0.14,1.25-0.14,5.87-0.12,8.06
			c0.02,2.56,1.4,10.63,1.43,10.65c-0.05,0.18-0.13,0.48-0.2,0.87c0.06,0.19,0.08,0.23,0.17,0.41c0.06,0.13,0.12,0.25,0.18,0.38
			c0.89,1.49,2.47,2.09,3.83,3.06c1.31,0.93,2.51,1.49,3.77,1.28c-0.04-0.01-0.07-0.02-0.11-0.03
			C278.51,369.95,278.48,369.78,277.66,368.95z"
            />
            <path
              className="st37"
              d="M277.66,368.95c-1.37-1.38-3.32-3.99-3.58-5.16c0.1-1.42,0.44-5.51,1.61-15.12c0.16-1.27,0.37-3.2,0.5-4.51
			c0.13-0.5,0.17-1.03,0.1-1.51c-0.48-3.69-1.44-8.99-3.91-21.47l-5.8,10.27l2.03,5.99c0.94-0.46,2.1-0.42,3.05,0.57
			c2.57,2.65,3.32,5.7,2.92,9.32c-0.39,3.6-0.87,7.19-1.16,10.81c-0.3,3.73-0.15,6.76,2.1,9.84c0.44,0.61,0.9,1.2,1.34,1.78
			c0.71,0.3,1.4,0.44,2.12,0.32c-0.04-0.01-0.07-0.02-0.11-0.03C278.51,369.95,278.48,369.78,277.66,368.95z"
            />
            <g>
              <path
                className="st19"
                d="M279.52,321.31l-3.8-9.56c0,0-3.39-14.56-3.61-15.29c-0.34-1.12-2.92-3.05-5.56-0.91
				c0,0,0.24,1.16,1.64,5.12c1.2,3.39,3.19,12.85,3.19,12.85l6.4,9.09c-0.22,1.02,0.52,2.25,0.91,3.13l0.21,0.78l-0.01,1.17
				c0,0-0.11,1.19,0.45,0.95l0.22-1.19l0.1,0.24c0,0-0.27,1.53,0.47,1.44l0.2-0.63c-0.01,0.43,0.07,0.96,0.52,0.9l0.29-0.91
				c0.25,0.45,0.54,0.26,0.54,0.26l-0.06-2.05c0,0-0.03-0.06-0.07-0.15c0.25,0.15,0.43,0,0.43,0l-0.65-2.98L279.52,321.31z"
              />
              <path
                className="st39"
                d="M280.57,323.86c-1.92-1.06-1.78-2.33-2.28-3.05c-1.31-1.89-5.85-11.97-5.46-15.12
				c-1,0.05-2.01,0.1-3.01,0.11c1.07,3.57,1.57,7.72,1.57,7.72l6.4,9.09c-0.22,1.02,0.52,2.25,0.91,3.13l0.21,0.78l-0.01,1.17
				c0,0-0.11,1.19,0.45,0.95l0.22-1.19l0.1,0.24c0,0-0.27,1.53,0.47,1.44l0.2-0.63c-0.01,0.43,0.07,0.96,0.52,0.9l0.29-0.91
				c0.25,0.45,0.54,0.26,0.54,0.26l-0.06-2.05c0,0-0.03-0.06-0.07-0.15c0.09,0.05,0.16,0.06,0.23,0.06
				C281.02,325.34,281.08,325.24,280.57,323.86z"
              />
            </g>
            <path
              className="st10"
              d="M273.32,315.41l0.25-5.5c0,0,2.23-1.5,1.01-4.85l-2.07-3.28l-1.16-2.97c-0.21-0.89-0.09-2.06-0.25-2.65
			c-0.35-1.36-1.18-1.54-1.18-1.54s-0.22-0.15-0.93-0.21c-2.55-0.2-10,0.17-12.81,5.47c-1.08,2.03,1.61,8.05,2.13,15.93
			c0.02,0.3-0.84,4.31-1.33,7.48c0,0-0.63,6.1-0.51,9.25l-0.02,7.02c0,0,4.18,4.05,13,0.93c6.79-2.4,7.68-4.18,7.68-4.18
			l-2.86-16.63L273.32,315.41z"
            />
            <path
              className="st37"
              d="M260.61,301.34c-1.71,0.03-3.62-0.38-4.47-1.37c-0.97,2.13,1.66,8.06,2.17,15.83
			c0.02,0.3-0.84,4.31-1.33,7.48c0,0-0.63,6.1-0.51,9.25l-0.02,7.02c0,0,0.69,0.66,2.08,1.24c-1.47-5.27,1.47-19.19,2.75-23.89
			c-1.25-5.12-1.16-10.53,0.51-15.6C261.38,301.31,260.98,301.34,260.61,301.34z"
            />
            <path
              className="st19"
              d="M268.24,297.26c0.26-0.46-0.25-2.61-3.11-3.15c-1.84-0.35-2.93,1.29-2.93,1.29s-0.17,1.7,2.61,2.58
			c0.63,0.2,0.94,0.21,1.37,0.2c0.36-0.01,2.11,2.11,2.11,2.11S268.11,297.49,268.24,297.26z"
            />
            <path
              className="st19"
              d="M262.86,292.47c0.2,1.34-0.42,2.7-0.42,2.7l5.02,1.92c0.22-1.71,0.07-5.13,0.07-5.13L262.86,292.47z"
            />
            <path
              className="st40"
              d="M262.86,292.47c0.04,0.25,0.04,0.5,0.03,0.75c0.38,1.72,1.76,2.84,3.46,3.03c0.38-0.13,0.79-0.27,1.21-0.43
			c0.07-1.74-0.02-3.86-0.02-3.86L262.86,292.47z"
            />
            <path
              className="st19"
              d="M260.69,286.13l0.53,3.63c0.81,4.6,4.95,5.08,4.95,5.08c2.48,0.66,3.55-1.82,3.55-4.39l-0.28-5.88
			L260.69,286.13z"
            />
            <path
              className="st19"
              d="M259.41,298.41c-0.69-0.14-2.79,0.69-3.11,1.32c-0.4,0.8-1.04,2.22-1.83,4.49
			c-1.38,3.97-1.92,10.13-1.92,10.13s-0.09,0.98-0.19,2.01c-0.08,0.84,0.35,8.86,0.24,11.76l-0.03-0.04l-0.67,4.14l0.35,1.52
			l0.95,0.89c0,0,0.4,0.63,0.46,0.74c0.19,0.38,0.66,0.36,0.62-0.07l-0.01-0.45l0.04-1.18l0.7-1.67l-0.06,1.47
			c0.24,1.08,0.53,0.93,0.53,0.93l0.45-3.26l-0.97-3.41c0,0,1.25-8.75,2.02-11.92c0,0,4.8-11.71,5.02-12.44
			C262.19,302.73,262.15,298.95,259.41,298.41z"
            />
            <path
              className="st39"
              d="M261.39,300.26c0.14,0.47,0.17,0.99,0.02,1.57l-0.46,1.76c-0.1,0.38-0.25,0.69-0.43,0.95
			c-1.05,3.29-2.77,6.24-4.58,9.25c-0.19,0.32-0.42,0.57-0.66,0.77c-0.23,0.75,0.18,2.46-0.8,11.85c-0.13,1.29,0.12,2.58,0.28,3.87
			c0.72,1.24,0.94,2.65,0.67,4.13c0.03,0,0.05-0.01,0.05-0.01l0.45-3.26l-0.97-3.41c0,0,1.25-8.75,2.02-11.92
			c0,0,4.8-11.71,5.02-12.44c0.13-0.44,0.12-2.1-0.64-3.26C261.38,300.15,261.39,300.21,261.39,300.26z"
            />
            <path
              className="st38"
              d="M262.9,282.7c-1.05,0.56-3.01,2.16-2.21,6.54c0.39,2.15,2.19,3.89,2.19,3.89l-0.01-0.66
			c0,0,0.27-2.92,0.48-3.52c0.12-0.36,0.53-0.8,1.01-0.64c2.77,0.88,5.72-0.4,5.72-0.4c0.71-2.02-0.4-3.57-1.13-4.26
			C266.81,281.61,263.94,282.14,262.9,282.7z"
            />
            <path
              className="st19"
              d="M262.81,288.88c-0.77,0.03-0.71,0.65-0.39,1.29c0.53,1.05,1.04,0.85,1.04,0.85l0.17-1.84
			C263.62,289.19,263.57,288.86,262.81,288.88z"
            />
            <path
              className="st2"
              d="M262.72,294.36l-1.14,1.32c0.45,3.17,3.82,4.34,3.82,4.34l0.79-1.84
			C264.43,297.78,262.72,294.36,262.72,294.36z"
            />
            <path
              className="st2"
              d="M267.86,294.78h-0.27l-0.01,0.58c0.73,1.05,0.67,1.9,0.67,1.9l1.21-0.67
			C269.08,295.72,267.86,294.78,267.86,294.78z"
            />
            <polygon
              className="st39"
              points="268.24,297.26 269.59,298.06 269.45,296.58 		"
            />
            <polygon
              className="st9"
              points="270.94,368 271.92,371.45 272.54,371.62 272.5,368.65 		"
            />
            <path
              className="st39"
              d="M262.15,308.92c2.58,3.95,6.93,0.77,6.93,0.77C265.54,311.29,262.15,308.92,262.15,308.92z"
            />
          </g>
        </g>
      </g>
      <g id="ludzie_3a" ref={firstPhoneRef}>
        <linearGradient
          id="SVGID_2_"
          gradientUnits="userSpaceOnUse"
          x1="391.2074"
          y1="187.4281"
          x2="361.5408"
          y2="167.7614"
          gradientTransform="matrix(1 0 0 -1 0 538)"
        >
          <stop offset="0" className="st51" />
          <stop offset="1" className="st53" />
        </linearGradient>
        <polygon
          className="st41"
          points="323.04,364.31 369.22,390.63 418.32,364.05 370.82,336.24 	"
        />
        <g>
          <g className="st3">
            <g>
              <path
                className="st6"
                d="M353.95,279.06l-12.22-7.05c-0.52-0.3-0.93-0.06-0.94,0.54v0.07c0,0.59,0.41,1.31,0.93,1.61l12.22,7.05
					c0.52,0.3,0.94,0.06,0.94-0.53v-0.07C354.89,280.08,354.47,279.36,353.95,279.06z"
              />
            </g>
            <g>
              <path
                className="st2"
                d="M366.7,281.89l-41.11-23.74c-1.39-0.8-2.52-0.16-2.53,1.44l-0.29,103.79c0,1.6,1.12,3.55,2.51,4.35
					l41.11,23.74c1.39,0.8,2.52,0.16,2.53-1.44l0.29-103.79C369.22,284.64,368.1,282.69,366.7,281.89z M340.8,272.55
					c0-0.6,0.42-0.83,0.94-0.54l12.22,7.05c0.52,0.3,0.93,1.02,0.93,1.62v0.07c0,0.59-0.42,0.83-0.94,0.53l-12.22-7.05
					c-0.52-0.3-0.93-1.02-0.93-1.61V272.55z M338.29,270.02c0.5,0.29,0.9,1.02,0.9,1.63c0,0.61-0.41,0.87-0.91,0.59
					c-0.5-0.29-0.91-1.02-0.9-1.63C337.38,269.99,337.79,269.73,338.29,270.02z M367.26,383.11l-42.8-24.71l0.25-89.43l42.8,24.71
					L367.26,383.11z"
              />
            </g>
            <g>
              <path
                className="st6"
                d="M338.29,270.02c-0.5-0.29-0.91-0.03-0.91,0.58s0.4,1.34,0.9,1.63s0.91,0.03,0.91-0.59
					C339.19,271.04,338.79,270.31,338.29,270.02z"
              />
            </g>
            <g>
              <polygon
                className="st6"
                points="324.46,358.4 367.26,383.11 367.51,293.68 324.72,268.97 				"
              />
            </g>
            <path
              className="st4"
              d="M370.18,279.87l-41.11-23.74c-0.7-0.4-1.33-0.44-1.79-0.17l-3.48,2.02c0.46-0.27,1.09-0.23,1.79,0.17
				l41.11,23.74c1.39,0.8,2.52,2.75,2.51,4.35l-0.29,103.79c0,0.8-0.28,1.35-0.73,1.62l3.48-2.02c0.45-0.26,0.73-0.82,0.73-1.62
				l0.29-103.79C372.7,282.62,371.58,280.67,370.18,279.87z"
            />
          </g>
        </g>
        <g className="st43">
          <polygon
            className="st2"
            points="367.26,383.11 367.51,293.68 324.72,268.97 		"
          />
        </g>
        <g>
          <g>
            <g className="st3">
              <g>
                <path
                  className="st2"
                  d="M348.21,318.2c-5.26-3.04-9.53-0.6-9.55,5.44s4.23,13.4,9.49,16.43c5.25,3.03,9.53,0.6,9.55-5.44
						C357.71,328.59,353.46,321.23,348.21,318.2z"
                />
              </g>
            </g>
          </g>
          <g>
            <g className="st3">
              <g>
                <path
                  className="st2"
                  d="M348.21,344.9c-5.26-3.04-9.53-0.6-9.55,5.44s4.23,13.4,9.49,16.43c5.25,3.03,9.53,0.6,9.55-5.44
						C357.71,355.29,353.46,347.93,348.21,344.9z"
                />
              </g>
            </g>
          </g>
          <g>
            <g>
              <g className="st3">
                <g>
                  <ellipse
                    transform="matrix(0.8646 -0.5025 0.5025 0.8646 -104.809 215.9141)"
                    className="st2"
                    cx="348.19"
                    cy="302.41"
                    rx="7.77"
                    ry="13.42"
                  />
                </g>
              </g>
            </g>
          </g>
          <g>
            <g>
              <path
                className="st9"
                d="M350.99,325.64c-1.55-0.89-2.81-0.04-2.81,1.91c0.01-1.95-1.25-4.25-2.79-5.14
					c-1.55-0.89-2.81-0.04-2.81,1.91c0,0.65,0.15,1.4,0.41,2.19l0.83,0.48l1.49,0.86l0.08,0.05l0.03,0.12l0.2,0.66l0.1,0.33
					l0.1,0.33c0.05,0.16,0.09,0.31,0.14,0.47l0.06-0.36l0.09-0.51l0.04-0.26l0.04-0.26l0.09-0.51l0.05-0.26l0.05-0.25l0.1-0.51
					l0.05-0.25l0.05-0.25l0.15-0.77l0.28,0.96l0.2,0.68l0.2,0.68c0.03,0.11,0.07,0.23,0.1,0.34l0.1,0.34l0.09,0.34l0.05,0.17
					l0.35,0.21l0.49,0.29l0.98,0.58l0.98,0.59l0.98,0.59c0.33,0.2,0.65,0.4,0.98,0.6l0.49,0.3l0.38,0.23
					c0.26-0.5,0.42-1.07,0.42-1.72C353.79,328.84,352.54,326.54,350.99,325.64z"
              />
            </g>
            <g>
              <path
                className="st9"
                d="M352.49,332.84c-0.33-0.18-0.65-0.36-0.98-0.54l-0.98-0.54l-0.98-0.55l-0.98-0.55l-0.49-0.27l-0.49-0.28
					l-0.13-0.07l-0.05-0.18l-0.11-0.34l-0.11-0.34l-0.1-0.34c-0.03-0.11-0.07-0.23-0.1-0.34l-0.12-0.4l-0.05,0.26l-0.05,0.25
					l-0.05,0.25l-0.1,0.5l-0.05,0.25l-0.05,0.25l-0.1,0.5l-0.11,0.5c-0.04,0.17-0.07,0.33-0.1,0.5l-0.15,0.66l-0.21-0.83l-0.17-0.65
					c-0.06-0.22-0.11-0.44-0.17-0.65l-0.08-0.33l-0.08-0.33l-0.14-0.54l-1.39-0.86l-0.74-0.46c1.36,3.93,4.9,8.59,5.09,8.83
					c0.19-0.03,3.75-0.59,5.13-2.94l-0.3-0.16L352.49,332.84z"
              />
            </g>
          </g>
          <g>
            <g className="st3">
              <g>
                <path
                  className="st10"
                  d="M348.21,351.25c-0.02-0.01-0.04-0.02-0.06-0.03l-0.43-0.96l-0.14-0.31c-0.03-0.06-0.07-0.11-0.12-0.14
						c-0.03-0.02-0.05-0.02-0.08-0.02c-0.06,0.01-0.08,0.11-0.04,0.2l0.04,0.08l0.52,1.17c-0.09,0.09-0.11,0.27-0.03,0.47
						c0.07,0.18,0.2,0.33,0.33,0.41c0.07,0.04,0.13,0.06,0.19,0.05c0.18-0.02,0.24-0.25,0.14-0.53
						C348.46,351.48,348.34,351.33,348.21,351.25z"
                />
              </g>
              <g>
                <path
                  className="st10"
                  d="M352.25,353.41c-0.14-0.12-0.76-0.62-1.63-1.25c-0.19-1.46-1.2-3.07-2.42-3.77
						c-1.22-0.7-2.23-0.26-2.43,0.97c-0.87-0.38-1.51-0.59-1.65-0.64c-0.54-0.29-0.97-0.04-0.97,0.58v0.04l0.91,8.87
						c0.01,0.62,0.44,1.35,1,1.67l6.2,3.58c0.56,0.32,0.97,0.08,0.99-0.53l0.94-7.82C353.2,354.48,352.79,353.74,352.25,353.41z
						 M345.46,359.46l-0.38-0.22c-0.25-0.15-0.44-0.46-0.44-0.73v-0.04l-0.91-8.88c0.01-0.26,0.2-0.36,0.44-0.22l0.03,0.02
						l0.03,0.02c0.01,0,0.63,0.2,1.52,0.59c0.03,0.66,0.25,1.36,0.59,2.01c-0.64-0.26-1.03-0.39-1.05-0.4
						c-0.32-0.19-0.59-0.04-0.59,0.33L345.46,359.46z M349.35,353.64c-0.32,0.1-0.72,0.04-1.15-0.21s-0.83-0.65-1.15-1.11
						c-0.43-0.63-0.72-1.39-0.74-2.07c0-0.04-0.01-0.07-0.01-0.11c0-0.19,0.02-0.36,0.06-0.51c0.21-0.83,0.96-1.1,1.84-0.59
						s1.62,1.64,1.83,2.71c0.04,0.2,0.06,0.39,0.06,0.58c0,0.03,0,0.06,0,0.1C350.07,353.08,349.78,353.51,349.35,353.64z
						 M351.68,362.57c0,0.29-0.16,0.4-0.41,0.26l-0.4-0.23l0.82-6.6c0-0.37-0.26-0.83-0.58-1.02c-0.02-0.02-0.41-0.34-1.04-0.81
						c0.34-0.26,0.56-0.72,0.59-1.33c0.9,0.65,1.51,1.16,1.52,1.17l0.03,0.02l0.03,0.02c0.24,0.14,0.42,0.45,0.42,0.73
						L351.68,362.57z"
                />
              </g>
            </g>
          </g>
          <g>
            <g>
              <path
                className="st6"
                d="M349.98,302.76l-2.13-4.49c-0.15-0.3-0.23-0.62-0.23-0.89l0.01-1.89c-0.61-0.22-1.12-0.18-1.12,1.23
					l0.09,2.32c0.01,0.12-0.03,0.21-0.1,0.26c-0.07,0.05-0.16,0.04-0.26-0.01l-2.76-1.59c-0.35-0.2-0.65-0.07-0.67,0.3
					c-0.02,0.35,0.2,0.81,0.51,1.06c0.17,0.14,0.3,0.39,0.3,0.59c0,0.2-0.13,0.31-0.3,0.25c-0.3-0.1-0.51,0.08-0.51,0.42
					c0,0.37,0.24,0.82,0.55,1.05c0.15,0.11,0.28,0.31,0.31,0.51c0.04,0.19-0.02,0.34-0.15,0.37c-0.17,0.04-0.26,0.22-0.23,0.47
					c0.04,0.32,0.28,0.69,0.57,0.86l0.05,0.03c0.17,0.1,0.31,0.31,0.36,0.52c0.04,0.21-0.02,0.37-0.16,0.39
					c-0.15,0.02-0.25,0.18-0.25,0.4c0,0.34,0.22,0.75,0.48,0.9l3.43,1.98c0.59,0.34,1.41,0.43,1.87,0.2
					c0.07-0.04,0.16-0.02,0.26,0.03l2.49,1.44l0.01-5.21l-2.09-1.13C350.16,303.03,350.05,302.9,349.98,302.76z"
              />
            </g>
          </g>
        </g>
      </g>
      <g id="ludzie_3b" ref={secondPhoneRef}>
        <g className="st44">
          <polygon
            className="st18"
            points="320.6,287.69 336.43,296.82 336.5,264.05 320.6,254.87 		"
          />
        </g>
        <g>
          <polygon
            className="st2"
            points="296.98,271.75 333.1,292.6 333.49,253.37 297.37,232.51 		"
          />
        </g>
        <g>
          <polygon
            className="st9"
            points="299.21,241.33 313.83,250.1 313.83,248.15 299.21,239.38 		"
          />
        </g>
        <g>
          <polygon
            className="st6"
            points="299.21,249.5 313.83,258.27 313.83,256.78 299.21,248.01 		"
          />
        </g>
        <g>
          <polygon
            className="st6"
            points="299.21,253.19 313.83,261.96 313.83,260.47 299.21,251.7 		"
          />
        </g>
        <g>
          <polygon
            className="st6"
            points="299.21,256.64 313.83,265.41 313.83,263.92 299.21,255.15 		"
          />
        </g>
        <g>
          <g>
            <path
              className="st2"
              d="M320.19,300.99c-0.47-0.29-1.09-0.14-1.38,0.33s-0.14,1.09,0.33,1.38l0.04,0.03c0.16,0.1,0.33,0.14,0.5,0.14
				c0.33,0,0.66-0.17,0.85-0.49C320.82,301.91,320.66,301.28,320.19,300.99z"
            />
          </g>
          <g>
            <path
              className="st2"
              d="M313.34,296.79c-0.48-0.29-1.09-0.13-1.37,0.34c-0.29,0.47-0.13,1.09,0.34,1.37l0.08,0.05
				c0.16,0.09,0.32,0.14,0.48,0.14c0.33,0,0.66-0.18,0.85-0.5C314,297.72,313.81,297.08,313.34,296.79z"
            />
            <path
              className="st2"
              d="M316.76,298.89c-0.47-0.29-1.08-0.14-1.38,0.33c-0.29,0.47-0.14,1.09,0.33,1.38l0.08,0.05
				c0.16,0.09,0.32,0.14,0.49,0.14c0.33,0,0.65-0.18,0.84-0.5C317.42,299.82,317.23,299.18,316.76,298.89z"
            />
          </g>
          <g>
            <path
              className="st2"
              d="M309.44,294.55c-0.55,0-1,0.47-1,1.02c0,0.35,0.18,0.67,0.48,0.85l0.04,0.03c0.16,0.1,0.33,0.14,0.5,0.14
				c0.33,0,0.66-0.17,0.85-0.49c0.14-0.23,0.17-0.5,0.11-0.75C310.35,294.89,309.93,294.55,309.44,294.55z"
            />
          </g>
          <g>
            <path
              className="st2"
              d="M309.44,287.14c-0.55,0-1,0.45-1,1v0.09c0,0.55,0.45,0.96,1,0.96s1-0.49,1-1.04S309.99,287.14,309.44,287.14
				z"
            />
            <path
              className="st2"
              d="M309.44,290.83c-0.55,0-1,0.45-1,1v0.09c0,0.55,0.45,0.95,1,0.95s1-0.49,1-1.04
				C310.44,291.28,309.99,290.83,309.44,290.83z"
            />
            <path
              className="st2"
              d="M309.44,283.45c-0.55,0-1,0.45-1,1v0.09c0,0.55,0.45,0.96,1,0.96s1-0.49,1-1.04
				C310.44,283.9,309.99,283.45,309.44,283.45z"
            />
          </g>
          <g>
            <path
              className="st2"
              d="M309.44,279.8c-0.55,0-1,0.45-1,1v0.05c0,0.55,0.45,0.97,1,0.97s1-0.47,1-1.02S309.99,279.8,309.44,279.8z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              className="st2"
              d="M296.23,237.29c-0.48-0.29-1.09-0.13-1.37,0.34c-0.29,0.47-0.13,1.09,0.34,1.37l0.04,0.03
				c0.16,0.1,0.33,0.14,0.5,0.14c0.34,0,0.66-0.18,0.85-0.49C296.88,238.2,296.7,237.57,296.23,237.29z"
            />
          </g>
          <g>
            <path
              className="st2"
              d="M283.43,229.4c-0.47-0.29-1.09-0.14-1.38,0.33s-0.14,1.09,0.33,1.38l0.08,0.05c0.16,0.1,0.33,0.14,0.49,0.14
				c0.33,0,0.65-0.18,0.84-0.5C284.09,230.33,283.9,229.69,283.43,229.4z"
            />
            <path
              className="st2"
              d="M286.63,231.38c-0.47-0.29-1.09-0.14-1.38,0.33s-0.14,1.09,0.33,1.38l0.08,0.05
				c0.16,0.1,0.33,0.14,0.49,0.14c0.33,0,0.65-0.18,0.84-0.5C287.29,232.3,287.1,231.66,286.63,231.38z"
            />
            <path
              className="st2"
              d="M289.83,233.35c-0.47-0.29-1.09-0.14-1.38,0.33s-0.14,1.09,0.33,1.38l0.08,0.05
				c0.16,0.1,0.33,0.14,0.49,0.14c0.33,0,0.65-0.18,0.84-0.5C290.49,234.27,290.31,233.63,289.83,233.35z"
            />
            <path
              className="st2"
              d="M293.04,235.32c-0.47-0.29-1.09-0.14-1.38,0.33s-0.14,1.09,0.33,1.38l0.08,0.05
				c0.16,0.1,0.33,0.14,0.49,0.14c0.33,0,0.65-0.18,0.84-0.5C293.69,236.25,293.51,235.61,293.04,235.32z"
            />
          </g>
          <g>
            <path
              className="st2"
              d="M280.27,227.45c-0.31-0.19-0.7-0.17-1.01,0c-0.32,0.18-0.51,0.54-0.51,0.9c0,0.55,0.45,0.97,1,0.97
				c0.03,0,0.06,0,0.09,0c0.31-0.02,0.61-0.19,0.78-0.48C280.91,228.37,280.74,227.74,280.27,227.45z"
            />
          </g>
          <g>
            <path
              className="st2"
              d="M279.74,250.53c-0.55,0-1,0.5-1,1.05s0.45,1,1,1s1-0.45,1-1v-0.09C280.74,250.93,280.3,250.53,279.74,250.53
				z"
            />
            <path
              className="st2"
              d="M279.74,258.27c-0.55,0-1,0.5-1,1.05s0.45,1,1,1s1-0.45,1-1v-0.09C280.74,258.67,280.3,258.27,279.74,258.27
				z"
            />
            <path
              className="st2"
              d="M279.74,246.66c-0.55,0-1,0.49-1,1.05c0,0.55,0.45,1,1,1s1-0.45,1-1v-0.09
				C280.74,247.06,280.3,246.66,279.74,246.66z"
            />
            <path
              className="st2"
              d="M279.74,254.4c-0.55,0-1,0.49-1,1.05c0,0.55,0.45,1,1,1s1-0.45,1-1v-0.09
				C280.74,254.8,280.3,254.4,279.74,254.4z"
            />
            <path
              className="st2"
              d="M279.74,231.18c-0.55,0-1,0.49-1,1.05c0,0.55,0.45,1,1,1s1-0.45,1-1v-0.09
				C280.74,231.58,280.3,231.18,279.74,231.18z"
            />
            <path
              className="st2"
              d="M279.74,235.05c-0.55,0-1,0.49-1,1.05c0,0.55,0.45,1,1,1s1-0.45,1-1V236
				C280.74,235.45,280.3,235.05,279.74,235.05z"
            />
            <path
              className="st2"
              d="M279.74,238.92c-0.55,0-1,0.49-1,1.05c0,0.55,0.45,1,1,1s1-0.45,1-1v-0.09
				C280.74,239.32,280.3,238.92,279.74,238.92z"
            />
            <path
              className="st2"
              d="M279.74,242.79c-0.55,0-1,0.5-1,1.05s0.45,1,1,1s1-0.45,1-1v-0.09C280.74,243.19,280.3,242.79,279.74,242.79
				z"
            />
          </g>
          <g>
            <path
              className="st2"
              d="M279.74,262.12c-0.55,0-1,0.47-1,1.03c0,0.35,0.23,0.71,0.53,0.89c0.16,0.09,0.33,0.14,0.49,0.14
				c0.34,0,0.67-0.18,0.86-0.5c0.13-0.23,0.16-0.49,0.1-0.74C280.65,262.46,280.24,262.12,279.74,262.12z"
            />
          </g>
          <g>
            <path
              className="st2"
              d="M284.2,264.73l-0.09-0.06c-0.47-0.29-1.09-0.15-1.38,0.32s-0.15,1.09,0.32,1.38l0.09,0.06
				c0.16,0.1,0.35,0.15,0.53,0.15c0.33,0,0.66-0.17,0.85-0.47C284.82,265.64,284.67,265.02,284.2,264.73z"
            />
            <path
              className="st2"
              d="M291.98,269.54l-0.1-0.06c-0.47-0.29-1.09-0.14-1.38,0.33s-0.14,1.09,0.33,1.38l0.1,0.06
				c0.16,0.1,0.34,0.15,0.52,0.15c0.34,0,0.67-0.17,0.85-0.48C292.6,270.45,292.45,269.83,291.98,269.54z"
            />
            <path
              className="st2"
              d="M288.09,267.14l-0.09-0.06c-0.47-0.29-1.09-0.15-1.38,0.32s-0.15,1.09,0.32,1.38l0.09,0.06
				c0.16,0.1,0.35,0.15,0.53,0.15c0.34,0,0.66-0.17,0.85-0.47C288.71,268.04,288.56,267.43,288.09,267.14z"
            />
          </g>
          <g>
            <path
              className="st2"
              d="M295.82,271.92l-0.04-0.03c-0.47-0.29-1.06-0.13-1.35,0.34s-0.12,1.1,0.35,1.39
				c0.16,0.1,0.34,0.15,0.52,0.15c0.34,0,0.67-0.17,0.85-0.48C296.44,272.82,296.29,272.21,295.82,271.92z"
            />
          </g>
        </g>
        <g>
          <polygon
            className="st4"
            points="324.39,284.75 330.83,288.47 330.85,281.07 324.41,277.35 		"
          />
        </g>
        <g>
          <polygon
            className="st10"
            points="307.42,274.96 313.86,278.68 313.88,271.27 307.44,267.55 		"
          />
        </g>
        <g>
          <polygon
            className="st6"
            points="298.93,270.06 305.38,273.78 305.4,266.38 298.96,262.66 		"
          />
        </g>
        <g>
          <polygon
            className="st6"
            points="315.9,279.86 322.35,283.58 322.37,276.17 315.92,272.45 		"
          />
        </g>
        <g>
          <g className="st31">
            <g className="st3">
              <g>
                <path
                  className="st18"
                  d="M298.24,262.11c-0.5-0.29-1.14-0.52-1.14-0.55c0-0.08-0.04,10.52-0.04,10.52s7.39,3.94,9.48,5.18
						c0.01-0.16,0.03-0.55,0.03-0.72C306.58,271.23,302.85,264.77,298.24,262.11z"
                />
              </g>
            </g>
          </g>
          <g>
            <g className="st3">
              <g>
                <path
                  className="st10"
                  d="M295.82,263.44c-4.43-2.55-8.02-0.5-8.04,4.58c-0.01,5.08,3.56,11.28,7.98,13.83s8.02,0.5,8.04-4.58
						C303.82,272.19,300.24,265.99,295.82,263.44z"
                />
              </g>
            </g>
          </g>
          <g>
            <path
              className="st2"
              d="M297.37,269.66v-0.56c0-0.89-0.62-1.97-1.4-2.42l-0.3-0.18c-0.78-0.45-1.4-0.09-1.41,0.8v0.56l-1.41-0.81
				l-0.35,8.12l6.58,3.8l-0.31-8.5L297.37,269.66z M296.88,269.38l-2.13-1.23v-0.56c0-0.58,0.41-0.81,0.91-0.52l0.3,0.18
				c0.5,0.29,0.91,1,0.91,1.57L296.88,269.38z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              className="st45"
              d="M319.33,252.44c-0.01,0.04-0.02,0.09-0.03,0.13c0.01-0.05,0.02-0.1,0.03-0.15
				C319.34,252.43,319.34,252.44,319.33,252.44z"
            />
            <path
              className="st45"
              d="M319.2,253.06c0.03-0.17,0.07-0.33,0.1-0.49c-0.04,0.15-0.08,0.3-0.1,0.46V253.06z"
            />
          </g>
          <g>
            <polygon
              className="st45"
              points="319.2,253.06 319.2,253.04 319.2,253.07 			"
            />
          </g>
          <g>
            <path
              className="st2"
              d="M322.91,256.44c-1.63-1.88-2.32-2.49-2.36-2.56c-0.02,0.08-0.48,0.9-0.48,2.63c0,0.96,0.12,1.98,0.5,2.84
				c0.02,0.04,0.36,0.79,0.9,1.61c0.05,0.07,0.1,0.14,0.14,0.21c0.35,0.5,0.77,0.99,1.21,1.28l0.18,0.1
				c0.32,0.16,0.63,0.2,0.91,0.17c0.07-0.01,0.14-0.02,0.21-0.03c0.68-0.14,1.12-0.61,1.14-0.63c0.51-0.58,0.6-1.67,0.61-2.36
				c0-0.38-0.02-1.82-0.03-1.84C325.73,257.78,323.54,257.17,322.91,256.44z"
            />
          </g>
          <g>
            <path
              className="st46"
              d="M324.12,262.71c-0.07,0.01-0.14,0.03-0.21,0.03c-0.28,0.03-0.59-0.01-0.91-0.17l-0.18-0.1
				c-0.44-0.29-0.86-0.78-1.21-1.28c-0.05-0.07-0.1-0.14-0.14-0.21c-0.01,0.34-0.09,1.58-0.42,1.69c-0.03,0.01-0.71,0.09-1.41,0.09
				c0,0.01,0.5,1.43,3.14,2.95c2.34,1.35,3.06,0.62,3.07,0.6c-0.67-0.77-1.3-1.58-1.33-1.63
				C324.24,264.25,324.15,263.22,324.12,262.71z"
            />
          </g>
          <g>
            <path
              className="st9"
              d="M328.48,268.62l-0.73-0.64l-1.91-1.69c-0.02,0.02-0.73,0.75-3.07-0.6c-2.63-1.52-3.13-2.93-3.14-2.95
				l-1.08-0.17l-1.62-0.26c-0.61-0.05-1.09,0.25-1.32,0.83c-0.55,1.37-0.58,4.41-0.76,4.76c2.91,1.68,5.33,3.08,8.07,4.66
				c2.01,1.16,3.63,2.11,5.55,3.22c0.73,0.42,1.5,0.87,2.36,1.35c-0.3-0.91-0.17-4.14-0.92-6.3
				C329.62,270,329.1,269.2,328.48,268.62z"
            />
          </g>
          <g>
            <path
              className="st4"
              d="M324.35,252.95c-0.63-0.57-1.17-0.96-1.62-1.22c-1.68-0.97-3.2,0.06-3.4,0.71c-0.05,0.2-0.1,0.41-0.13,0.62
				c-0.12,0.83-0.5,7.16-0.64,9.51l1.08,0.17c0.69,0.01,1.37-0.08,1.41-0.09c0.32-0.11,0.4-1.34,0.42-1.69
				c-0.54-0.82-0.88-1.57-0.9-1.61c-0.38-0.86-0.5-1.89-0.5-2.84c0-1.74,0.14-1.87,0.16-1.94c0.86,1.78,3.05,2.76,5.62,3.63
				c0,0.01,0.03,1.13,0.03,1.51c0,0.69-0.09,1.78-0.61,2.36c-0.02,0.02-0.47,0.49-1.14,0.63c0.03,0.51,0.12,1.54,0.4,1.95
				c0.03,0.04,0.66,0.85,1.33,1.63l1.91,1.69c-0.05-1.35-0.19-5.23-0.2-6.63C327.56,259.53,327.76,256.01,324.35,252.95z"
            />
          </g>
        </g>
        <g>
          <path
            className="st2"
            d="M289.89,244.59l-15.97-9.22c-0.67-0.38-1.21-0.08-1.21,0.69l-0.03,11.49c0,0.76,0.53,1.69,1.2,2.08
			l15.97,9.22c0.67,0.38,1.21,0.08,1.21-0.69l0.03-11.49C291.09,245.9,290.56,244.97,289.89,244.59z M277.41,249.83l-3.11-1.8v-1.64
			l3.11,1.8V249.83z M277.42,246.56l-3.11-1.8v-0.88l3.11,1.8V246.56z M281.43,248.88l-3.35-1.94v-0.88l3.35,1.94V248.88z
			 M285.45,251.2l-3.35-1.94v-0.88l3.35,1.94V251.2z M289.46,253.51l-3.35-1.94v-0.88l3.35,1.94V253.51z M289.47,251
			c0,0.38-0.29,0.52-0.64,0.32l-2.08-1.2c-0.35-0.2-0.64-0.68-0.64-1.06v-1.23c0-0.38,0.29-0.52,0.64-0.32l2.08,1.2
			c0.35,0.2,0.63,0.67,0.63,1.05L289.47,251z"
          />
        </g>
        <g>
          <g>
            <path
              className="st34"
              d="M303.1,290.29c-0.89-0.51-1.62-0.1-1.62,0.92c0,1.02,0.72,2.27,1.61,2.79c0.89,0.51,1.61,0.1,1.62-0.92
				C304.71,292.05,303.99,290.8,303.1,290.29z"
            />
          </g>
          <g>
            <path
              className="st35"
              d="M297.56,287.09c-0.89-0.52-1.62-0.1-1.62,0.92c0,1.02,0.72,2.27,1.61,2.79c0.89,0.51,1.61,0.1,1.62-0.92
				C299.17,288.85,298.45,287.6,297.56,287.09z"
            />
          </g>
          <g>
            <path
              className="st2"
              d="M292.01,283.89c-0.89-0.51-1.62-0.1-1.62,0.92c0,1.02,0.72,2.27,1.61,2.79c0.89,0.51,1.62,0.1,1.62-0.92
				C293.63,285.65,292.91,284.41,292.01,283.89z"
            />
          </g>
        </g>
        <path
          className="st29"
          d="M329.48,301.82c-4.05-2.34-7.34-0.46-7.35,4.19s3.26,10.31,7.3,12.65c4.05,2.34,7.34,0.46,7.35-4.19
		C336.8,309.82,333.53,304.15,329.48,301.82z"
        />
        <g>
          <g>
            <path
              className="st2"
              d="M327.14,306.1c-0.85-0.49-1.54-0.1-1.54,0.88c0,0.97,0.68,2.16,1.53,2.65s1.54,0.1,1.54-0.88
				C328.67,307.78,327.98,306.59,327.14,306.1z"
            />
          </g>
          <g>
            <path
              className="st2"
              d="M329.18,309.11c0,1.3-0.93,1.83-2.06,1.17c-1.14-0.66-2.06-2.25-2.05-3.55c0-0.38,0.08-0.7,0.23-0.94
				l-1.45-0.84l-0.01,3.12c0,0.74,0.52,1.64,1.16,2.01l6.23,3.6c0.64,0.37,1.16,0.07,1.17-0.66l0.01-3.12l-3.43-1.98
				C329.1,308.32,329.18,308.73,329.18,309.11z"
            />
          </g>
          <g>
            <path
              className="st9"
              d="M328.14,299.63c-4.05-2.34-7.34-0.46-7.35,4.19s3.26,10.31,7.3,12.65c4.05,2.34,7.34,0.46,7.35-4.19
				C335.46,307.63,332.19,301.97,328.14,299.63z M332.39,313.02c0,0.74-0.52,1.04-1.17,0.66l-6.23-3.6
				c-0.64-0.37-1.16-1.27-1.16-2.01l0.01-3.12l1.45,0.84c-0.14,0.24-0.22,0.55-0.23,0.94c0,1.3,0.92,2.89,2.05,3.55
				c1.13,0.65,2.06,0.13,2.06-1.17c0-0.38-0.08-0.79-0.22-1.19l3.43,1.98L332.39,313.02z M325.6,306.98c0-0.97,0.69-1.37,1.54-0.88
				s1.53,1.68,1.53,2.65s-0.69,1.37-1.54,0.88S325.59,307.95,325.6,306.98z M332.4,308.9v0.41l-3.8-2.19
				c-0.37-0.66-0.89-1.23-1.47-1.56s-1.1-0.36-1.48-0.14l-1.81-1.05v-0.41c0-0.74,0.53-1.03,1.17-0.66l0.33,0.19l3.58,2.07
				l1.37,0.79v-0.87l1.01,0.58v0.88C331.92,307.33,332.4,308.19,332.4,308.9z"
            />
          </g>
          <g>
            <path
              className="st2"
              d="M331.3,306.94v-0.88l-1.01-0.58v0.87l-1.37-0.79l-3.58-2.07l-0.33-0.19c-0.64-0.37-1.16-0.07-1.17,0.66v0.41
				l1.81,1.05c0.38-0.23,0.9-0.2,1.48,0.14c0.57,0.33,1.1,0.91,1.47,1.56l3.8,2.19v-0.41C332.4,308.19,331.92,307.33,331.3,306.94z"
            />
          </g>
        </g>
        <g>
          <path
            className="st33"
            d="M392.42,297.83l-24.63-14.43c-3.19-1.84-5.78-0.36-5.79,3.3l-0.05,16.21l6.01-1.3l24.4,14.3
			c3.19,1.84,5.78,0.36,5.79-3.3l0.01-4.82C398.18,304.14,395.6,299.67,392.42,297.83z"
          />
        </g>
        <g>
          <g>
            <path
              className="st2"
              d="M389.17,307.63c0.8,0.15,1.38-0.38,1.38-1.41c0-1.28-0.9-2.85-2.02-3.5s-2.03-0.13-2.03,1.16
				c0,1.02,0.57,2.23,1.37,3c-1.24-0.46-2.26-0.04-2.7,1.03c0.9,1.4,2.07,2.6,3.34,3.33c1.27,0.74,2.44,0.88,3.35,0.53
				C391.43,310.19,390.41,308.61,389.17,307.63z"
            />
          </g>
          <g>
            <path
              className="st10"
              d="M388.55,299.32c-2.86-1.65-5.2-0.33-5.21,2.97c-0.01,1.83,0.71,3.88,1.83,5.63
				c0.44-1.07,1.46-1.48,2.7-1.03c-0.8-0.77-1.37-1.97-1.37-3c0-1.28,0.91-1.8,2.03-1.16c1.12,0.65,2.02,2.21,2.02,3.5
				c0,1.02-0.58,1.56-1.38,1.41c1.24,0.98,2.25,2.56,2.69,4.14c1.13-0.44,1.85-1.66,1.86-3.49
				C393.73,304.98,391.41,300.97,388.55,299.32z"
            />
          </g>
        </g>
      </g>
      <g id="ozdobnik" className="st31" ref={lineRef}>
        <path
          className="st2"
          d="M391.19,235.93c-1.41,0.79-2.57,2.75-2.58,4.36c-0.01,0.65,0.18,1.14,0.49,1.45l-4.85,11.08
		c-0.18,0.06-0.36,0.13-0.55,0.24s-0.37,0.23-0.54,0.38l-4.65-5.72c0.32-0.67,0.52-1.38,0.53-2.03c0.01-1.61-1.12-2.28-2.54-1.48
		s-2.58,2.75-2.59,4.37c-0.01,0.65,0.18,1.14,0.49,1.45l-4.85,11.08c-0.18,0.06-0.36,0.13-0.55,0.24c-1.42,0.8-2.58,2.75-2.59,4.37
		s1.12,2.28,2.54,1.49c1.41-0.79,2.57-2.75,2.58-4.37c0.01-0.65-0.18-1.13-0.49-1.44l4.86-11.08c0.18-0.06,0.36-0.13,0.55-0.24
		c0.18-0.1,0.37-0.23,0.54-0.38l4.65,5.72c-0.32,0.67-0.52,1.38-0.53,2.03c-0.01,1.62,1.12,2.28,2.54,1.49
		c1.41-0.79,2.57-2.75,2.58-4.37c0.01-0.65-0.18-1.13-0.49-1.44l4.85-11.08c0.18-0.06,0.36-0.13,0.55-0.24
		c1.41-0.79,2.57-2.75,2.58-4.37C393.74,235.8,392.61,235.13,391.19,235.93z"
        />
      </g>
      <g id="dymek" ref={dimmerRef}>
        <path
          className="st2"
          d="M254.93,204.02l-21.21,12.25c-2.64,1.52-4.77,5.04-4.76,7.85l0.07,23.24l4.29-8.62L255,226.22
		c2.64-1.52,4.76-5.03,4.76-7.84l-0.03-12.03C259.71,203.55,257.57,202.5,254.93,204.02z"
        />
      </g>
      <g id="Tlo_2" ref={hexagonRef}>
        <polygon
          className="st47"
          points="257.95,63.43 257.95,167.61 348.17,219.7 438.39,167.61 438.39,63.43 348.17,11.34 	"
        />
        <path
          className="st2"
          d="M348.17,221.43l-91.72-52.96V62.57l91.72-52.96l91.72,52.96v105.91L348.17,221.43z M259.45,166.75l88.72,51.22
		l88.72-51.22V64.3l-88.72-51.22L259.45,64.3V166.75z"
        />
        <g ref={hexagonTextRef}>
          <text
            transform="matrix(1 0 0 1 292.3713 95.0831)"
            className="st2 st48 st49"
          >
            Łączymy wizję{' '}
          </text>
          <text
            transform="matrix(1 0 0 1 285.2813 114.2831)"
            className="st2 st48 st49"
          >
            klientów z naszą{' '}
          </text>
          <text
            transform="matrix(1 0 0 1 289.0613 133.4831)"
            className="st2 st48 st49"
          >
            specjalistyczną{' '}
          </text>
          <text
            transform="matrix(1 0 0 1 319.5212 152.6831)"
            className="st2 st48 st49"
          >
            wiedzą
          </text>
        </g>
      </g>
    </Svg>
  );
};

export default SvgAboutUs;
